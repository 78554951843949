import {
  PreviewPropsBase,
  QuestionDefinition,
  QuestionPreviewModuleConfiguration,
} from "./interfaces";
import { Configuration as FreeTextPreviewConfiguration } from "./FreeTextQuestion/Preview";
import { Configuration as MultipleChoicePreviewConfiguration } from "./MultipleChoiceQuestion/Preview";
import { Configuration as ProductSelectionPreviewConfiguration } from "./ProductSelectionQuestion/Preview";
import { Configuration as QuantityPreviewConfiguration } from "./QuantityQuestion/Preview";
import { Configuration as YesNoPreviewConfiguration } from "./YesNoQuestion/Preview";
import { Configuration as LongAnswerFreeTextPreviewConfiguration } from "./LongAnswerFreeTextQuestion/Preview";
import { Action } from "utils";
export * from "./interfaces";

const asConf = (
  input: QuestionPreviewModuleConfiguration<any, any, any>
): QuestionPreviewModuleConfiguration<
  PreviewPropsBase,
  QuestionDefinition,
  Action
> => {
  return input;
};
export const QuestionTypesMetaConfigurationExtensions: QuestionPreviewModuleConfiguration<
  PreviewPropsBase,
  QuestionDefinition,
  Action
>[] = [
  asConf(LongAnswerFreeTextPreviewConfiguration),
  asConf(FreeTextPreviewConfiguration),
  asConf(MultipleChoicePreviewConfiguration),
  asConf(ProductSelectionPreviewConfiguration),
  asConf(QuantityPreviewConfiguration),
  asConf(YesNoPreviewConfiguration),
];
