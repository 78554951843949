import { FC } from "react";
import styled from "styled-components";

export const Checkbox: FC<{
  checked: boolean;
  onChange: (value: boolean) => void;
}> = ({ checked, onChange }) => {
  return (
    <StyledCheckbox>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(!checked)}
      />
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled.div`
  input {
    width: 1.75rem;
    height: 1.75rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    padding: 0;
  }
`;
