import { CheckoutConfirmation, PersonalData } from "servicebundlecore";
import { SalesforceRequestedStartTime } from 'salesforce/SalesforceModels';

export interface CheckoutState {
  isSubmitting?: boolean;
  submitted?: boolean;
  checkoutValidationErrors: any[];
  formState: Partial<CheckoutFormState>;
  checkoutConfirmation?: CheckoutConfirmation;
}

export interface MoveForwards {
  type: "move_forwards";
}
export interface MoveBackwards {
  type: "move_backwards";
}

export interface SetFormValue {
  type: "set_form_value";
  value: Partial<CheckoutFormState>;
}

export interface AddImage {
  type: "add_images";
  value: UploadedImage[];
}
export interface RemoveImage {
  type: "remove_image";
  id: string;
}
export interface CompletePersonalForm {
  type: "complete_personal_form";
  value: PersonalData;
}

export interface CheckoutConfirmed {
  type: "checkout_confirmed";
  value: CheckoutConfirmation;
}

export type CheckoutActions =
  | MoveForwards
  | MoveBackwards
  | SetFormValue
  | AddImage
  | CompletePersonalForm
  | CheckoutConfirmed
  | RemoveImage;

export enum CheckoutPageStep {
  serviceQuestions,
  personalInformation,
  summary,
}

export interface UploadedImage {
  name: string;
  id: string;
  dataUrl: string;
  file: File;
}

export interface CheckoutFormState {
  requestedStartDate?: Date;
  desireUrgency: boolean;
  requestedStartTimeId: SalesforceRequestedStartTime;
  otherComments?: string;
  gateAccessCode?: string;
  personal: PersonalData;
}

export const checkoutReducer = (
  state: CheckoutState,
  action: CheckoutActions
): CheckoutState => {
  switch (action.type) {
    case "set_form_value": {
      return {
        ...state,
        formState: {
          ...state.formState,
          ...action.value,
        },
      };
    }
    case "complete_personal_form": {
      return {
        ...state,
        formState: {
          ...state.formState,
          personal: action.value,
        },
      };
    }
    case "checkout_confirmed": {
      return {
        ...state,
        checkoutConfirmation: action.value,
      };
    }
  }

  return state;
};
