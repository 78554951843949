import {FC} from "react";
import {Deductable, Price, Time} from "..";
import {DEFAULT_PRICE, DEFAULT_START_FEE} from "utils";
import {Category, ServiceBundle} from "servicebundlecore";

export enum HouseWorkTypeCategories {
    UseParent = "Use parent",
    None = "None",
    GREEN = "Grön teknik",
    RUT = "RUT",
    ROT = "ROT",
}

interface estimatedTimeAndPriceTextProps {
    from: Time;
    to: Time;
    price: Price;
}

export function estimatedLowestPriceText({
    from,
    to,
    price,
    isClasMedlem,
    deduction,
    hourlyRate,
}: estimatedTimeAndPriceTextProps & {
    deduction: Deductable;
    isClasMedlem: boolean;
    hourlyRate;
}) {
    //Medlemsrabatt 80kr per timme före avdrag
    const {fromPrice, toPrice} = estimatedPriceWithDeductionsAndRebates({
        from,
        to,
        price,
        isClasMedlem,
        deduction,
        hourlyRate,
    });

    if (fromPrice === toPrice) return <>{formatPrice(fromPrice)} kr</>;
    else {
        return (
            <>
                {formatPrice(fromPrice)} kr - {formatPrice(toPrice)} kr
            </>
        );
    }
}

export function estimatedPriceWithDeductionsAndRebates({
                                                           from,
                                                           to,
                                                           price,
                                                           isClasMedlem,
                                                           deduction,
                                                           hourlyRate = DEFAULT_PRICE,
                                                       }: estimatedTimeAndPriceTextProps & {
    deduction: Deductable;
    isClasMedlem: boolean;
    hourlyRate?: number;
}) {
    //Medlemsrabatt 10%
    const fromPrice = calculatePriceFromTime(from, hourlyRate);
    const toPrice = calculatePriceFromTime(to, hourlyRate);

    const fromTotalPrice = fromPrice + price.amount;
    const toTotalPrice = toPrice + price.amount;

    const fromRebate = calculateRebate(fromTotalPrice, isClasMedlem);
    const toRebate = calculateRebate(toTotalPrice, isClasMedlem);

    const deductedFromPrice = getDeductedPrice(
        fromTotalPrice - fromRebate,
        deduction
    );
    const deductedToPrice = getDeductedPrice(toTotalPrice - toRebate, deduction);

    return {
        fromPrice: Math.floor(deductedFromPrice),
        toPrice: Math.floor(deductedToPrice),
    };
}

export function formatPrice(price: number) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function calculateRebate(price: number, useRebate: boolean) {
    if (!useRebate) return 0;
    return price * 0.1;
}

interface listOfStartFee {
    [key: string]: number;
}

export const extractStartFeeAndCategoryFromBundleList = (cartItemList): any => {
    const listOfFees: listOfStartFee = {};
    for (let cartItem of cartItemList) {
        const {startFee, categoryId} = extractCategoryIdWithStartFee(
            cartItem.serviceBundle
        );
        if (categoryId) {
            listOfFees[categoryId] = startFee;
        }
    }
    return listOfFees;
};

export const extractStartFeeFromBundleList = (cartItemList): number => {
    const listOfFees: listOfStartFee = {};
    for (let cartItem of cartItemList) {
        const {startFee, categoryId} = extractCategoryIdWithStartFee(
            cartItem.serviceBundle
        );
        if (categoryId) {
            listOfFees[categoryId] = startFee;
        }
    }

    const reducer = (accumulator, curr): number => accumulator + curr;
    return Object.values(listOfFees).length
        ? Object.values(listOfFees).reduce(reducer)
        : 0;
};

export const extractSyncWithSalesforce = (bundle) => {
    if (bundle?.category?.syncWithSalesforce) {
        return true;
    }
    return false;
};

export const extractHourlyRateFromBundle = (bundle): number|undefined => {
    if (bundle === undefined || bundle === null) {
        return undefined;
    }

    if (typeof bundle.hourlyRate === "number") {
        return bundle.hourlyRate;
    }

    const findFirstRate = (category: Category | undefined): number | undefined => {
        if (category === undefined)
            return undefined;
        if (typeof category.hourlyRate === "number")
            return category.hourlyRate;
        return findFirstRate(category.parentCategory);
    }

    let category = bundle.category ?? bundle.parentCategory;
    return findFirstRate(category);
};

export const extractCategoryIdWithStartFee = (
    bundle?: Partial<ServiceBundle>
) => {
    let categoryId: string | null = null;
    let startFee = DEFAULT_START_FEE;
    let parentCategoryStartFee = DEFAULT_START_FEE;

    const parentCatStartPrice = Number.isInteger(
        bundle?.category?.parentCategory?.startFee
    );
    const catStartPrice = Number.isInteger(bundle?.category?.startFee);

    if (bundle?.category?.parentCategory && parentCatStartPrice) {
        categoryId = bundle.category.parentCategory.id;
        startFee = bundle.category.parentCategory.startFee!;
        parentCategoryStartFee = bundle.category.parentCategory.startFee!;
    }

    if (bundle?.category && catStartPrice) {
        categoryId = bundle.category.id;
        startFee = bundle.category.startFee!;
    }

    return {categoryId, startFee, parentCategoryStartFee};
};

export function extractParentHouseWorkType(bundle) {
    const category = bundle?.category;
    const parentCategory = bundle?.category?.parentCategory;

    if (
        category &&
        category.houseWorkTypeId &&
        HouseWorkTypeCategories[category.houseWorkTypeId] !== HouseWorkTypeCategories.UseParent
    ) {
        return bundle.category.houseWorkTypeId;
    }
    if (
        parentCategory &&
        parentCategory.houseWorkTypeId &&
        HouseWorkTypeCategories[parentCategory.houseWorkTypeId] !== HouseWorkTypeCategories.UseParent
    ) {
        return parentCategory.houseWorkTypeId;
    }
    return HouseWorkTypeCategories.None;
}

const startFeeAndParentStartFee = (
    item
): {
    startFee: number | undefined,
    parentStartFee: number | undefined,
} => {
    if (item === undefined) {
        return {
            startFee: undefined,
            parentStartFee: undefined,
        };
    }

    const findFirstFee = (category: Category | undefined): number | undefined => {
        if (category === undefined)
            return undefined;
        if (typeof category.startFee === "number")
            return category.startFee;
        return findFirstFee(category.parentCategory);
    }

    const category = item.category ?? item.parentCategory;
    const startFee = item.startFee ?? category?.startFee;
    const parentStartFee = findFirstFee(category?.parentCategory);

    return {
        startFee: startFee,
        parentStartFee: parentStartFee,
    };
}

export const extractStartFeeTextFromItem = (item): string|null => {

    const fees = startFeeAndParentStartFee(item);
    if (typeof fees.startFee !== "number" && typeof fees.parentStartFee !== "number")
        return null;

    if (typeof fees.startFee !== "number") {
        return `Startpris: ${fees.parentStartFee} kr (bara en per yrkesområde)`;
    }

    return `Startpris: ${fees.startFee} kr (bara en per yrkesområde)`;
};

function getDeductedPrice(price: number, deduction: Deductable) {
    switch (deduction) {
        case "None":
            return price;
        case "ROT":
            return price * 0.7;
        case "RUT":
            return price * 0.5;
    }
    return 0;
}

function calculatePriceFromTime(time: Time, hourlyRate: number) {
    const pricePerMinute = hourlyRate / 60; // 1.66

    const price = time.length * pricePerMinute; // 120 * 1.66   = 199.

    return Math.floor(price);
}

export interface FromAndToPrice {
    fromPrice: number;
    toPrice: number;
}

export function SumupFromAndToPrices(prices: FromAndToPrice[]) {
    return prices.reduce(
        (state, newOne) => {
            return {
                fromPrice: state.fromPrice + newOne.fromPrice,
                toPrice: state.toPrice + newOne.toPrice,
            };
        },
        {fromPrice: 0, toPrice: 0} as FromAndToPrice
    );
}

export function summarizeFromAndToPrice(
    prices: FromAndToPrice[]
): FromAndToPrice {
    return prices.reduce(
        (curr, {fromPrice, toPrice}) => {
            return {
                ...curr,
                fromPrice: curr.fromPrice + fromPrice,
                toPrice: curr.toPrice + toPrice,
            };
        },
        {fromPrice: 0, toPrice: 0} as FromAndToPrice
    );
}

export function padPriceIfNecessary(
    price: FromAndToPrice,
    useDeduction: boolean,
    isClasMedlem: boolean
): FromAndToPrice {
    const pricePerHour = price.toPrice ? price.toPrice : DEFAULT_PRICE;
    const fromRebate = calculateRebate(pricePerHour, isClasMedlem);
    const lowestPrice = getDeductedPrice(
        pricePerHour - fromRebate,
        useDeduction ? "RUT" : "None"
    );

    if (price.fromPrice <= lowestPrice) {
        return {
            fromPrice: Math.floor(lowestPrice),
            toPrice: Math.floor(lowestPrice),
        };
    }

    return price;
}

export function meetsMinimumRequirement(
    totalPrice: FromAndToPrice,
    useDeduction: boolean,
    isClasMedlem: boolean
) {
    const totalPaddedPrice = padPriceIfNecessary(
        totalPrice,
        useDeduction,
        isClasMedlem
    );
    if (totalPaddedPrice.fromPrice === totalPrice.fromPrice) {
        //All is good
        return {
            meetsMinimum: true,
            priceText: estimatedPriceText(totalPrice),
            padPriceText: estimatedPriceText({fromPrice: 0, toPrice: 0}),
            price: totalPrice,
        };
    }
    return {
        meetsMinimum: false,
        priceText: estimatedPriceText(totalPaddedPrice),
        padPriceText: estimatedPriceText({
            fromPrice: Math.floor(totalPaddedPrice.fromPrice - totalPrice.fromPrice),
            toPrice: Math.floor(totalPaddedPrice.toPrice - totalPrice.toPrice),
        }),
        price: totalPaddedPrice,
    };
}

export function estimatedPriceText({fromPrice, toPrice}: FromAndToPrice) {
    if (fromPrice === toPrice) return priceText(fromPrice);
    return `${formatPrice(fromPrice)} kr till ${formatPrice(toPrice)} kr`;
}

function priceText(price: number) {
    if (price === 0) return "";
    return `${formatPrice(price)} kr`;
}

export function rebatePrice<TPriceObj extends { price: Price }>(
    input: TPriceObj,
    isClasMedlem: boolean,
    deduction: Deductable
): TPriceObj {
    const price = input.price.amount;
    const fromRebate = calculateRebate(price, isClasMedlem);
    const newPrice = price - fromRebate;
    const deductedFromPrice = getDeductedPrice(newPrice, deduction);
    return {
        ...input,
        price: {...input.price, amount: Math.floor(deductedFromPrice)},
    };
}

export function estimatedTimeAndPriceText({
                                              from,
                                              to,
                                              price,
                                          }: estimatedTimeAndPriceTextProps) {
    if (from.length === 0 && to.length === 0 && price.amount === 0) return "";
    if (from.length === 0 && to.length === 0) return `${showPrice({price})}`;
    if (from.length === to.length) {
        return `${from.length} minuter ${showPrice({price, plus: true})}`;
    }
    return `${from.length} till ${to.length} minuter ${showPrice({
        price,
        plus: true,
    })}`;
}

function showPrice({price, plus}: { price: Price; plus?: boolean }) {
    if (price.amount === 0) return "";
    return `${plus ? "+" : ""} ${formatPrice(price.amount)} kr`;
}

export const ShowEstimatedTimeAndPrice: FC<estimatedTimeAndPriceTextProps> = ({
                                                                                  from,
                                                                                  to,
                                                                                  price,
                                                                              }) => {
    return <>{estimatedTimeAndPriceText({from, to, price})}</>;
};

function deductionText({deduction}: { deduction: Deductable }) {
    if (deduction === "None") return "";
    return `${deduction} Avdrag inte inkluderade`;
}

export const DeductionText: FC<{ deduction: Deductable }> = ({deduction}) => {
    return <>{deductionText({deduction})}</>;
};
