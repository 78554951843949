const enhancedFetch: typeof fetch = async (...args) => {
  const res = await fetch(...args);
  if (!res.ok) {
    alert(
      "Ett problem har uppstått med applikationen, vi har loggat felet och föreslår att du försöker igen senare."
    );
  }
  return res;
};

export default enhancedFetch;
