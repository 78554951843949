export const DEFAULT_PRICE = 998;
export const DEFAULT_START_FEE = 0;
export const QUOTE_REQUIRED_TYPE = "QuoteRequired";
export const QUOTE_REQUIRED_TEXT = "Offert krävs";

export enum HouseWorkTypeCategories {
  UseParent = "Use parent",
  None = "None",
  GREEN = "Green Tech",
  RUT = "RUT",
  ROT = "ROT",
}

export interface HouseWorkType {
  id: string;
  name: string;
  houseWorkTypeCategory?: HouseWorkTypeCategories;
  salesforceName: string;
  isDeleted?: boolean;
}

export enum HouseWorkTypeCategoriesSelectableInHouseWorkType {
  GREEN = "Grön teknik",
  RUT = "RUT",
  ROT = "ROT",
}
