import { PreviewPropsBase, QuestionPreviewModuleConfiguration } from "..";
import { Action } from "utils";
import { YesNoDefinition } from ".";
import styled from "styled-components";
import { Tooltip } from "../Tooltip";
import { Checkbox } from "../Checkbox";

export const Configuration: QuestionPreviewModuleConfiguration<
  YesNoQuestionPreviewProps,
  YesNoDefinition,
  Actions
> = {
  typeId: "yesno-question",
  presentAnswerText: (state) => {
    return state.selected ? "Yes" : "No";
  },
  isValidToSubmit: (state, definition) => {
    if (!definition.mandatory) return true;
    return !!state.selected;
  },
  extractEstimatesFromPreviewData: (input, definition) => {
    if ((definition as any).definition) {
      definition = (definition as any).definition; //why?
    }
    const yesChoice = definition.choices.find((a) => a.answer === true);
    const noChoice = definition.choices.find((a) => a.answer === false);

    const choice = input.selected === true ? yesChoice : noChoice;
    if (choice) return [choice?.estimate];

    return []; //Figure out how to get estimates
  },
  getDefaultPreviewData: () => {
    return {
      selected: false,
    };
  },
  getDefaultPreviewDataFromDefinition: (definition) => {
    return {
      selected: false,
    };
  },
  getPreviewDataReducer: (
    state: YesNoQuestionPreviewProps,
    action,
    definition
  ) => {
    switch (action.type) {
      case "check": {
        return { ...state, selected: action.checked };
      }
    }
    return state;
  },
  previewQuestion: ({ definition, state, dispatch }) => {
    return (
      <Wrapper>
        <LabelCol>
          <QuestionText>
            {definition.questionText}
            {definition.mandatory && " *"}
          </QuestionText>
          <Tooltip text={definition.tooltipText} />
        </LabelCol>
        <Checkbox
          checked={!!state.selected}
          onChange={() => dispatch({ type: "check", checked: !state.selected })}
        />
      </Wrapper>
    );
  },
  presentAnswer: ({ definition, state }) => {
    return (
      <Wrapper>
        <QuestionText>{definition.questionText}</QuestionText>
        <QuestionText>{state.selected ? "Ja" : "Nej"}</QuestionText>
      </Wrapper>
    );
  },
};

export interface YesNoQuestionPreviewProps extends PreviewPropsBase {
  selected?: boolean;
}

interface SelectYesNo extends Action {
  type: "check";
  checked: boolean;
}

type Actions = SelectYesNo;

const LabelCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & select {
    background-color: white;
    border-radius: 0.25rem;
    border: 0.125rem solid black;
    padding: 0.45rem 0.875rem;
    width: 50%;
  }
`;
const QuestionText = styled.span``;
