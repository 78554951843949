import classNames from "classnames";
import { createContext, EventHandler, FC, useContext } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import styled, { StyledComponent } from "styled-components";
import { AccordionItem, useAccordionContext } from ".";
import { colors } from "../../style/statics";
import { useStateWithDeps } from "../../utils";
import { TextButton } from "./Buttons";
import {
  extractHourlyRateFromBundle,
  extractStartFeeTextFromItem,
  padPriceIfNecessary,
} from "estimates";
import { useCart } from "../../Cart";

export const AccordionToggle: FC<{
  titleComponent?: StyledComponent<"div", any, {}, never>;
  eventKey: string;
  item: AccordionItem;
  onToggle: (eventKey?: string) => void;
}> = ({ titleComponent, onToggle, eventKey, item }) => {
  const { title, image } = item;

  const { items, toggleButton, secondaryColors, whitespace } =
    useAccordionContext();
  if (!titleComponent) titleComponent = TitleWrapper;

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    onToggle(eventKey)
  );

  const { activeEventKey } = useContext(AccordionContext);

  const [active] = useStateWithDeps<boolean>(() => {
    return activeEventKey === eventKey;
  }, [eventKey, activeEventKey]);

  const [currentIndex] = useStateWithDeps<number>(() => {
    return items.findIndex((c) => c.id === eventKey);
  }, [items]);

  const [activeIndex] = useStateWithDeps<number>(() => {
    return items.findIndex((c) => c.id === activeEventKey);
  }, [items, activeEventKey]);

  const [first] = useStateWithDeps<boolean>(() => {
    return currentIndex === 0;
  }, [items, currentIndex, activeIndex]);

  const [last] = useStateWithDeps<boolean>(() => {
    if (currentIndex === items.length) return true;
    return currentIndex + 1 === items.length;
  }, [items, currentIndex, active]);

  const cart = useCart();

  let startFeeText = extractStartFeeTextFromItem(item) ?? "";
  let hourlyRateText = "";

  const hourlyRate = extractHourlyRateFromBundle(item);
  if (hourlyRate !== undefined) {
      const hourlyRateDeducted = padPriceIfNecessary(
        { fromPrice: 0, toPrice: hourlyRate },
        cart.useDeduction,
        cart.isClasMedlem
      );
      hourlyRateText = `Timpris: ${hourlyRateDeducted.fromPrice} kr/tim`;
  }
  if (item?.discriminator !== "category") {
      startFeeText = "";
      hourlyRateText = "";
  }

  return (
    <ToggleButtonContext.Provider
      value={{ toggle: decoratedOnClick, active, item }}
    >
      <TitleWrapper
        onClick={decoratedOnClick}
        className={classNames({
          active,
          first,
          last,
          secondaryColors,
          whitespace,
          navigationDisabled: item.disabledNavigation,
        })}
      >
        <>
          <ImageWrapper className={classNames({ disabled: item.disabled })}>
            {image && typeof image === "string" && (
              <img
                src={image}
                alt={typeof title === "string" ? title : "clasfixare"}
              />
            )}
            {image && typeof image !== "string" && image}
          </ImageWrapper>
          <TitleText>
            <Title className={classNames({ disabled: item.disabled })}>
              {typeof title === "string" ? <span>{title}</span> : title}
              {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
            </Title>
            <StartFee>
              {startFeeText} {hourlyRateText}
            </StartFee>
            {item.disabled && (
              <DisabledSubtitle>
                {item.disabledText ??
                  "Tjänsten är inte tillgänglig i ditt område för tillfället"}
              </DisabledSubtitle>
            )}
          </TitleText>
          {toggleButton || <TextButton />}
        </>
      </TitleWrapper>
    </ToggleButtonContext.Provider>
  );
};

const Subtitle = styled.p`
  margin-bottom: 0;
  font-style: italic;
  font-size: 0.8rem;
`;

const DisabledSubtitle = styled(Subtitle)`
  color: ${colors.font.red};
`;
const StartFee = styled(Subtitle)`
  color: ${colors.font.black};
`;
const TitleText = styled.div`
  padding: 0 1.313rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  background-color: ${colors.grey[3]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;

  &.first {
  }

  &.last {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  &.active {
    background-color: ${colors.secondary.green[0]};
    margin-bottom: -1.4rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }

  &.navigationDisabled {
    cursor: auto;
  }

  & span {
    font-family: Clas Ohlson Sans Web medium;
    font-size: 1.1rem;
  }

  & img {
    width: 2.5rem;
    justify-content: center;
    align-content: center;
  }

  &.secondaryColors {
    background-color: ${colors.grey[3]};
  }

  &.whitespace {
    border-radius: 0.25rem;
  }
`;

const Title = styled.div`
  width: 100%;

  &.disabled {
    color: ${colors.grey[2]};
  }
`;
const ImageWrapper = styled.div`
  &.disabled {
    opacity: 0.4;
  }
`;
const ToggleButtonContext = createContext<{
  toggle: EventHandler<any>;
  active: boolean;
  item: AccordionItem;
}>(undefined as any);
export const useToggleButtonContext = () => useContext(ToggleButtonContext);
