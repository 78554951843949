import styled from "styled-components";

export const BlackButton = styled.button`
  background-color: black;
  padding: 0.25rem;
  border-radius: 0.25rem;
  color: white;
  border: 0;
  height: 2.25rem;
  min-width: 7.938rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
`;
