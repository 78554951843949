import { useCart } from "./useCart";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { media } from "../style/media";
import {
  Headline1,
  SubHeadline4,
  SubHeadline5,
  SubHeadline5Medium,
} from "../style/Text";
import { useLocation, useNavigate } from "react-router-dom";
import {
  enhancedItemsContainsQuoteRequired,
  estimatedPriceText,
  estimatedPriceWithDeductionsAndRebates,
  extractHourlyRateFromBundle,
  extractStartFeeFromBundleList,
  padPriceIfNecessary,
  summarizeFromAndToPrice,
  summarizePriceEstimates,
} from "estimates";
import { BlackButton } from "../Global/components/BlackButton";
import { colors } from "../style/statics";
import { QUOTE_REQUIRED_TEXT } from "utils";

export const FooterSummary = () => {
  const { postalCode, enhancedCartItemsInCart } = useCart();
  const location = useLocation();

  const isCartPage = location.pathname.includes("/varukorg");
  const isCartEmpty = enhancedCartItemsInCart.length === 0;

  if (!isCartPage || isCartEmpty) {
    return <></>;
  }

  return (
    <FooterWrapper>
      <Container>
        <Row>
          <HeadlineCol>
            <SummaryText>Offert sammanfattning</SummaryText>
          </HeadlineCol>
          <Summary>
            {postalCode ? (
              <EstimateCol>
                <EstimatedPrice />
              </EstimateCol>
            ) : (
              <MissingPostalCodeLink
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Ange postnummer för att se vilka tjänster som är tillgängliga i
                ditt område
              </MissingPostalCodeLink>
            )}

            <CheckoutButton />
          </Summary>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

function CheckoutButton() {
  const navigation = useNavigate();
  const { postalCode } = useCart();
  return (
    <ButtonWrapper>
      <BBB disabled={!postalCode} onClick={() => navigation("/checkout")}>
        Gå vidare
      </BBB>
    </ButtonWrapper>
  );
}

function EstimatedPrice() {
  const { enhancedCartItemsInCart, useDeduction, isClasMedlem } = useCart();

  let containsQuoteRequired = enhancedItemsContainsQuoteRequired(
    enhancedCartItemsInCart
  );

  let totalPriceText = QUOTE_REQUIRED_TEXT;

  if (!containsQuoteRequired) {
    const allPrices = enhancedCartItemsInCart.flatMap((s) => {
      const estimates = s.questionAnswers.flatMap((a) => {
        return a.meta.extractEstimatesFromPreviewData(a.state, a.definition);
      });

      let result = summarizePriceEstimates([
        ...estimates,
        s.serviceBundle?.estimate!,
      ]);

      const hourlyRate = extractHourlyRateFromBundle(s.serviceBundle);
      const innerPrice = estimatedPriceWithDeductionsAndRebates({
        ...result,
        deduction: useDeduction ? s.serviceBundle?.deductable! : "None",
        isClasMedlem,
        hourlyRate: hourlyRate,
      });

      const itemPrice: any = summarizeFromAndToPrice([innerPrice]);

      return itemPrice;
    });

    let final = { fromPrice: 0, toPrice: 0 };

    for (let currentPrice of allPrices) {
      final.fromPrice += currentPrice.fromPrice;
      final.toPrice += currentPrice.toPrice;
    }

    const startFee = extractStartFeeFromBundleList(enhancedCartItemsInCart);
    final.fromPrice += startFee;
    final.toPrice += startFee;

    const totalPaddedPrice = padPriceIfNecessary(
      final,
      useDeduction,
      isClasMedlem
    );

    totalPriceText = estimatedPriceText(totalPaddedPrice);
  }

  return (
    <Price>
      <SubHeadline5Medium>Est. totalpris</SubHeadline5Medium>
      <SubHeadline4>{totalPriceText}</SubHeadline4>
    </Price>
  );
}

const EstimateCol = styled(Col)`
  width: 100%;
  margin-bottom: 1rem;
  ${media.tablet} {
    margin-bottom: 0;
  }
  ${media.desktop} {
    margin-bottom: 0;
  }
`;

const HeadlineCol = styled(Col)`
  display: none;
  align-items: center;

  ${media.desktop} {
    display: flex;
  }
`;

const SummaryText = styled(Headline1)`
  font-size: 2.5rem;
  margin-bottom: 0;
  margin-top: 0;
`;

const Summary = styled(Col)`
  background-color: white;
  border-radius: 0.25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 1.3rem;
  flex: 1;

  ${media.tablet} {
    flex-direction: row;
  }

  ${media.desktop} {
    // margin-left: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  flex-wrap: nowrap;
  align-items: center;

  ${media.tablet} {
    min-width: 30rem;
    margin-left: 1rem;
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    padding-right: 1rem;
  }

  ${SubHeadline4} {
    margin-bottom: 0;
    margin-top: 0;

    ${media.desktop} {
      margin-right: 2rem;
    }
  }

  ${SubHeadline5} {
    margin-bottom: 0px;
  }
`;

const BBB = styled(BlackButton)`
  width: 100%;
  height: 2.7rem;
  margin-bottom: 0.5rem;
  &:disabled,
  &button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

const ButtonWrapper = styled(Col)`
  width: 100%;
  ${media.tablet} {
    width: auto;
  }
  ${media.desktop} {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 3.2rem;
  background-color: ${colors.lightBlueBackground};
  width: 100%;
  padding: 1rem 0 3rem 0;
  align-items: center;

  ${media.tablet} {
    bottom: 2rem;
  }
  ${media.desktop} {
    padding-top: 1.8rem;
  }
`;

const MissingPostalCodeLink = styled.a`
  cursor: pointer;
`;
