import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: "Clas Ohlson Sans Web", "Clas Ohlson Sans Web bold" , "Clas Ohlson Sans Web medium"; 
    a{color: black}          
  }
`;
