import { SubHeadline5 } from "../../style/Text";
import { useCheckout } from "../Checkout/context";
import { createElement } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import calendar from "./calendar.png";

export function DesiredStartDate() {
  const {
    dispatch,
    formState: { requestedStartDate, desireUrgency },
  } = useCheckout();
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 2);

  return (
    <DateContainer>
      <WrapHeadlineFlex>
        <div>
          <SubHeadline5 style={{ marginBottom: "1rem" }}>
            Önskat startdatum
          </SubHeadline5>
          <p>{tooltipText}</p>
        </div>
      </WrapHeadlineFlex>
      <Styles>
        <DatePicker
          selected={requestedStartDate}
          disabled={desireUrgency}
          onChange={(date) =>
            dispatch({
              type: "set_form_value",
              value: { requestedStartDate: date ? date : undefined },
            })
          }
          dateFormatCalendar="MMM yyyy"
          customInput={createElement(ExampleCustomInput)}
          onSelect={(date) =>
            dispatch({
              type: "set_form_value",
              value: { requestedStartDate: date },
            })
          }
          minDate={minDate}
          calendarStartDay={1}
          dateFormat="yyyy/MM/dd"
        />
      </Styles>
    </DateContainer>
  );
}

function ExampleCustomInput(props: any) {
  const { value, onClick, disabled } = props;
  return (
    <DatePickerStyling
      onClick={onClick}
      style={{ backgroundColor: disabled ? "#f3f3f3" : undefined }}
    >
      {disabled ? "Snarast möjligt" : value || "YYYY/MM/DD"}
      <img src={calendar} alt="calendar" width={22} height={22} />
    </DatePickerStyling>
  );
}

export const WrapHeadlineFlex = styled.div`
  display: flex;
  align-items: center;
`;
const DatePickerStyling = styled.div`
  display: flex;
  width: 100%;
  height: 47px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid rgb(217, 213, 213);
  padding: 1rem;
  cursor: pointer;
`;
const DateContainer = styled.div`
  padding: 1rem 0;
`;

const Styles = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    border-radius: 0.5px;
    padding: 0.5rem 0;

    input {
      padding-left: 1rem;
    }
  }
`;

const tooltipText = `
  Önskat startdatum är endast ett önskemål – vi gör vårt bästa för att hitta en fixare det datum du önskar, men om vi inte har en fixare tillgänglig med rätt kompetens önskat datum så kommer du att få förslag på annat datum
`;
