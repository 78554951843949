import { CheckoutQuestionTypesMetaConfigurationExtensions } from "checkoutquestions";
import { Deductable, Estimate } from "estimates";
import { QuestionTypesMetaConfigurationExtensions } from "questions";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  Category,
  CMSContent,
  QuestionDataProps,
  ServiceBundleBookingDestination,
} from "servicebundlecore";
import {
  Actions,
  AnswerPreviewStateItem,
  CheckoutAnswerPreviewStateItem,
  reducer,
} from "./reducer";

export interface ServiceBundleSubstitute {
  id: string;
  workName: string;
  cms: CMSContent;
  bookingDestination: ServiceBundleBookingDestination;
  estimate: Estimate;
  questionDefinitions: QuestionDataProps[];
  checkoutQuestionDefinitions: QuestionDataProps[];
  deductable: Deductable;
  isDeleted: boolean;
  categoryId?: string;
  category?: Category;
}

export const ServiceBundleConfig: React.FC<{
  serviceBundle: ServiceBundleSubstitute;
}> = ({ children, serviceBundle }) => {
  const { questionDefinitions, checkoutQuestionDefinitions } = serviceBundle;
  const [timeEstimate, setTimeEstimate] = useState("");

  const questions = useMemo(() => {
    const result =
      questionDefinitions.map(
        ({ questionType, definition, questionDefinitionId: id }) => {
          let meta = QuestionTypesMetaConfigurationExtensions.find(
            (a) => a.typeId === questionType
          );
          if (!meta) return undefined;
          const { getDefaultPreviewData } = meta;

          const getData = getDefaultPreviewData;
          const previewState = getData(definition);
          return {
            isValidToSubmit: meta.isValidToSubmit(previewState, definition),
            definition,
            state: previewState,
            questionDefinitionId: id,
            meta,
            estimates: [],
          } as AnswerPreviewStateItem;
        }
      ) ?? [];
    return result.filter((a) => a !== undefined).map((a) => a!) ?? [];
  }, [questionDefinitions]);

  const checkoutQuestions = useMemo(() => {
    const result =
      checkoutQuestionDefinitions?.map(
        ({ questionType, definition, questionDefinitionId: id }) => {
          let meta = CheckoutQuestionTypesMetaConfigurationExtensions.find(
            (a) => a.typeId === questionType
          );
          if (!meta) return undefined;
          const { getDefaultPreviewData } = meta;

          const getData = getDefaultPreviewData;
          const previewState = getData(definition);
          return {
            definition,
            state: previewState,
            checkoutQuestionDefinitionId: id,
            meta,
          } as CheckoutAnswerPreviewStateItem;
        }
      ) ?? [];
    return result.filter((a) => a !== undefined).map((a) => a!) ?? [];
  }, [checkoutQuestionDefinitions]);
  useEffect(() => {
    dispatch({ type: "reset", questions });
  }, [questions]);

  const [allItems, dispatch] = useReducer(reducer, { items: [] });

  const invalidQuestionAnswerIds = useMemo(() => {
    return allItems.items
      .filter((x) => !x.isValidToSubmit)
      .map((x) => x.questionDefinitionId);
  }, [allItems]);

  const allEstimates = [
    serviceBundle.estimate,
    ...allItems.items.flatMap((a) => a.estimates),
  ];

  return (
    <serviceBundleContext.Provider
      value={{
        invalidQuestionAnswerIds,
        serviceBundle,
        questions: allItems.items,
        checkoutQuestions: checkoutQuestions,
        dispatch,
        allEstimates,
        timeEstimate,
        setTimeEstimate,
      }}
    >
      {children}
    </serviceBundleContext.Provider>
  );
};

export interface ServiceBundleConfigContextProps {
  serviceBundle: ServiceBundleSubstitute;
  questions: AnswerPreviewStateItem[];
  checkoutQuestions: CheckoutAnswerPreviewStateItem[];
  invalidQuestionAnswerIds: string[];
  dispatch: React.Dispatch<Actions>;
  allEstimates: Estimate[];
  timeEstimate: string;
  setTimeEstimate: any;
}

export const serviceBundleContext =
  createContext<ServiceBundleConfigContextProps>(undefined as any);

export function useServiceBundleConfig() {
  return useContext(serviceBundleContext);
}
