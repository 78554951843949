export * from "./interfaces";
import {
  CheckoutQuestionDefinition,
  CheckoutQuestionPreviewModuleConfiguration,
  PreviewPropsBase,
} from "./interfaces";
import { Action } from "utils";
export * from "./interfaces";
import { Configuration as ImageUploadPreviewConfiguration } from "./ImageUploadQuestion/Preview";

const asConf = (
  input: CheckoutQuestionPreviewModuleConfiguration<any, any, any>
): CheckoutQuestionPreviewModuleConfiguration<
  PreviewPropsBase,
  CheckoutQuestionDefinition,
  Action
> => {
  return input;
};
export const CheckoutQuestionTypesMetaConfigurationExtensions: CheckoutQuestionPreviewModuleConfiguration<
  PreviewPropsBase,
  CheckoutQuestionDefinition,
  Action
>[] = [asConf(ImageUploadPreviewConfiguration)];
