import { createContext, FC, useContext, useEffect, useState } from "react";

interface SubmenuContextProps {
  setComponent: (component?: JSX.Element) => void;
  currentComponent?: JSX.Element;
}

const SubmenuContext = createContext<SubmenuContextProps>(undefined as any);

export const SubmenuContextProvider: FC = ({ children }) => {
  const [currentComponent, setComponent] = useState<JSX.Element | undefined>(
    undefined
  );
  return (
    <SubmenuContext.Provider
      value={{
        currentComponent,
        setComponent,
      }}
    >
      {children}
    </SubmenuContext.Provider>
  );
};

export const RenderSubmenu: FC = () => {
  const { currentComponent } = useContext(SubmenuContext);

  if (!currentComponent) return <></>;

  return <>{currentComponent}</>;
};
export const Submenu: FC = ({ children }) => {
  const { setComponent } = useContext(SubmenuContext);
  useEffect(() => {
    setComponent(<>{children}</>);
    return () => {
      setComponent(undefined);
    };
  }, [children, setComponent]);
  return <></>;
};
