import { PreviewPropsBase, QuestionPreviewModuleConfiguration } from "..";
import { Estimate } from "estimates";
import { Action } from "utils";
import { QuantityDefinition } from ".";
import styled from "styled-components";
import { Tooltip } from "../Tooltip";

export const Configuration: QuestionPreviewModuleConfiguration<
  PreviewProps,
  QuantityDefinition,
  Actions
> = {
  typeId: "quantity",
  isValidToSubmit: (state, definition) => {
    if (!definition.mandatory) return true;
    return !!state.count;
  },
  presentAnswerText: (state) => {
    return state.count?.toString();
  },
  extractEstimatesFromPreviewData: (input, definition) => {
    const quantityIncludedInBasePrice =
      definition.quantityIncludedInBasePrice ?? 1;
    let list: Estimate[] = [];
    for (let i = 0; i < input.count - quantityIncludedInBasePrice; i++) {
      list.push(definition.estimate);
    }
    return list;
  },
  getDefaultPreviewData: (definition) => {
    const quantityIncludedInBasePrice =
      definition.quantityIncludedInBasePrice ?? 1;

    return {
      count: quantityIncludedInBasePrice,
    };
  },
  getPreviewDataReducer: (state, action, definition) => {
    const baseAndLowestValue = definition.quantityIncludedInBasePrice ?? 1;
    switch (action.type) {
      case "increase":
        return { ...state, count: state.count + 1 };
      case "decrease": {
        if (baseAndLowestValue === state.count) return state;
        return { ...state, count: state.count - 1 };
      }
    }
    return state;
  },
  previewQuestion: ({ definition, state, dispatch }) => {
    return (
      <Wrapper>
        <LabelCol>
          <QuestionText>{definition.questionText}</QuestionText>
          <Tooltip text={definition.tooltipText} />
        </LabelCol>
        <ButtonWrapper>
          <button onClick={() => dispatch({ type: "decrease" })}>-</button>
          <Count>{state.count}</Count>
          <button onClick={() => dispatch({ type: "increase" })}>+</button>
        </ButtonWrapper>
      </Wrapper>
    );
  },
  presentAnswer: ({ definition, state }) => {
    return (
      <Wrapper>
        <QuestionText>{definition.questionText}</QuestionText>
        <Count>{state.count} st</Count>
      </Wrapper>
    );
  },
};

interface PreviewProps extends PreviewPropsBase {
  count: number;
}

interface Increase extends Action {
  type: "increase";
}
interface Decrease extends Action {
  type: "decrease";
}
type Actions = Increase | Decrease;

const LabelCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const QuestionText = styled.span``;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & button {
    border: 0;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 0.25rem;
  }

  & button:first-child {
    background-color: white;
  }

  & button:last-child {
    background-color: black;
    color: white;
  }

  & span {
    padding: 0 0.5rem;
    background-color: red;
  }
`;
const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
`;
