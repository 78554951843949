import styled from "styled-components";
import { media } from "../media";
import { colors } from "../statics";

const Headline = styled.div`
  font-family: inherit;
  letter-spacing: -0.03%;
`;

export const Headline1 = styled(Headline)`
  font-family: Clas Ohlson Sans Web bold;
  font-size: 2.7rem;
  line-height: 3rem;
  margin-bottom: 1.3rem;
  margin-top: 1rem;
`;

export const HeadLineVW = styled(Headline)`
  font-family: Clas Ohlson Sans Web bold;
  font-size: 3rem;
  line-height: 3rem;
  margin-top: 2rem;
  margin-bottom: 1.3rem;

  ${media.tablet} {
    font-size: 2rem;
  }

  ${media.desktop} {
    font-size: 3rem;
  }
`;

export const Headline2 = styled(Headline)`
  font-family: Clas Ohlson Sans Web bold;
  font-size: 2.625rem;
  line-height: 2.625rem;
`;

export const Headline3 = styled(Headline)`
  font-family: Clas Ohlson Sans Web medium;
  font-size: 2.1875rem;
  line-height: 2.1875rem;
  margin-bottom: 0.5rem;
`;

export const SubHeadline4 = styled(Headline)`
  font-family: Clas Ohlson Sans Web bold;
  font-size: 1.75rem;
  line-height: 1.75rem;
`;

export const SubHeadline4Medium = styled(Headline)`
  font-family: Clas Ohlson Sans Web medium;
  font-size: 1.75rem;
  line-height: 1.75rem;
`;

export const SubHeadline5 = styled(Headline)`
  font-family: Clas Ohlson Sans Web bold;
  font-size: 1.3125rem;
  line-height: 1.3125rem;
  ${media.desktop} {
  }
`;

export const SubHeadline5Medium = styled(Headline)`
  font-family: Clas Ohlson Sans Web Medium;
  font-size: 1.3125rem;
  line-height: 1.3125rem;
`;

export const SubHeadline6 = styled(Headline)`
  font-family: Clas Ohlson Sans Web Medium;
  font-size: 1rem;
  line-height: 1rem;
`;

export const ListHeadline = styled(Headline)`
  font-family: Clas Ohlson Sans Web Medium;
  font-size: 1.1rem;
  line-height: 1rem;
`;

export const SubHeadline6Grey = styled(Headline)`
  font-family: Clas Ohlson Sans Web medium;
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${colors.grey[1]};
`;

export const BodyText = styled.pre`
  font-size: 1 rem;
  line-height: 1.2rem;
  letter-spacing: none;
  margin-bottom: 2.625rem;
  white-space: pre-wrap;
  font-family: inherit;
  letter-spacing: -0.03%;
  font-size: 1rem;
`;

export const BodyTextBold = styled(Headline)`
  font-family: Clas Ohlson Sans Web Bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: none;
  color: ${colors.font.black};
`;

export const BodyTextMedium = styled(Headline)`
  font-family: Clas Ohlson Sans Web medium;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: none;
  color: ${colors.grey[2]};
`;

export const SmallText = styled(Headline)`
  font-family: Clas Ohlson Sans Web medium;
  font-size: 0.625rem;
  line-height: 0.8125rem;
  letter-spacing: 0.4%;
`;
export const SmallTextReg = styled(Headline)`
  font-family: Clas Ohlson Sans Web;
  font-size: 0.625rem;
  line-height: 0.8125rem;
  letter-spacing: 0.4%;
`;
export const SummaryTitlePrice = styled(SubHeadline6)`
  font-size: 0.9rem;
  line-height: 0.9rem; ;
`;
