import { useEffect } from "react";
import styled from "styled-components";
import { useCart } from "../../Cart";
import { media } from "../../style/media";
import {
  BodyText,
  BodyTextBold,
  SubHeadline5,
  SubHeadline6,
} from "../../style/Text";
import { useCheckout } from "../Checkout/context";
import { useStepper } from "../Stepper/useStepper";

export function PersonalDetailsSummary() {
  const {
    formState: { personal, gateAccessCode },
  } = useCheckout();
  const { move } = useStepper();
  const { postalCode } = useCart();
  useEffect(() => {
    if (!personal) move("backwards");
  }, []);
  if (!personal) {
    return (
      <>Du behöver fylla i dina person och kontaktuppgifter för att gå vidare</>
    );
  }
  return (
    <>
      <SubHeadline5>Dina Uppgifter</SubHeadline5>
      <Service>
        <ServiceInfoRowHeader>
          <SubHeadline6>Personuppgifter</SubHeadline6>
        </ServiceInfoRowHeader>
        <ServiceInfoRowAddon>
          <DetailHeadline>Förnamn</DetailHeadline>
          <DetailText>{personal.firstName}</DetailText>
        </ServiceInfoRowAddon>
        <ServiceInfoRowAddon>
          <DetailHeadline>Efternamn</DetailHeadline>
          <DetailText>{personal.surname}</DetailText>
        </ServiceInfoRowAddon>
        <ServiceInfoRowAddon>
          <DetailHeadline>E-mail</DetailHeadline>
          <DetailText>{personal.email}</DetailText>
        </ServiceInfoRowAddon>
        <ServiceInfoRowAddon>
          <DetailHeadline>Telefonnummer</DetailHeadline>
          <DetailText>{personal.phone}</DetailText>
        </ServiceInfoRowAddon>
        <ServiceInfoRowHeader>
          <SubHeadline6>Address</SubHeadline6>
        </ServiceInfoRowHeader>
        <ServiceInfoRowAddon>
          <DetailHeadline>Gatunamn och nummer</DetailHeadline>
          <DetailText>{personal.street}</DetailText>
        </ServiceInfoRowAddon>
        <ServiceInfoRowAddon>
          <DetailHeadline>Postnummer</DetailHeadline>
          <DetailText>{postalCode}</DetailText>
        </ServiceInfoRowAddon>
        <ServiceInfoRowAddon>
          <DetailHeadline>Stad</DetailHeadline>
          <DetailText>{personal.city}</DetailText>
        </ServiceInfoRowAddon>
        <ServiceInfoRowAddon>
          <DetailHeadline>Boendeform</DetailHeadline>
          <DetailText>{personal.livingForm}</DetailText>
        </ServiceInfoRowAddon>

        {!!gateAccessCode ? (
          <ServiceInfoRowAddon>
            <DetailText>Portkod</DetailText>
            <DetailText>{gateAccessCode}</DetailText>
          </ServiceInfoRowAddon>
        ) : null}
      </Service>
    </>
  );
}

const Service = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceInfoRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.3125rem;
`;

const ServiceInfoRowAddon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;

  ${media.mobile} {
    justify-content: space-between;
    flex-direction: row;
    margin-left: 1.3125rem;
    margin-top: 0.625rem;
    padding: 0;
  }
`;

const DetailHeadline = styled(BodyTextBold)`
  margin: 0;
  color: black;
`;

const DetailText = styled(BodyText)`
  margin: 0;
`;
