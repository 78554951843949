import { FC } from "react";
import {
  estimatedTimeAndPriceText,
  rebatePrice,
  summarizePriceEstimates,
} from "estimates";
import styled from "styled-components";
import { useCart } from "../../Cart";
import { ServiceBundleSubstitute } from "../serviceBundle/useServiceBundlePreview";
import { SubHeadline6 } from "../../style/Text";
import { colors } from "../../style/statics";

export const BundleTitle: FC<{ bundle: ServiceBundleSubstitute }> = ({
  bundle,
}) => {
  const { isClasMedlem, useDeduction } = useCart();
  const estimates = [bundle.estimate];
  const result = summarizePriceEstimates(estimates);
  const resultWithRebateOnPrice = rebatePrice(
    result,
    isClasMedlem,
    useDeduction ? bundle.deductable : "None"
  );
  const estimate = estimatedTimeAndPriceText(resultWithRebateOnPrice);
  return (
    <TitleBackground>
      <SubHeadline6>{bundle.cms.title}</SubHeadline6>
      <PriceLabel>{estimate}</PriceLabel>
    </TitleBackground>
  );
};

const TitleBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PriceLabel = styled(SubHeadline6)`
  color: ${colors.grey[1]};
  margin-top: 0.438rem;
`;
