import { PreviewPropsBase, QuestionPreviewModuleConfiguration } from "..";
import { Action } from "utils";
import { ProductSelectionDefinition } from ".";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Checkbox } from "../Checkbox";

export const Configuration: QuestionPreviewModuleConfiguration<
  PreviewProps,
  ProductSelectionDefinition,
  Actions
> = {
  typeId: "product-selection-question",
  isValidToSubmit: (state, definition) => {
    if (!definition.mandatory) return true;
    return !!state.selectedProductId;
  },
  presentAnswerText: (state) => {
    return state.selectedProductId;
  },
  extractEstimatesFromPreviewData: (input, definition) => {
    if (definition?.products === undefined) return [];
    const product = definition.products.find(
      (a) => a.id === input.selectedProductId
    );
    if (product) return [product.estimate];

    return [];
  },
  getDefaultPreviewData: () => {
    return {
      answer: "dont bring products",
    };
  },
  getPreviewDataReducer: (state, action, definition) => {
    switch (action.type) {
      case "select_choice": {
        const selectedProductId =
          action.answer === "dont bring products"
            ? undefined
            : state.selectedProductId;
        return { ...state, answer: action.answer, selectedProductId };
      }
      case "select_product": {
        return { ...state, selectedProductId: action.productId };
      }
    }
    return state;
  },
  previewQuestion: ({ definition, state, dispatch }) => {
    const [selectProduct, setSelectProduct] = useState<boolean>(
      state.answer === "bring products"
    );
    useEffect(() => {
      setSelectProduct(state.answer === "bring products");
    }, [state.answer]);

    return (
      <div>
        <Wrapper>
          <QuestionText>
            {definition.questionText}
            {definition.mandatory && " *"}
          </QuestionText>
          <YesPleaseText>Gärna!</YesPleaseText>
          <Checkbox
            checked={selectProduct}
            onChange={() => {
              if (selectProduct) {
                dispatch({
                  type: "select_choice",
                  answer: "dont bring products",
                });
              } else {
                dispatch({ type: "select_choice", answer: "bring products" });
              }
            }}
          />
        </Wrapper>
        <DescriptionText>
          Du betalar här och vi tar med den hem till dig
        </DescriptionText>

        {selectProduct && (
          <ProductSelection>
            {definition.products.map((c) => (
              <Product
                className={
                  c.id === state.selectedProductId ? "selected" : "not-selected"
                }
                onClick={() =>
                  dispatch({ type: "select_product", productId: c.id })
                }
                key={c.id}
              >
                <ProductImg src={c.imageUrl} alt={c.description} />
                {c.description}
                <ProductBtn target="_blank" href={c.productUrl}>
                  Läs mer..
                </ProductBtn>
              </Product>
            ))}
          </ProductSelection>
        )}
      </div>
    );
  },
  presentAnswer: ({ definition, state }) => {
    return (
      <Wrapper>
        <QuestionText>{definition.questionText}</QuestionText>
        <QuestionText>{state.answer}</QuestionText>
        {state.answer === "bring products" && (
          <QuestionText>
            {
              definition.products.find((a) => a.id === state.selectedProductId)
                ?.description
            }
          </QuestionText>
        )}
      </Wrapper>
    );
  },
};

interface PreviewProps extends PreviewPropsBase {
  answer: Answer;
  selectedProductId?: string;
}
type Answer = "dont bring products" | "bring products";

interface SelectChoice extends Action {
  type: "select_choice";
  answer: Answer;
}
interface SelectProduct extends Action {
  type: "select_product";
  productId: string;
}

type Actions = SelectChoice | SelectProduct;

const YesPleaseText = styled.span`
  margin-right: 0.5rem;
`;
const DescriptionText = styled.div`
  font-size: 0.8rem;
  font-style: italic;
`;

const ProductBtn = styled.a`
  border-radius: 0.25rem;
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  text-decoration: none;
  background-color: black;
  color: white;

  &:hover {
    background-color: #78d7fa;
    color: black;
    transition: 0.1s ease-in;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const QuestionText = styled.span`
  width: 100%;
`;

const ProductSelection = styled.div`
  display: flex;
  display: inline-flex;
  gap: 0.8rem;
  width: 100%;
  margin-top: 1rem;
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: white;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 0.08rem solid black;

  cursor: pointer;

  &.selected {
    border: 0.4rem solid #78d7fa;
    padding: 0.65rem;
    transition: 0.08s ease-in;
  }
`;

const ProductImg = styled.img`
  width: 100%;
  height: 6rem;
  object-fit: contain;

  @media (min-width: 720px) {
    height: 10rem;
  }
`;
