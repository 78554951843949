import { FC } from "react";
import styled from "styled-components";
import { useSubCategory } from "./Global/useCategory";
import { BodyText, HeadLineVW } from "../style/Text";

export const SubCategoryDescription: FC = () => {
  const category = useSubCategory();
  if (!category) return <>No category</>;
  const { name, longDescription, imgUrl } = category;
  return (
    <div>
      {imgUrl && <CategoryImage src={imgUrl} alt={name} />}

      <HeadLineVW>{name}</HeadLineVW>

      {longDescription && (
        <BodyText>
          <div dangerouslySetInnerHTML={{ __html: longDescription }}></div>
        </BodyText>
      )}
    </div>
  );
};

const CategoryImage = styled.img`
  max-width: 25rem;
  max-height: 25rem;
`;
