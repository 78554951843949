import { GoBackButton } from "../Checkout/components/GoBackButton";
import { Col, Row } from "react-bootstrap";
import { Submenu } from "../Global/SubMenu";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { colors } from "../style/statics";
import { BodyText, Headline1 } from "../style/Text";
import { BlackButton } from "../Global/components/BlackButton";
import { Layout } from "./Layout";
import { media } from "../style/media";
import {DeductionChecker } from "../MainPage/ClasMedlemChecker";
import { useCart } from "./useCart";
import TagManager from "react-gtm-module";
import {
  estimatedPriceWithDeductionsAndRebates,
  extractHourlyRateFromBundle,
  meetsMinimumRequirement,
  summarizeFromAndToPrice,
  summarizePriceEstimates,
} from "estimates";

export function CartPage() {
  const { enhancedCartItemsInCart, useDeduction, isClasMedlem } = useCart();
  console.log(enhancedCartItemsInCart);
  useEffect(() => {
    window.scrollTo(0, 0);

    let totalpriceWithoutDeductionAndClubClas = 0;

    const allPrices = enhancedCartItemsInCart.flatMap((s) => {
      const estimates = s.questionAnswers.flatMap((a) => {
        return a.meta.extractEstimatesFromPreviewData(a.state, a.definition);
      });

      const result = summarizePriceEstimates([
        ...estimates,
        s.serviceBundle?.estimate!,
      ]);

      const subTotalPriceWithoutDeduction =
        estimatedPriceWithDeductionsAndRebates({
          ...result,
          deduction: "None",
          isClasMedlem: false,
          hourlyRate: extractHourlyRateFromBundle(s.serviceBundle),
        });

      const subTotal = estimatedPriceWithDeductionsAndRebates({
        ...result,
        deduction: useDeduction ? s.serviceBundle?.deductable! : "None",
        isClasMedlem,
        hourlyRate: extractHourlyRateFromBundle(s.serviceBundle),
      });

      const bundlePrice = result?.price?.amount
        ? result?.price?.amount
        : subTotalPriceWithoutDeduction.fromPrice;
      totalpriceWithoutDeductionAndClubClas =
        totalpriceWithoutDeductionAndClubClas + bundlePrice;

      return subTotal;
    });
    const totalPrice = summarizeFromAndToPrice(allPrices);

    if (typeof window !== "undefined" && enhancedCartItemsInCart.length > 0) {
      const tagManagerArgs = {
        dataLayer: {
          event: "funnel_boka.clasfixare.se_cart",
          tasks: enhancedCartItemsInCart,
          totalPrice,
          useDeduction,
          isClasMedlem,
          totalpriceWithoutDeductionAndClubClas,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  }, []);

  return (
    <>
      <Submenu>
        <GoBackButton />
      </Submenu>
      <div>
        <Row>
          <Col>
            <Headline1>Din Varukorg</Headline1>
            <BodyText>
              Här kan du ändra dina varor om du skulle vilja det annars kan du
              gå vidare till utcheckning
            </BodyText>
          </Col>
          <CartCol>
            <Layout />
            <HasEnoughEstimatesToBook />
            <Deduction>
              <DeductionChecker />
            </Deduction>
          </CartCol>
        </Row>
      </div>
    </>
  );
}
function HasEnoughEstimatesToBook() {
  const { enhancedCartItemsInCart, useDeduction, isClasMedlem } = useCart();
  const navigation = useNavigate();

  const allPrices = enhancedCartItemsInCart.flatMap((s) => {
    const estimates = s.questionAnswers.flatMap((a) => {
      return a.meta.extractEstimatesFromPreviewData(a.state, a.definition);
    });

    const result = summarizePriceEstimates([
      ...estimates,
      s.serviceBundle?.estimate!,
    ]);
    return estimatedPriceWithDeductionsAndRebates({
      ...result,
      deduction: useDeduction ? s.serviceBundle?.deductable! : "None",
      isClasMedlem,
      hourlyRate: extractHourlyRateFromBundle(s.serviceBundle),
    });
  });
  const totalPrice = summarizeFromAndToPrice(allPrices);

  const { meetsMinimum } = meetsMinimumRequirement(
    totalPrice,
    useDeduction,
    isClasMedlem
  );

  return (
    <>
      {!meetsMinimum && (
        <span>
          <AddProduct onClick={() => navigation("/")}>
            Lägg till ny produkt
          </AddProduct>
        </span>
      )}
    </>
  );
}

const AddProduct = styled(BlackButton)`
  width: 50%;
`;

const Deduction = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    flex-direction: row;
  }
`;

const CartCol = styled(Col)`
  padding-bottom: 2rem;
`;
