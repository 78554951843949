import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { ConfigurationProvider } from "./utils/configuration";

console.log("Version 0.0.32 - Image resize before pushing to Salesforce");
ReactDOM.render(
  <React.StrictMode>
    <ConfigurationProvider>
      <App />
    </ConfigurationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
