import { Deductable, Estimate } from "../interfaces";
import {
  estimatedPriceText,
  estimatedPriceWithDeductionsAndRebates,
  extractHourlyRateFromBundle,
  SumupFromAndToPrices,
} from "./output";
import { summarizePriceEstimates } from "./summarize";
import { DEFAULT_PRICE, QUOTE_REQUIRED_TYPE } from "utils";
import { checkQuestionsForQuoteRequired } from "utils";

export interface OutputEnhancedCartItem {
  serviceBundle: {
    estimate: Estimate;
    deductable: Deductable;
  };
  questionAnswers: PreviewStateItem[];
}

interface PreviewStateItem {
  questionDefinitionId: string;
  state: any;
  definition: any;
  meta: any;
  estimates: Estimate[];
}

export function getAllEstimatesForEnhancedCartItems(
  services: OutputEnhancedCartItem[],
  useDeduction: boolean,
  isClasMedlem: boolean
) {
  const err = services.flatMap((service) => {
    let hourlyRate = extractHourlyRateFromBundle(service.serviceBundle);
    const questionEstimates = service.questionAnswers.flatMap((s) => {
      return s.meta.extractEstimatesFromPreviewData(s.state, s.definition);
    });
    const estimates = [...questionEstimates, service.serviceBundle?.estimate!];
    const result = summarizePriceEstimates(estimates);
    return estimatedPriceWithDeductionsAndRebates({
      ...result,
      deduction: useDeduction ? service.serviceBundle?.deductable! : "None",
      isClasMedlem,
      hourlyRate,
    });
  });

  return SumupFromAndToPrices(err);
  // return estimatedPriceText(fromToprices);
}

export function getTotalPriceForEnhancedCartItem(
  service: OutputEnhancedCartItem,
  useDeduction: boolean,
  isClasMedlem: boolean,
  hourlyRate: number = DEFAULT_PRICE
) {
  const questionEstimates = service.questionAnswers.flatMap((s) => {
    return s.meta.extractEstimatesFromPreviewData(s.state, s.definition);
  });

  const estimates = [...questionEstimates, service.serviceBundle?.estimate!];
  const result = summarizePriceEstimates(estimates);

  const allPrices = estimatedPriceWithDeductionsAndRebates({
    from: result.from,
    to: result.to,
    price: result.price,
    deduction: useDeduction ? service.serviceBundle?.deductable! : "None",
    isClasMedlem,
    hourlyRate,
  });
  return estimatedPriceText(allPrices);
}

export function getTotalPriceForEnhancedCartItemNumber(
  service: OutputEnhancedCartItem,
  useDeduction: boolean,
  isClasMedlem: boolean,
  hourlyRate: number = DEFAULT_PRICE
) {
  const questionEstimates = service.questionAnswers.flatMap((s) => {
    return s.meta.extractEstimatesFromPreviewData(s.state, s.definition);
  });

  const estimates = [...questionEstimates, service.serviceBundle?.estimate!];
  const result = summarizePriceEstimates(estimates);

  const allPrices = estimatedPriceWithDeductionsAndRebates({
    from: result.from,
    to: result.to,
    price: result.price,
    deduction: useDeduction ? service.serviceBundle?.deductable! : "None",
    isClasMedlem,
    hourlyRate,
  });
  if (allPrices.fromPrice > allPrices.toPrice) {
    return allPrices.toPrice;
  }

  return allPrices.fromPrice;
}

export function getBasePriceForEhancedCartItem(
  service: OutputEnhancedCartItem,
  useDeduction: boolean,
  isClasMedlem: boolean,
  hourlyRate
) {
  const estimates = [service.serviceBundle?.estimate!];
  const result = summarizePriceEstimates(estimates);
  const allPrices = estimatedPriceWithDeductionsAndRebates({
    ...result,
    deduction: useDeduction ? service.serviceBundle?.deductable! : "None",
    isClasMedlem,
    hourlyRate,
  });
  return estimatedPriceText(allPrices);
}

export function getPriceForEstimatesList(
  estimates: Estimate[],
  deductable: Deductable,
  useDeduction: boolean,
  isClasMedlem: boolean,
  hourlyRate
) {
  const result = summarizePriceEstimates(estimates);
  const allPrices = estimatedPriceWithDeductionsAndRebates({
    ...result,
    deduction: useDeduction ? deductable : "None",
    isClasMedlem,
    hourlyRate,
  });
  return estimatedPriceText(allPrices);
}

export function meetsMinimumRequirementForCheckout(estimates: Estimate[]) {
  const result = summarizePriceEstimates(estimates);

  return result.from.length >= 60;
}

export const enhancedItemsContainsQuoteRequired = (
  enhancedCartItems
): Boolean => {
  const containsOfferRequired = (element) => {
    return checkBundleForQuoteRequired(element);
  };
  return enhancedCartItems.some(containsOfferRequired);
};

export const getQtyFromAnswer = (answer): Number => {
  switch (answer.meta.typeId) {
    case "longanswertext":
    case "multi-question":
    case "yesno-question":
    case "product-selection-question":
    case "text":
      return 1;
    case "quantity":
      return answer.state.count;
    default:
      return 1;
  }
};

export const checkBundleForQuoteRequired = (item): Boolean => {
  if (item?.serviceBundle?.estimate?.type === QUOTE_REQUIRED_TYPE) {
    return true;
  }
  return (
    item.questionAnswers.filter((question) => {
      return checkQuestionsForQuoteRequired(question, item);
    }).length > 0
  );
};

export const checkEstimatesForQuoteRequired = (estimates): Boolean => {
  return estimates.some((est: Estimate) => est.type === QUOTE_REQUIRED_TYPE);
};
