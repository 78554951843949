export const colors = {
  brandColor: "#78D7FA",
  lightBlueBackground: "#DCE6F0",
  grey: {
    0: "#000000",
    1: "#5F646A",
    2: "#A8ACAD",
    3: "#EEEEEC",
    4: "#FFFFFF",
  },
  secondary: {
    blue: {
      3: "#A5B9C8",
      2: "#B4E6FA",
      1: "#DCE6F0",
      0: "#8CC8DC",
    },
    green: {
      3: "#AAD7B4",
      2: "#C8F0D2",
      1: "#AFBEB9",
      0: "#E1E6E1",
    },
    yellow: {
      3: "#EBDB64",
      2: "#FAEB8C",
      1: "#D2C8AA",
      0: "#F5F0D2",
    },
    red: {
      3: "#F5DCBE",
      2: "#FABEAA",
      1: "#E66F5A",
      0: "#DCAF91",
    },
    pink: {
      3: "#F4AABE",
      2: "#FAD2DC",
      1: "#F5DCD7",
      0: "#DCAFAA",
    },
  },
  accent: {
    green: "#FF8D80",
    pink: "#D2C8B4",
    tan: "#F5AFE6",
    red: "#80E0A6",
    yellow: "#F5EB32",
  },
  illustration: {
    green: "#80E0A6",
    yellow: "#F5EB32",
    blue: "#78D7FA",
    red: "#FF2600",
  },
  font: {
    red: "#CC3C3C",
    white: "#FFFFFF",
    black: "#000000",
  },
};

export const MissingBundleImage =
  "https://www.clasfixare.se/wp-content/uploads/2022/03/Picto_Heart_Black-1.svg";
