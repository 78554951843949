import {
  CheckoutQuestionDefinition,
  CheckoutQuestionPreviewConfiguration,
} from "checkoutquestions";
import { Estimate } from "estimates";
import { QuestionDefinition, QuestionPreviewConfiguration } from "questions";
import { Action } from "utils";

export function reducer(
  state: PreviewItemsState,
  action: Actions
): PreviewItemsState {
  switch (action.type) {
    case "reset": {
      const items = action.questions.map((s) => {
        const estimates = s.meta.extractEstimatesFromPreviewData(
          s.state,
          s.definition
        );
        return { ...s, estimates };
      });
      return { items };
    }
    case "StateUpdated": {
      const items = state.items.map((s) => {
        if (s.questionDefinitionId === action.questionDefinitionId) {
          const estimates = s.meta.extractEstimatesFromPreviewData(
            action.previewState,
            s.definition
          );
          return {
            ...s,
            state: action.previewState,
            estimates,
            isValidToSubmit: s.meta.isValidToSubmit(
              action.previewState,
              s.definition
            ),
          };
        }
        return s;
      });
      return { ...state, items };
    }
    default: {
      return state;
    }
  }
}
interface Reset extends Action {
  type: "reset";
  questions: AnswerPreviewStateItem[];
}
interface QuestionStateUpdated extends Action {
  type: "StateUpdated";
  questionDefinitionId: string;
  previewState: any;
}
export type Actions = Reset | QuestionStateUpdated;

interface PreviewItemsState {
  items: AnswerPreviewStateItem[];
}

export interface AnswerPreviewStateItem {
  isValidToSubmit: boolean;
  questionDefinitionId: string;
  state: any;
  definition: QuestionDefinition;
  meta: QuestionPreviewConfiguration;
  estimates: Estimate[];
}

export interface CheckoutAnswerPreviewStateItem {
  checkoutQuestionDefinitionId: string;
  state: any;
  definition: CheckoutQuestionDefinition;
  meta: CheckoutQuestionPreviewConfiguration;
}
