import styled from "styled-components";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const GoBackButton = () => {
  const navigate = useNavigate();

  function cancelCheckout() {
    navigate(-1);
  }
  return (
    <>
      <BackButton onClick={cancelCheckout}>
        <MdKeyboardArrowLeft /> Tillbaka
      </BackButton>
    </>
  );
};

const BackButton = styled.button`
  border: 0;
  margin-top: 1rem;
  background-color: transparent;
`;
