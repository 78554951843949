import { useEffect } from "react";
import { useConfig } from "../utils/configuration";
import TagManager from "react-gtm-module";
export function useTagManager() {
  const { gtmId } = useConfig();

  useEffect(() => {
    TagManager.initialize({ gtmId });
  }, [gtmId]);
}
