import { reducer } from "./reducer";
import { FC, useEffect, useReducer } from "react";
import styled from "styled-components";
import { EnhancedCartItem, useCart } from "../../Cart";
import { media } from "../../style/media";
import { PreviewPropsBase } from "questions";

export const EditElement: FC<{ item: EnhancedCartItem }> = ({ item }) => {
  const { dispatch: dispatchCart } = useCart();
  const allCheckoutQuestionAnswers = item.checkoutQuestionAnswers.filter(
    (a) => a !== undefined
  );
  const [allItems, dispatch] = useReducer(reducer, {
    items: allCheckoutQuestionAnswers,
  });
  useEffect(() => {
    dispatch({ type: "reset", questions: allCheckoutQuestionAnswers });
  }, [item]);

  function save(
    checkoutQuestionDefinitionId: string,
    answerState: PreviewPropsBase
  ) {
    dispatchCart({
      type: "updated-item-in-checkout",
      serviceInCartId: item.serviceInCartId,
      answer: {
        checkoutQuestionDefinitionId,
        answerState,
      },
    });
  }

  return (
    <Configuration>
      <ItemWrapper>
        {allItems.items.map(
          ({ state, definition, meta, checkoutQuestionDefinitionId }) => (
            <AnswerItem key={checkoutQuestionDefinitionId}>
              {meta.previewQuestion({
                definition,
                state,
                dispatch: (action) => {
                  const previewState = meta.getPreviewDataReducer(
                    state,
                    action,
                    definition
                  );
                  save(checkoutQuestionDefinitionId, previewState);
                },
              })}
            </AnswerItem>
          )
        )}
      </ItemWrapper>
    </Configuration>
  );
};

const Configuration = styled.div`
  border-radius: 0.25rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const AnswerItem = styled.div`
  height: 100px;
  min-width: 100%;
  flex-grow: 1;
  ${media.tablet} {
    height: 200px;
    min-width: unset;
  }
`;
