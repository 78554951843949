import styled from "styled-components";
import { useCart } from "../../Cart";
import { HeaderImage, HeaderTitle } from "../../Cart/Layout";
import { BlackBtn } from "../../style/Buttons";
import { colors } from "../../style/statics";
import { SubHeadline4, SubHeadline6Grey } from "../../style/Text";
import { BtnContainer, StepHeader } from "../Step-1";
import { SimpleStepper } from "../Stepper";
import { useStepper } from "../Stepper/useStepper";
import { EditElement } from "./EditCheckoutItem";

export function QuestionsPage() {
  const { enhancedCartItemsInCart } = useCart();
  const { move } = useStepper();

  return (
    <div>
      <SimpleStepper />
      <Wrapper>
        <StepHeader>
          <SubHeadline4>Ladda upp bilder till dina valda tjänster</SubHeadline4>
        </StepHeader>
        {enhancedCartItemsInCart.map((a) => {
          return (
            <div key={a.serviceInCartId}>
              {a.checkoutQuestionAnswers.length !== 0 && (
                <>
                  <HeaderWrapper>
                    <HeaderImage item={a} />
                    <HeaderTitle item={a} />
                  </HeaderWrapper>
                  <EditElement item={a} />
                  <hr />
                </>
              )}
            </div>
          );
        })}
        {enhancedCartItemsInCart.flatMap((a) => a.checkoutQuestionAnswers)
          .length === 0 && (
          <SubHeadline6Grey>
            Du behöver inte ladda upp några bilder, fortsätt till nästa steg
          </SubHeadline6Grey>
        )}

        {/* <div>
                {enhancedCartItemsInCart.map(a => {

                    return <div key={a.serviceInCartId}>
                        {a.checkoutQuestionAnswers.map(b => {

                            const html = b.meta.presentAnswerHTML({ definition: b.definition, state: b.state });

                            return <div key={b.checkoutQuestionDefinitionId}>
                                <div dangerouslySetInnerHTML={{ __html: html }} />
                            </div>
                        })}
                    </div>
                })}
            </div> */}
      </Wrapper>
      <BtnContainer>
        <BlackBtn onClick={() => move("forward")}>Nästa</BlackBtn>
      </BtnContainer>
    </div>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem;
  & img {
    width: 2.5rem;
  }
`;
const Wrapper = styled.div`
  background-color: ${colors.secondary.blue[2]};
  padding: 1.3125rem;
`;
