import { PreviewPropsBase, QuestionPreviewModuleConfiguration } from "..";
import { Action } from "utils";
import styled from "styled-components";
import { FreeTextDefinition } from ".";
import { Tooltip } from "../Tooltip";

export const Configuration: QuestionPreviewModuleConfiguration<
  FreeTextPreviewProps,
  FreeTextDefinition,
  Actions
> = {
  typeId: "text",
  isValidToSubmit: (state, definition) => {
    if (!definition.mandatory) return true;
    return !!state.answerText;
  },
  presentAnswerText: (state) => {
    return state.answerText;
  },
  extractEstimatesFromPreviewData: (input, definition) => {
    return [];
  },
  getDefaultPreviewData: () => {
    return {
      answerText: "",
    };
  },
  getPreviewDataReducer: (state, action, definition) => {
    switch (action.type) {
      case "edited_text":
        return { ...state, answerText: action.value };
    }
    return state;
  },
  previewQuestion: ({ definition, state, dispatch }) => {
    return (
      <Wrapper>
        <LabelCol>
          <QuestionText>
            {definition.questionText}
            {definition.mandatory && " *"}
          </QuestionText>
          <Tooltip text={definition.tooltipText} />
        </LabelCol>
        <QuestionInput
          value={state.answerText}
          onChange={(e: any) =>
            dispatch({ type: "edited_text", value: e.target.value })
          }
        />
      </Wrapper>
    );
  },
  presentAnswer: ({ definition, state }) => {
    return (
      <Wrapper>
        <QuestionText>{definition.questionText}</QuestionText>
        <QuestionText>{state.answerText}</QuestionText>
      </Wrapper>
    );
  },
};

interface FreeTextPreviewProps extends PreviewPropsBase {
  answerText: string;
}

interface EditedText extends Action {
  type: "edited_text";
  value: string;
}

type Actions = EditedText;

const LabelCol = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
`;

const QuestionText = styled.span``;
const QuestionInput = styled.input`
  background-color: white;
  border-radius: 0.25rem;
  border: 0.125rem solid black;
  padding: 0.45rem 0.875rem;
  overflow: auto;
  width: 100%;
  margin-top: 0.5rem;
`;
