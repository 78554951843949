import { Answer, CheckoutAnswer } from "booking-backend/src/Cart/reducer";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useToast } from "../../Global/components/Toast";
import { useCart } from "../../Cart";
import { BlackBtn, WhiteBtn } from "../../style/Buttons";
import { colors } from "../../style/statics";
import { useServiceBundleConfig } from "./useServiceBundlePreview";

export const AddToCart: FC<{ serviceBundleId: string }> = ({
  serviceBundleId,
}) => {
  const { dispatch, postalCode } = useCart();
  const {
    timeEstimate,
    questions,
    serviceBundle,
    invalidQuestionAnswerIds,
    checkoutQuestions,
  } = useServiceBundleConfig();
  const { categoryId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();

  const answers = questions.map(
    ({ questionDefinitionId, state }) =>
      ({
        questionDefinitionId,
        answerState: state,
      } as Answer)
  );

  const checkoutAnswers = checkoutQuestions.map(
    ({ checkoutQuestionDefinitionId, definition, meta, state }) =>
      ({
        checkoutQuestionDefinitionId,
        answerState: state,
      } as CheckoutAnswer)
  );
  return (
    <Wrapper>
      {!postalCode && (
        <MissingPostalCode
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Ange postnummer för att se vilka tjänster som är tillgängliga
        </MissingPostalCode>
      )}
      <AddButton
        disabled={invalidQuestionAnswerIds.length > 0 || !postalCode}
        onClick={() => {
          dispatch({
            type: "add-to-cart",
            serviceBundleId,
            answers,
            checkoutAnswers,
            timeEstimate,
          });
          navigate(`/${categoryId}`);
          toast({
            title: serviceBundle.cms.title,
            subtitle: "har lagts till i din varukorg",
            actions: [<DismissButton />, <CheckoutButton />],
          });
        }}
      >
        Lägg till
      </AddButton>
    </Wrapper>
  );
};

const DismissButton = () => {
  const { dismiss } = useToast();
  return <WhiteBtn onClick={dismiss}>Fortsätt beställning</WhiteBtn>;
};

const CheckoutButton = () => {
  const { dismiss } = useToast();
  const navigate = useNavigate();

  const go = () => {
    dismiss();
    navigate("/varukorg");
  };

  return <BlackBtn onClick={go}>Gå till varukorg</BlackBtn>;
};

const MissingPostalCode = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  font-size: 0.8rem;
  color: ${colors.font.red};
  cursor: pointer;
`;

const AddButton = styled.button`
  background-color: ${colors.grey[0]};
  border: 0;
  border-radius: 0.25rem;
  color: white;
  min-width: 6.125rem;
  height: 2.25rem;

  &:disabled {
    background-color: ${colors.grey[2]};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
