import { PreviewPropsBase, QuestionPreviewModuleConfiguration } from "..";
import { Action } from "utils";
import styled from "styled-components";
import { LongAnswerFreeTextDefinition } from ".";
import { Tooltip } from "../Tooltip";

export const Configuration: QuestionPreviewModuleConfiguration<
  LongAnswerFreeTextPreviewProps,
  LongAnswerFreeTextDefinition,
  Actions
> = {
  typeId: "longanswertext",
  isValidToSubmit: (state, definition) => {
    if (!definition.mandatory) return true;
    return !!state.answerText;
  },
  presentAnswerText: (state) => {
    return state.answerText;
  },
  extractEstimatesFromPreviewData: (input, definition) => {
    return [];
  },
  getDefaultPreviewData: () => {
    return {
      answerText: "",
    };
  },
  getPreviewDataReducer: (state, action, definition) => {
    switch (action.type) {
      case "edited_text":
        return { ...state, answerText: action.value };
    }
    return state;
  },
  previewQuestion: ({ definition, state, dispatch }) => {
    return (
      <Wrapper>
        <LabelCol>
          <QuestionText>
            {definition.questionText}
            {definition.mandatory && " *"}
          </QuestionText>
          <Tooltip text={definition.tooltipText} />
        </LabelCol>
        <QuestionInput
          rows={5}
          value={state.answerText}
          onChange={(e: any) =>
            dispatch({ type: "edited_text", value: e.target.value })
          }
        />
      </Wrapper>
    );
  },
  presentAnswer: ({ definition, state }) => {
    return (
      <Wrapper>
        <QuestionText>{definition.questionText}</QuestionText>
        <QuestionText>{state.answerText}</QuestionText>
      </Wrapper>
    );
  },
};

interface LongAnswerFreeTextPreviewProps extends PreviewPropsBase {
  answerText: string;
}

interface EditedText extends Action {
  type: "edited_text";
  value: string;
}

type Actions = EditedText;

const LabelCol = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const QuestionText = styled.div`
  display: block;
  align-self: start;
`;
const QuestionInput = styled.textarea`
  background-color: white;
  border-radius: 0.25rem;
  border: 0.125rem solid black;
  padding: 0.75rem 0.875rem;
  resize: vertical;
  display: block;
`;
