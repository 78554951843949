import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { ConfirmationPage } from "./Step-5/ConfirmationPage";
import { ServiceQuestionPage } from "./Step-1";
import { CheckoutSummaryPage } from "./Step-4";
import { QuestionsPage } from "./Step-2";
import { PersonalInformationPage } from "./Step-3/PersonalInformation";

export const CheckoutRouter: FC = () => {
  return (
    <>
      <Routes>
        <Route path="/deliverydate" element={<ServiceQuestionPage />} />
        <Route path="/questions" element={<QuestionsPage />} />
        <Route path="/personaldetails" element={<PersonalInformationPage />} />
        <Route path="/summary" element={<CheckoutSummaryPage />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
      </Routes>
    </>
  );
};
