import { FC } from "react";
import { MissingBundleImage } from "../../style/statics";
import styled from "styled-components";
import { ServiceBundleSubstitute } from "../serviceBundle/useServiceBundlePreview";

export const HeaderImage: FC<{ bundle: ServiceBundleSubstitute }> = ({
  bundle,
}) => {
  return (
    <ImageBackground>
      <BundleImage
        src={bundle.cms.imgUrl || MissingBundleImage}
        alt={bundle.cms.title}
      />
    </ImageBackground>
  );
};

const ImageBackground = styled.div`
  background-color: white;
  border-radius: 50%;
  padding: 0.8rem;
`;

const BundleImage = styled.img``;
