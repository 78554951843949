import { createContext, FC, useContext, useState } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../style/statics";
import { SubHeadline5 } from "../../style/Text";
import { AiFillCheckCircle } from "react-icons/ai";
import { media } from "../../style/media";
export const ToastContext: FC = ({ children }) => {
  const [current, setCurrent] = useState<ToastAction | undefined>();

  const toast = (toast: ToastAction) => {
    setCurrent(toast);
  };
  const dismiss = () => setCurrent(undefined);

  return (
    <context.Provider
      value={{
        toast,
        dismiss,
      }}
    >
      {children}
      {!!current && (
        <ToastContainer>
          <Left>
            {current.icon || (
              <AiFillCheckCircle color={colors.font.white} size={"2.625rem"} />
            )}
            <Title>
              <TitleText>{current.title}</TitleText>
              {current.subtitle && <span>{current.subtitle}</span>}
            </Title>
          </Left>

          <Right>
            {current.actions?.map((x, key) => (
              <ButtonWrapper key={key}>{x}</ButtonWrapper>
            ))}
          </Right>
        </ToastContainer>
      )}
    </context.Provider>
  );
};

const context = createContext<{
  toast: (toast: ToastAction) => void;
  dismiss: () => void;
}>(undefined as any);

export interface ToastAction {
  title: string;
  subtitle?: string;
  actions?: JSX.Element[];
  icon?: JSX.Element;
}

export const useToast = () => useContext(context);

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & button {
    min-width: 11rem;

    ${media.tablet} {
      margin-left: 1rem;
    }
  }
`;

const ButtonWrapper = styled(Col)`
  justify-content: center;
  display: flex;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`;

const TitleText = styled(SubHeadline5)`
  margin-bottom: 0;
`;

const ToastContainer = styled.div`
  position: fixed;
  top: 0rem;
  background-color: ${colors.secondary.green[3]};
  border-radius: 0.25rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0;
  z-index: 9999;

  ${media.tablet} {
    margin-top: 1.313rem;
    width: 40rem;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
  }

  ${media.desktop} {
    margin-top: 1.313rem;
    width: 60rem;
    border-radius: 4px;
  }
`;
