import styled from "styled-components";
import { media } from "../media";

const Button = styled.button`
  border-radius: 0.25rem;
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  width: 100;
  margin-top: 1rem;
  text-decoration: none;

  ${media.tablet} {
    margin: 1rem 0;
    max-width: 30%;
  }
`;

export const WhiteBtn = styled(Button)`
  color: black;
  background-color: white;
  border: 0;

  &:hover {
    background-color: black;
    color: white;
    transition: 0.05s ease-in;
  }

  &:active {
    background-color: grey;
    color: white;
  }
`;

export const StyledSpinner = styled.span`
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
`;

export const BlackBtn = styled(Button)`
  color: white;
  background-color: black;
  border: 0;
  &:hover {
    background-color: grey;
    color: white;
    transition: 0.05s ease-in;
  }

  &:active {
    background-color: #78d7fa;
    color: black;
  }

  &:disabled {
    background-color: grey;
  }
`;

export const BlueBtn = styled(Button)`
  color: black;
  margin: 1rem;
  margin-left: 0;
  border: none;
  background-color: #78d7fa;

  &:hover {
    background-color: white;
    color: black;
    transition: 0.05s ease-in;
  }

  &:active {
    background-color: grey;
    color: white;
  }
`;
