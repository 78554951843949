import { Price, Time } from "estimates";

export function getPrice(amount: number): Price {
  return {
    amount,
    currency: "sek",
  };
}
export function getTime(length: number): Time {
  return {
    length,
    variant: "minutes",
  };
}
export function joinTime(times: Time[]): Time {
  return times.reduce(
    (state, time) => {
      return { ...state, length: state.length + time.length };
    },
    { length: 0, variant: "minutes" }
  );
}
export function joinPrice(prices: Price[]): Price {
  return prices.reduce((state, price) => {
    return { ...state, amount: state.amount + price.amount };
  }, getPrice(0));
}
