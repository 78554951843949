import {
  FixedPrice,
  Price,
  Time,
  TimeEstimate,
  TimeEstimateInterval,
} from "estimates";
import { getTime } from "./helpers";

export function flattenFixedPrice(fixedPrice: FixedPrice[]): FixedPrice {
  const allPrices = fixedPrice.map((a) => a.price);
  const prices = allPrices.reduce(
    (total: Price, price: Price) => {
      switch (price.currency) {
        case "sek":
          return { ...total, amount: total.amount + price.amount };
      }
      return total;
    },
    { amount: 0, currency: "sek" }
  );
  return {
    type: "FixedPrice",
    price: prices,
  };
}
export function flattenTimeEstimate(fixedTime: TimeEstimate[]): TimeEstimate {
  const allTimes = fixedTime.map((a) => a.duration);
  const duration = allTimes.reduce(reduceTimeTotal, getTime(0));
  return {
    type: "FixedTime",
    duration,
  };
}

export function flattenTimeInterval(
  timeIntervals: TimeEstimateInterval[]
): TimeEstimateInterval {
  const fromDuration = timeIntervals
    .flatMap((a) => a.fromDuration)
    .reduce(reduceTimeTotal, { length: 0, variant: "minutes" });
  const toDuration = timeIntervals
    .flatMap((a) => a.toDuration)
    .reduce(reduceTimeTotal, { length: 0, variant: "minutes" });

  return {
    type: "IntervalTime",
    fromDuration,
    toDuration,
  };
}

function reduceTimeTotal(total: Time, time: Time): Time {
  switch (time.variant) {
    case "minutes":
      return { ...total, length: total.length + time.length };
    // case "hours":
    //     return { ...total, length: total.length + (time.length * 60) };
    // case "days":
    //     return { ...total, length: total.length + (time.length * 60 * 24) };
  }
  return total;
}
