import { FC, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../style/statics";
import { BodyText, Headline3, SubHeadline4 } from "../../style/Text";
import { media } from "../../style/media";
import { useCart } from "../../Cart";
import {
  estimatedPriceWithDeductionsAndRebates,
  extractHourlyRateFromBundle,
  summarizeFromAndToPrice,
  summarizePriceEstimates,
} from "estimates";
import TagManager from "react-gtm-module";
import { useCheckout } from "../Checkout/context";

export const ConfirmationPage: FC = () => {
  const { checkoutConfirmation } = useCheckout();
  const { enhancedCartItemsInCart, useDeduction, isClasMedlem } = useCart();

  useEffect(() => {
    window.scrollTo(0, 0);

    let totalpriceWithoutDeductionAndClubClas = 0;
    if (typeof window !== "undefined" && enhancedCartItemsInCart.length > 0) {
      const allPrices = enhancedCartItemsInCart.flatMap((s) => {
        const estimates = s.questionAnswers.flatMap((a) => {
          return a.meta.extractEstimatesFromPreviewData(a.state, a.definition);
        });
        const result = summarizePriceEstimates([
          ...estimates,
          s.serviceBundle?.estimate!,
        ]);

        const subTotalPriceWithoutDeduction =
          estimatedPriceWithDeductionsAndRebates({
            ...result,
            deduction: "None",
            isClasMedlem: false,
            hourlyRate: extractHourlyRateFromBundle(s.serviceBundle),
          });

        const subTotal = estimatedPriceWithDeductionsAndRebates({
          ...result,
          deduction: useDeduction ? s.serviceBundle?.deductable! : "None",
          isClasMedlem,
          hourlyRate: extractHourlyRateFromBundle(s.serviceBundle),
        });

        const bundlePrice = result?.price?.amount
          ? result?.price?.amount
          : subTotalPriceWithoutDeduction.fromPrice;
        totalpriceWithoutDeductionAndClubClas =
          totalpriceWithoutDeductionAndClubClas + bundlePrice;

        return subTotal;
      });
      const totalPrice = summarizeFromAndToPrice(allPrices);

      const tagManagerArgs = {
        dataLayer: {
          event: "funnel_boka.clasfixare.se_purchase",
          tasks: enhancedCartItemsInCart,
          totalPrice,
          useDeduction,
          isClasMedlem,
          totalpriceWithoutDeductionAndClubClas,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  });

  if (!checkoutConfirmation) {
    return <Headline3>Ingen aktiv bokningsbekräftelse</Headline3>;
  }

  return (
    <ConfirmationBox>
      <ConfHeadline>Förfrågan skickad!</ConfHeadline>
      <ConformationContent>
        <Text>
          <ConfDone>Din bokning är nu genomförd!</ConfDone>
          <BodyText>
            Du kommer att få ett SMS när din bokning har bekräftats av någon av
            våra fixare. Fixaren kommer sedan att höra av sig till dig för att
            boka in tid för din bokning.
          </BodyText>
        </Text>
        <Circles>
          <Circle />
          <Circle />
          <HalfCircle />
        </Circles>
      </ConformationContent>
    </ConfirmationBox>
  );
};

const ConfirmationBox = styled.div`
  background-color: ${colors.secondary.blue[2]};
  padding: 1.313rem 1.313rem 1.313rem 1.313rem;
  border-radius: 0.125rem;
`;

const ConfHeadline = styled(SubHeadline4)`
  margin-bottom: 1rem;
  margin-top: 0;
`;

const ConfDone = styled(SubHeadline4)`
  margin-bottom: 1rem;
  margin-top: 0;
`;

const ConformationContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  border-radius: 0.125rem;
`;

const Text = styled.div`
  padding: 2.188rem;
`;

const Circles = styled.div`
  flex-direction: row;
  display: none;

  ${media.desktop} {
    display: flex;
  }
`;

const Circle = styled.div`
  height: 20.5rem;
  width: 20.5rem;
  border-radius: 50%;
  background-color: ${colors.grey[3]};
`;

const HalfCircle = styled.div`
  height: 20.5rem;
  width: 10.25rem;
  background-color: ${colors.illustration.green};
  border-top-left-radius: 20.5rem;
  border-bottom-left-radius: 20.5rem;
  visibility: hidden;

  ${media.desktop} {
    visibility: visible;
  }
`;
