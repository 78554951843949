import { useCart } from "../../Cart/useCart";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";

export const Cart = () => {
  const { itemsInCart } = useCart();
  return (
    <Link to="/varukorg">
      <button
        style={{
          border: 0,
          color: "white",
          backgroundColor: "black",
        }}
      >
        <MdOutlineShoppingCart size={26} />{" "}
        {itemsInCart.length > 0 && `(${itemsInCart.length})`}
      </button>
    </Link>
  );
};
