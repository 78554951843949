import { FC } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../style/statics";
import { useToggleButtonContext } from "./AccordionToggle";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
} from "react-icons/md";
import classNames from "classnames";

export const TextButton: FC<{ label?: string; disabled?: boolean }> = ({
  label,
  disabled,
}) => {
  const { toggle, active } = useToggleButtonContext();
  return (
    <Btn
      className={classNames({ active, disabled })}
      type="button"
      disabled={disabled}
      onClick={toggle}
    >
      <b>{label || "Välj"}</b>
    </Btn>
  );
};

const Btn = styled.button`
  background-color: ${colors.font.black};
  color: ${colors.font.white};
  width: 5.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  border: 0;

  &.disabled {
    background-color: ${colors.grey[2]};
  }
`;

export const ArrowButton: FC = () => {
  const { toggle, active } = useToggleButtonContext();

  return (
    <StyledArrowButton>
      <Button onClick={toggle}>
        {active ? (
          <MdKeyboardArrowDown color="black" size={20} />
        ) : (
          <MdKeyboardArrowRight color="black" size={20} />
        )}
      </Button>
    </StyledArrowButton>
  );
};

const StyledArrowButton = styled.div`
  & button {
    background-color: ${colors.grey[3]};
    border: 0;
  }
  & button:focus {
    background-color: ${colors.secondary.green[0]};
    border: 0;
    box-shadow: none;
  }
  & button:hover {
    background-color: ${colors.secondary.green[0]};
    border: 0;
    box-shadow: none;
  }
`;
