import styled from "styled-components";
import { Checkbox } from "../../Global/components/Checkbox";
import { SubHeadline5 } from "../../style/Text";
import { useCheckout } from "../Checkout/context";

export function DesireUrgency() {
  const {
    dispatch,
    formState: { desireUrgency },
  } = useCheckout();
  return (
    <DateContainer>
      <WrapHeadlineFlex>
        <div>
          <SubHeadline5 style={{ marginBottom: "1rem" }}>
            Önskar du istället hjälp så snabbt som möjligt?
          </SubHeadline5>
          <p>
            Väljer du detta så bokar vi första lediga tid men sätter även upp
            dig på vår reservlista vilket innebär att vi har möjlighet att komma
            till er om vi exempelvis får in en avbokning och är i området. Vi
            ringer alltid och kollar om det funkar
          </p>
        </div>
        <div style={{ margin: "0 1rem" }}>
          <Checkbox
            checked={desireUrgency}
            onChange={(desireUrgency) => {
              dispatch({
                type: "set_form_value",
                value: { desireUrgency },
              });
            }}
          />
        </div>
      </WrapHeadlineFlex>
    </DateContainer>
  );
}

const DateContainer = styled.div`
  padding: 1rem 0;
`;
const WrapHeadlineFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
