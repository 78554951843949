import styled from "styled-components";
import { colors } from "../../style/statics";
import { SubHeadline4 } from "../../style/Text";
import { media } from "../../style/media";
import { BlackBtn, StyledSpinner } from "../../style/Buttons";
import { useCheckout } from "../Checkout/context";
import { PersonalDetailsSummary } from "./PersonalDetailsSummary";
import { OfferSummary } from "./OfferSummary";
import { useEffect } from "react";
import { SimpleStepper } from "../Stepper";
import { useStepper } from "../Stepper/useStepper";

export const CheckoutSummaryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SimpleStepper />
      <Wrapper>
        <StepHeader>
          <SubHeadline4>Sammanställning</SubHeadline4>
        </StepHeader>
        <Boxes>
          <Container>
            <OfferSummary />
          </Container>
          <Container>
            <PersonalDetailsSummary />
          </Container>
        </Boxes>
      </Wrapper>
      <BtnContainer>
        <CheckoutButton />
      </BtnContainer>
    </>
  );
};
const CheckoutButton = () => {
  const {
    submitCheckout,
    loading,
    formState: { personal },
  } = useCheckout();
  const { move } = useStepper();

  if (loading) return <SpinnerButton />;
  if (!personal) {
    return (
      <BlackBtn
        onClick={() => {
          move(0);
        }}
      >
        Gå till början av utcheckning
      </BlackBtn>
    );
  }

  return <BlackBtn onClick={submitCheckout}>Skicka förfrågan</BlackBtn>;
};

const SpinnerButton = () => {
  return (
    <BlackBtn disabled={true}>
      <div>
        <StyledSpinner className="spinner-border" role="status"></StyledSpinner>
        <span>Laddar</span>
      </div>
    </BlackBtn>
  );
};
const Wrapper = styled.div`
  background-color: ${colors.secondary.blue[2]};
  padding: 1.3125rem;
  width: 100%;
`;

const StepHeader = styled.div`
  margin-bottom: 2.625rem;
`;

const Container = styled.div`
  padding: 1.3125rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;

  ${media.tablet} {
    max-width: 50%;
  }
`;

const Boxes = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  ${media.tablet} {
    flex-direction: row;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  ${media.tablet} {
    justify-content: flex-end;
    margin-bottom: 0;
  }
`;
