import {
  CheckoutQuestionDefinition,
  CheckoutQuestionPreviewConfiguration,
} from "checkoutquestions";
import { Action } from "utils";

export function reducer(
  state: PreviewItemsState,
  action: Actions
): PreviewItemsState {
  switch (action.type) {
    case "reset": {
      const items = action.questions.map((s) => {
        return { ...s };
      });
      return { items };
    }
    case "StateUpdated": {
      const items = state.items.map((s) => {
        if (
          s.checkoutQuestionDefinitionId === action.checkoutQuestionDefinitionId
        ) {
          return { ...s, state: action.previewState };
        }
        return s;
      });
      return { ...state, items };
    }
    default: {
      return state;
    }
  }
}
interface Reset extends Action {
  type: "reset";
  questions: CheckoutAnswerPreviewStateItem[];
}
interface QuestionStateUpdated extends Action {
  type: "StateUpdated";
  checkoutQuestionDefinitionId: string;
  previewState: any;
}
export type Actions = Reset | QuestionStateUpdated;

interface PreviewItemsState {
  items: CheckoutAnswerPreviewStateItem[];
}

export interface CheckoutAnswerPreviewStateItem {
  checkoutQuestionDefinitionId: string;
  state: any;
  definition: CheckoutQuestionDefinition;
  meta: CheckoutQuestionPreviewConfiguration;
}
