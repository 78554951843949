import { BodyText, Headline1 } from "../style/Text";
import styled from "styled-components";
import { media } from "../style/media";
import { useCategory } from "./Global/useCategory";

export function CategoryDescription() {
  const category = useCategory();
  if (!category) return null;
  return (
    <div>
      <Headline1>{category.name}</Headline1>

      {category.imgUrl && (
        <MainImgDesk src={category.imgUrl} alt="Småfix och montering" />
      )}

      <BodyTextUnder
        dangerouslySetInnerHTML={{
          __html:
            category && category.longDescription
              ? category.longDescription
              : "",
        }}
      ></BodyTextUnder>
    </div>
  );
}

const MainImgDesk = styled.img`
  max-width: 100%;
  display: none;

  ${media.desktop} {
    display: block;
    margin-top: 1rem;
  }
`;

const BodyTextUnder = styled(BodyText)`
  margin-top: 2em;
`;
