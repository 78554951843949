import styled from "styled-components";
import { useCart } from "../../Cart";
import {
  checkBundleForQuoteRequired,
  enhancedItemsContainsQuoteRequired,
  extractHourlyRateFromBundle,
  extractStartFeeFromBundleList,
  getAllEstimatesForEnhancedCartItems,
  getPriceForEstimatesList,
  getQtyFromAnswer,
  getTotalPriceForEnhancedCartItem,
  meetsMinimumRequirement,
} from "estimates";
import { media } from "../../style/media";
import { colors } from "../../style/statics";
import {
  SmallTextReg,
  SubHeadline5,
  SubHeadline6,
  SummaryTitlePrice,
} from "../../style/Text";
import { CategoryDicItem } from "servicebundlecore";
import { getCategoriesFromCartItems, QUOTE_REQUIRED_TEXT } from "utils";

export function OfferSummary() {
  const { enhancedCartItemsInCart, useDeduction, isClasMedlem } = useCart();
  const totalPrice = getAllEstimatesForEnhancedCartItems(
    enhancedCartItemsInCart,
    useDeduction,
    isClasMedlem
  );
  const startPriceSum = extractStartFeeFromBundleList(enhancedCartItemsInCart);

  //adding start price to total price
  totalPrice.fromPrice += startPriceSum;
  totalPrice.toPrice += startPriceSum;

  const showCount = (count) => {
    if (count > 1) {
      return `(${count})`;
    }
    return "";
  };
  const totalPriceMinimum = meetsMinimumRequirement(
    totalPrice,
    useDeduction,
    isClasMedlem
  );

  let totalPriceText = totalPriceMinimum.priceText;
  const containsQuoteRequired = enhancedItemsContainsQuoteRequired(
    enhancedCartItemsInCart
  );
  if (containsQuoteRequired) {
    totalPriceText = "Offert krävs";
  }
  const { categories, subCategories } = getCategoriesFromCartItems(
    enhancedCartItemsInCart
  );
  const categoryList: CategoryDicItem[] = Object.values(categories);

  return (
    <>
      <SubHeadline5>Offert</SubHeadline5>

      {categoryList.map((cat: CategoryDicItem) => {
        const subCats = cat.childIds;
        const categoryId = cat.category.id;

        const filteredCartItems = enhancedCartItemsInCart.filter((item) => {
          const productCatId = item?.serviceBundle?.category?.id;
          const productParentCatId =
            item?.serviceBundle?.category?.parentCategory?.id;
          return (
            productCatId &&
            productParentCatId &&
            subCats.includes(productCatId) &&
            categoryId === productParentCatId
          );
        });

        const startPriceSubTotal =
          extractStartFeeFromBundleList(filteredCartItems);

        let startFeeText = <></>;
        if (startPriceSubTotal) {
          startFeeText = (
            <StartPriceInfoRowAddon>
              <DetailText>Startpris</DetailText>
              <DetailText>
                <PriceText>{`${startPriceSubTotal} kr`}</PriceText>
              </DetailText>
            </StartPriceInfoRowAddon>
          );
        }

        return (
          <>
            <CategoryInfoRowHeader>
              <TitlePrice>{cat.category.name}</TitlePrice>
            </CategoryInfoRowHeader>
            {startFeeText}

            {subCats.map((subCatId) => {
              const subCat = subCategories[subCatId];
              const filteredCartItems = enhancedCartItemsInCart.filter(
                (item) => {
                  return (
                    subCatId === item?.serviceBundle?.category?.id &&
                    categoryId ===
                      item?.serviceBundle?.category?.parentCategory?.id
                  );
                }
              );

              return (
                <>
                  <SubCategoryInfoRowHeader>
                    <TitlePrice>{subCat.name}</TitlePrice>
                  </SubCategoryInfoRowHeader>

                  {filteredCartItems.map((service, i) => {
                    const hourlyRate = extractHourlyRateFromBundle(
                      service.serviceBundle
                    );
                    const totalPrice = getTotalPriceForEnhancedCartItem(
                      service,
                      useDeduction,
                      isClasMedlem,
                      hourlyRate
                    );
                    const isQuoteRequired =
                      checkBundleForQuoteRequired(service);

                    return (
                      <Service key={i}>
                        <ServiceInfoRowHeader>
                          <TitlePrice>
                            {service.serviceBundle?.cms.title}
                          </TitlePrice>
                          <SummaryTitlePrice>
                            {isQuoteRequired ? QUOTE_REQUIRED_TEXT : totalPrice}
                          </SummaryTitlePrice>
                        </ServiceInfoRowHeader>
                        {service.questionAnswers.map((a, key) => {
                          const { definition, state } = a;
                          const estimates =
                            a.meta.extractEstimatesFromPreviewData(
                              state,
                              definition
                            );

                          let priceText = getPriceForEstimatesList(
                            estimates,
                            service.serviceBundle?.deductable!,
                            useDeduction,
                            isClasMedlem,
                            hourlyRate
                          );

                          if (!priceText) {
                            priceText = "0 kr";
                          }
                          if (isQuoteRequired) {
                            priceText = "";
                          }

                          const count = getQtyFromAnswer(a);

                          return (
                            <ServiceInfoRowAddon key={key}>
                              <DetailText>
                                {definition.questionText} {showCount(count)}
                              </DetailText>
                              <DetailText>
                                <PriceText>{priceText}</PriceText>
                              </DetailText>
                            </ServiceInfoRowAddon>
                          );
                        })}
                        <HorizontalDividerLine />
                      </Service>
                    );
                  })}
                </>
              );
            })}
          </>
        );
      })}
      {!totalPriceMinimum.meetsMinimum && !containsQuoteRequired && (
        <TotalPrice>
          <SubHeadline6>Pålagt pris till lägsta debitering:</SubHeadline6>
          <SubHeadline6>{totalPriceMinimum.padPriceText}</SubHeadline6>
        </TotalPrice>
      )}
      <TotalPrice>
        <SubHeadline5>Estimerat Totalpris:</SubHeadline5>
        <SubHeadline5>{totalPriceText}</SubHeadline5>
      </TotalPrice>
      {useDeduction && (
        <TotalPrice>
          <SummaryTitlePrice>RUT/ROT avdrag</SummaryTitlePrice>
          <SummaryTitlePrice>Ja</SummaryTitlePrice>
        </TotalPrice>
      )}
      {isClasMedlem && (
        <TotalPrice>
          <SummaryTitlePrice>Club Clas medlem</SummaryTitlePrice>
          <SummaryTitlePrice>Ja</SummaryTitlePrice>
        </TotalPrice>
      )}
    </>
  );
}

const Service = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryInfoRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.3125rem;s
  padding-left:0;
  font-weight:bold;
`;

const SubCategoryInfoRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8rem;
  padding-left: 0;
`;

const ServiceInfoRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8rem;
  padding-left: 0;

  ${media.mobile} {
    padding-left: 1.3125rem;
  }
`;

const ServiceInfoRowAddon = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  justify-content: space-between;

  ${media.mobile} {
    flex-direction: row;
    margin-left: 1.3125rem;
    margin-top: 0.625rem;
    padding: 0;
  }

  &:last-child {
    text-align: right;
    background: red;
  }
`;

const StartPriceInfoRowAddon = styled(ServiceInfoRowAddon)`
  ${media.mobile} {
    margin-left: 0;
  }
`;

const HorizontalDividerLine = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.secondary.blue[1]};
  margin: 1rem 0 0 0;

  ${media.mobile} {
    width: calc(100% - 1.3125rem);
    margin: 1rem 1.3125rem 0 1.3125rem;
  }
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
`;

const TitlePrice = styled(SummaryTitlePrice)`
  margin: 0;
  width: 50%;
`;

const DetailText = styled(SmallTextReg)`
  font-size: 0.8rem;
  margin: 0;
  color: #5f646a;
`;
const PriceText = styled(SmallTextReg)`
  text-align: right;
`;
