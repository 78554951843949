import { createContext, FC, useContext, useEffect, useState } from "react";
import { Cart } from "booking-backend/src/Cart/reducer";
import { Action } from "utils";
import { useServiceBundles } from "../../MainPage/Global/useServiceBundles";
import { QuestionTypesMetaConfigurationExtensions } from "questions";
import {
  AnswerPreviewStateItem,
  CheckoutAnswerPreviewStateItem,
} from "../../MainPage/serviceBundle/reducer";
import { CartContextProps, EnhancedCartItem, ItemInCart } from "./interfaces";
import fetch from "../../utils/enhancedFetch";
import { CheckoutQuestionTypesMetaConfigurationExtensions } from "checkoutquestions";
import { ServiceBundleSubstitute } from "../../MainPage/serviceBundle/useServiceBundlePreview";

export const CartContext: FC = ({ children }) => {
  const [itemsInCart, setItemsInCart] = useState<ItemInCart[]>([]);
  const [isClasMedlem, setIsClasMedlem] = useState<boolean>(true);
  const [useDeduction, setUseDeduction] = useState<boolean>(true);
  const [postalCode, setPostalCode] = useState<string>("");

  useEffect(() => {
    fetchData();
  }, []);

  function updateCartData(json: Cart) {
    const itemsInCart = json.servicesInCart.map(
      ({ serviceBundleId, id, answers, checkoutAnswers }) => {
        return {
          serviceBundleId,
          id,
          answers,
          checkoutAnswers: checkoutAnswers ?? [],
        } as ItemInCart;
      }
    );
    setItemsInCart(itemsInCart);
    setIsClasMedlem(json.isClasMedlem);
    setUseDeduction(json.useDeduction);
    setPostalCode(json.postalCode);
  }
  async function fetchData() {
    const result = await fetch("/api/cart", {
      method: "get",
      headers: { Accept: "application/json" },
    });
    updateCartData(await result.json());
  }

  async function dispatch(action: Action) {
    const result = await fetch("/api/cart", {
      method: "post",
      body: JSON.stringify(action),
      headers: { "Content-Type": "application/json" },
    });
    updateCartData(await result.json());
  }

  return (
    <ServiceBundlesContextProviderInner
      {...{ dispatch, isClasMedlem, useDeduction, itemsInCart, postalCode }}
    >
      {children}
    </ServiceBundlesContextProviderInner>
  );
};
interface ServiceBundlesContextProviderProps {
  postalCode: string;
  itemsInCart: ItemInCart[];
  isClasMedlem: boolean;
  useDeduction: boolean;
  dispatch: (action: Action) => void;
}
const ServiceBundlesContextProviderInner: FC<
  ServiceBundlesContextProviderProps
> = ({
  itemsInCart,
  isClasMedlem,
  useDeduction,
  postalCode,
  dispatch,
  children,
}) => {
  const { serviceBundles } = useServiceBundles();
  const [currentEditingId, setCurrentEditingId] = useState<string>("");
  const itemDefinitions = itemsInCart
    .map(({ serviceBundleId, answers, checkoutAnswers, id }) => {
      if (serviceBundles.length === 0) return undefined;
      const sb = serviceBundles.find((a) => a.id === serviceBundleId) as
        | ServiceBundleSubstitute
        | undefined;

      const questionAnswers = answers.map(
        ({ questionDefinitionId, answerState }) => {
          const definition = sb?.questionDefinitions.find(
            (a) => a.questionDefinitionId === questionDefinitionId
          )!;
          if (definition === undefined) return undefined;

          const { questionType } = definition;
          const meta = QuestionTypesMetaConfigurationExtensions.find(
            (a) => a.typeId === questionType
          )!;
          return {
            isValidToSubmit: meta.isValidToSubmit(
              answerState,
              definition.definition
            ),
            definition: definition.definition,
            state: answerState,
            questionDefinitionId: definition.questionDefinitionId,
            meta,
            estimates: [],
          } as AnswerPreviewStateItem;
        }
      );
      const checkoutQuestionAnswers = checkoutAnswers.map(
        ({ checkoutQuestionDefinitionId, answerState }) => {
          const definition = sb?.checkoutQuestionDefinitions.find(
            (a) => a.questionDefinitionId === checkoutQuestionDefinitionId
          )!;
          if (definition === undefined) return undefined;

          const { questionType } = definition;
          const meta = CheckoutQuestionTypesMetaConfigurationExtensions.find(
            (a) => a.typeId === questionType
          )!;
          return {
            definition: definition.definition,
            state: answerState,
            checkoutQuestionDefinitionId: definition.questionDefinitionId,
            meta,
          } as CheckoutAnswerPreviewStateItem;
        }
      );
      return {
        serviceBundle: sb,
        questionAnswers: questionAnswers
          .filter((a) => a !== undefined)
          .map((a) => a!),
        checkoutQuestionAnswers: checkoutQuestionAnswers,
        serviceInCartId: id,
        isEditing: currentEditingId === id,
      } as EnhancedCartItem;
    })
    .filter((a) => a !== undefined)
    .map((a) => a!);

  return (
    <context.Provider
      value={{
        editingItemId: currentEditingId,
        isClasMedlem,
        useDeduction,
        postalCode,
        startEditItem: (id) => {
          if (currentEditingId === id || !id) setCurrentEditingId("");
          else setCurrentEditingId(id);
        },
        enhancedCartItemsInCart: itemDefinitions,
        itemsInCart,
        dispatch,
      }}
    >
      {children}
    </context.Provider>
  );
};

const context = createContext<CartContextProps>(undefined as any);
export function useCart() {
  return useContext(context);
}
