import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Area, Category, MainCategory } from "servicebundlecore";
import { useCart } from "../../Cart";
import fetch from "../../utils/enhancedFetch";

export function useCategories() {
  return useContext(context);
}

export const useParentCategories = (): MainCategory[] => {
  const categories = useCategories();
  return categories.categories
    .filter((x) => !x.parentCatgoryId)
    .map((x) => ({
      ...x,
      subCategories: categories.categories.filter(
        (y) => y.parentCatgoryId === x.id
      ),
    }));
};

export const CategoriesListContext: FC = ({ children }) => {
  const { postalCode } = useCart();
  const [categories, setCategories] = useState<Category[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [enhancedCategories, setEnhancedCategories] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState("");

  const setSearchTextFunc = (searchString: string) => {
    setSearchText(searchString);
  };

  const isAvailable = useCallback(
    (c: Category): boolean => {
      if (!postalCode) return true;
      if (!c.availableAtAreas || c.availableAtAreas.length > 0) return true;

      return false;
    },
    [postalCode, areas]
  );

  useEffect(() => {
    if (areas.length < 1) return;

    const availableCategories = categories.map((c) => {
      if (c.availableAtAreaIds) {
        c.availableAtAreas = [];
        for (const areaId of c.availableAtAreaIds) {
          const areasFound: Area | undefined = areas.find((a: Area) => {
            return a.id === areaId && a.isAvailable;
          });
          if (areasFound) {
            c.availableAtAreas.push(areasFound);
          }
        }
      } else {
        c.availableAtAreas = areas;
      }

      return { ...c, unavailableAtYourArea: !isAvailable(c) };
    });

    setEnhancedCategories(availableCategories);
  }, [postalCode, categories, isAvailable, areas]);

  useEffect(() => {
    fetch(`/api/categories`, { method: "post" }).then(async (resp) => {
      const res: Category[] = await resp.json();
      setCategories(res);
    });

    const postData = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postalCode: postalCode }),
    };

    fetch(`/api/allAreas`, postData).then(async (resp) => {
      const res: Area[] = await resp.json();
      setAreas(res);
    });
  }, [postalCode]);

  return (
    <context.Provider
      value={{
        categories: enhancedCategories,
        searchText: searchText,
        setSearchTextFunc: setSearchTextFunc,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const context = createContext<CategoriesListContextProps>({
  categories: [],
  searchText: "",
  setSearchTextFunc: () => {},
});

interface CategoriesListContextProps {
  categories: Category[];
  searchText: string;
  setSearchTextFunc: (string) => void;
}

export function useCategory() {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { categories } = useCategories();
  return categories.find((a) => a.seoName === categoryId);
}

export const useSubCategory = () => {
  const { subCategoryId } = useParams<{ subCategoryId: string }>();
  const { categories } = useCategories();
  return categories.find((a) => a.seoName === subCategoryId);
};
