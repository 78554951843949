export interface SalesforceAttributes {
  type: string;
  url: string;
}

export interface SalesForceParentCategory {
  CF_ProductCategoryCode__c: string;
}

export interface SalesforceCategory {
  attributes?: SalesforceAttributes;
  CF_ProductCategoryCode__c: string;
  Name: string;
  Id?: string;
  CF_ParentProductCategory__r?: SalesForceParentCategory;
  IsDeleted?: boolean;
}

export interface SalesforceProduct {
  attributes?: SalesforceAttributes;
  Name: string;
  Id?: string;
  CF_ProductCategoryCode__c?: string;
  CF_ProductCode__c: string;
}

export interface SalesforceProductForInsert {
  Name: string;
  ProductCode: string;
  ProductCategoryCode?: string;
  Price: number;
  Deductable?: string;
  IsActive: boolean;
  Id?: string;
  IsStartPriceProduct?: boolean;
  HouseWorkType?: string;
}

export enum SalesforceUnitOfMeasure {
  "Fixed" = "Fixed",
  "Hourly" = "Hourly",
}

export enum SalesforceUnitWoliType {
  "Supervisor" = "Supervisor",
}

export enum SalesforceImageExtensions {
  "jpg" = "jpg",
  "png" = "png",
}

export interface SalesforceWorkOrderImage {
  Title: string;
  FileExtension?: string;
  VersionData?: string;
  Url: string;
}

export enum SalesforceRequestedStartTime {
  None = "none",
  FlexibleMorning = "flexible-morning",
  FlexibleEvening = "flexible-evening",
  FlexibleWholeDay = "flexible-wholeday",
  NotFlexible = "not-flexible",
};

export interface SalesforceWorkOrderItem {
  ProductCode: string;
  StartDate: string;
  StartTime: SalesforceRequestedStartTime;
  UnitOfMeasure: SalesforceUnitOfMeasure;
  Price: string;
  Description: string;
  Type: SalesforceUnitWoliType;
  Images?: SalesforceWorkOrderImage[];
  DurationEstimated?: string;
  DesireUrgency: boolean;
}

export interface SalesforceAccount {
  LastName: string;
  FirstName: string;
  PersonMobilePhone: string;
  PersonEmail: string;
  CF_PIN__pc: string;
  CF_HasOptedOutMarketing__c: boolean;
}

export interface SalesforceProperty {
  CF_StreetAddress__c: string;
  CF_PostalCode__c: string;
  CF_City__c: string;
  CF_BRFOrgNumber__c?: string;
  CF_PropertyCode__c?: string;
}

export interface SalesforceBooking {
  CF_Description__c: string|"";
};

export interface SalesforceOpportunity {
  CloseDate: string;
  WorkDetails: string;
  ProductCategoryId: string[];
  Images?: SalesforceWorkOrderImage[];
  Amount: number;
  VisitorID: string|"";
  GCLID: string|"";
}

// FIXME: Unfortunate name.
export interface SalesforceWo {
  StartDate?: string;
  StartTime: SalesforceRequestedStartTime;
  DesireUrgency: boolean;
  CF_VisitorID__c: string|"";
  GCLID__c: string|"";
};

export interface SalesforceBaseOrder {
  account: SalesforceAccount;
  property: SalesforceProperty;
}

export interface SalesforceWorkOrder extends SalesforceBaseOrder {
  wo: SalesforceWo;
  woli: SalesforceWorkOrderItem[];
};

export interface SalesforceOfferOrder extends SalesforceBaseOrder {
  opportunity: SalesforceOpportunity;
  booking: SalesforceBooking;
}

export interface SalesforceOrderResult {
  Id?: string;
  error?: string;
}
