import { createContext, FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ServiceBundle } from "servicebundlecore";
import { ServiceBundleSubstitute } from "../serviceBundle/useServiceBundlePreview";
import fetch from "../../utils/enhancedFetch";

export const AllServiceBundlesContext: FC = ({ children }) => {
  const [serviceBundles, setServiceBundles] = useState<
    ServiceBundleSubstitute[]
  >([]);
  useEffect(() => {
    fetch("/api/allServiceBundles", { method: "post" }).then(async (res) => {
      const obj: ServiceBundle[] = await res.json();

      const resu = obj.map((pr) => ({ ...pr } as ServiceBundleSubstitute));
      setServiceBundles(resu);
    });
  }, []);
  return (
    <context.Provider value={{ serviceBundles }}>{children}</context.Provider>
  );
};
const context = createContext<AllServiceBundlesContextProps>({
  serviceBundles: [],
});
interface AllServiceBundlesContextProps {
  serviceBundles: ServiceBundleSubstitute[];
}
export function useServiceBundles() {
  return useContext(context);
}
export function useCurrentServiceBundle() {
  const { serviceBundleId } = useParams<{ serviceBundleId: string }>();
  const { serviceBundles } = useServiceBundles();

  return serviceBundles.find((a) => a.cms.seoName === serviceBundleId);
}
