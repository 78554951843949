import { FC, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { StyledAccordion } from "./Accordion";
import { ArrowButton } from "./Accordion/Buttons";
import {
  useCategories,
  useCategory,
  useSubCategory,
} from "./Global/useCategory";
import { ServiceBundleList } from "./serviceBundle/List";
import { CategoryDescription } from "./CategoryDescription";
import { MainTextBlock } from "./MainTextBlock";
import { useServiceBundles } from "./Global/useServiceBundles";
import styled from "styled-components";
import { BodyText, Headline3 } from "../style/Text";
import { useStateWithDeps } from "../utils";
import { media } from "../style/media";
import { DeductionChecker } from "./ClasMedlemChecker";
import { SubCategoryDescription } from "./SubCategoryDescription";
import { CategoriesAccordion } from "./CategoriesAccordion";
import { useCart } from "../Cart";
import { ServiceBundleSearch } from "./ServiceBundleSearch";
import { ServiceBundleSubstitute } from "./serviceBundle/useServiceBundlePreview";

export function MainPage() {
  return (
    <Row>
      <Col>
        <LeftCol>
          <Presentation />
          <ServiceBundleDetails />
        </LeftCol>
      </Col>
      <Col>
        <Deduction>
          <DeductionChecker />
        </Deduction>
        <ServiceBundleSearch />
        <CategoriesAccordion>
          <SubCategoriesAccordion>
            <ServiceBundleList />
          </SubCategoriesAccordion>
        </CategoriesAccordion>
      </Col>
    </Row>
  );
}

const Presentation: FC = () => {
  const category = useCategory();
  const subCategory = useSubCategory();
  if (subCategory) return <SubCategoryDescription />;
  if (category) return <CategoryDescription />;
  return <MainTextBlock />;
};

const ServiceBundleDetails = () => {
  const { serviceBundles } = useServiceBundles();
  const { serviceBundleId } = useParams<{ serviceBundleId: string }>();
  const [current] = useStateWithDeps<ServiceBundleSubstitute | undefined>(
    () => serviceBundles.find((x) => x.cms.seoName === serviceBundleId),
    [serviceBundles, serviceBundleId]
  );
  if (!current) return null;

  return (
    <BundleDetailsText>
      <Headline3>{current.cms.title}</Headline3>
      <BodyText dangerouslySetInnerHTML={{ __html: current.cms.readMore }} />
    </BundleDetailsText>
  );
};

const SubCategoriesAccordion: FC = ({ children }) => {
  const category = useCategory();
  const subcategory = useSubCategory();
  const navigate = useNavigate();
  const { categories } = useCategories();
  const { postalCode } = useCart();
  const filteredCategories = useMemo(() => {
    if (!category) return categories;
    return categories.filter((x) => x.parentCatgoryId === category.id);
  }, [category, categories]);

  if (!category) return null;
  return (
    <StyledAccordion
      whitespace
      toggleButton={<ArrowButton />}
      onToggle={(c) =>
        navigate(
          `/${category.seoName}/${subcategory?.seoName === c.id ? "" : c.id}`
        )
      }
      items={filteredCategories.map((x) => ({
        ...x,
        id: x.seoName!,
        title: x.name,
        image: x.iconUrl,
        disabled: x.unavailableAtYourArea,
        subtitle: !postalCode
          ? "Ange postnummer för att se vilka tjänster som är tillgängliga"
          : undefined,
      }))}
      activeKey={subcategory?.seoName}
    >
      {children}
    </StyledAccordion>
  );
};

const BundleDetailsText = styled.div`
  display: none;

  ${media.tablet} {
    display: block;
  }
`;

const LeftCol = styled.div`
  ${media.tablet} {
    position: sticky;
    position: -webkit-sticky;
    top: 7rem;
    max-width: 90%;
  }
`;

const Deduction = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    flex-direction: row;
  }
`;
