import { useForm, UseFormRegister } from "react-hook-form";
import styled, { css } from "styled-components";
import { useEffect, useState, useRef } from "react";
import { BodyText, SubHeadline4, SubHeadline5, SubHeadline6 } from "../../style/Text";
import { colors } from "../../style/statics";
import { BlackBtn } from "../../style/Buttons";
import { media } from "../../style/media";
import { useCheckout } from "../Checkout/context";
import { useStepper } from "../Stepper/useStepper";
import { SimpleStepper } from "../Stepper";
import { Consent } from "./Consent";
import { useCart } from "../../Cart";
import { useDebounce } from "../../utils/useDebouncer";
import { Checkbox } from "../../Global/components/Checkbox";

const phoneRegexp = /^([0-9][.,\/#!$%\^&\*;:{}=\-_`~()\s]?){3,12}$/;
const ssnRegexp =
  /^((?:19|20)?\d{2})((?:0[1-9])|(?:1[012]))(0[1-9]|(?:[12]\d)|(?:3[01]))(-|\+|\s)?(\d{4})$/;

interface PhoneInputOption {
  code: string;
  flag: string;
  country: string;
  placeholder: string;
  pattern?: RegExp;
}

function parseSSN(ssn: string): [string, string, string, string, string] {
  let [, year, month, day, separator, lastFour] = ssn.match(ssnRegexp)!;
  if (year.length === 2) {
    if (separator === "+") {
      year = "19" + year;
    } else if (2000 + Number(year) <= new Date().getFullYear()) {
      year = "20" + year;
    } else {
      year = "19" + year;
    }
  }
  separator = Number(year) <= new Date().getFullYear() - 100 ? "+" : "-";
  return [year, month, day, separator, lastFour];
}

function cleanSSNForSalesForce(ssn: string): string {
  return parseSSN(ssn).join("");
}

const phoneOptions: PhoneInputOption[] = [
  { code: "+46", flag: "🇸🇪", country: "Sverige", placeholder: "70 123 45 67" },
  { code: "+358", flag: "🇫🇮", country: "Finland ", placeholder: "9 123 456" },
  { code: "+47", flag: "🇳🇴", country: "Norge", placeholder: "406 12 345" },
  { code: "+45", flag: "🇩🇰", country: "Danmark", placeholder: "32 12 34 56" },
  {
    code: "+44",
    flag: "🇬🇧",
    country: "Storbritannien",
    placeholder: "7400 123456",
  },
];

export const PersonalInformationPage = () => {
  const { dispatch, formState, getPersonalInfo, loading } = useCheckout();
  const { postalCode } = useCart();
  const { move } = useStepper();
  const { personal } = formState;
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const [decryptedPersonalInfo, setDecryptedPersonalInfo] = useState(false);
  const [personalInfo, setPersonalInfo] = useState("");
  const debouncedPersonalInfo = useDebounce(personalInfo, 500);

  for (const option of phoneOptions) {
    option.pattern ??= phoneRegexp;
  }
  const [phoneError, setPhoneError] = useState<string>("");
  const [phoneOpen, setPhoneOpen] = useState(false);
  const [phoneSelected, setPhoneSelected] = useState(phoneOptions[0]);
  const togglePhoneDropdown = () => setPhoneOpen(!phoneOpen);
  const phoneContainer = useRef<HTMLDivElement>(null);
  const onPhoneChange = (e) => {
    let phone = e.target.value;
    for (const option of phoneOptions) {
      if (phone.slice(0, option.code.length) === option.code) {
        e.target.value = phone.slice(option.code.length);
        setPhoneSelected(option);
      }
    }

    if (phoneSelected.pattern!.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Var vänlig ange ett giltigt telefonnummer");
    }
  };

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (
        phoneContainer.current &&
        event.target &&
        !phoneContainer.current.contains(event.target as Node)
      ) {
        setPhoneOpen(false);
      }
    });
  });

  useEffect(() => {
    if (debouncedPersonalInfo) {
      encryptSessionData(JSON.parse(debouncedPersonalInfo));
    }
  }, [debouncedPersonalInfo]);

  const decryptSessionData = async (cryptedString) => {
    await fetch("/api/decrypt", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: cryptedString,
    })
      .then((response) => response.json())
      .then((data) => {
        setDecryptedPersonalInfo(data);
      })
      .catch(() => {
        setDecryptedPersonalInfo(false);
      });
  };

  const encryptSessionData = async (data) => {
    await fetch("/api/encrypt", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        sessionStorage.setItem("personal_info", JSON.stringify(data));
      })
      .catch((error) => {
        sessionStorage.setItem("personal_info", JSON.stringify(false));
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const sessionPersonalInfo = sessionStorage.getItem("personal_info");
    if (sessionPersonalInfo) {
      decryptSessionData(sessionPersonalInfo);
    }
  }, []);

  useEffect(() => {
    if (decryptedPersonalInfo) {
      Object.keys(decryptedPersonalInfo).forEach((key) => {
        if (decryptedPersonalInfo[key]) {
          setValue(key as any, decryptedPersonalInfo[key]);
        }
      });
    }
  }, [decryptedPersonalInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!personal) return;
    setValue("livingForm", "");

    Object.keys(personal).forEach((key) => {
      setValue(key as any, personal[key]);
    });
  }, [personal, setValue]);

  const onSubmit = async (data) => {
    if (phoneError === "") {
      // https://46elks.com/kb/e164
      let cleanedPhone = data.phone.replaceAll(
        /[.,\/#!$%\^&\*;:{}=\-_`~()\s]/g,
        ""
      );
      if (cleanedPhone.startsWith("0")) {
        cleanedPhone = cleanedPhone.slice(1);
      }
      data.phone = `${phoneSelected.code}${cleanedPhone}`;
      data.ssn = cleanSSNForSalesForce(data.ssn);

      await encryptSessionData(data);
      dispatch({ type: "complete_personal_form", value: data });
      move("forward");
    }
  };

  const handleChange = () => {
    setPersonalInfo(JSON.stringify(getValues()));
  };

  return (
    <>
      <SimpleStepper />
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
        <Wrapper>
          <StepHeader>
            <SubHeadline4>Personuppgifter</SubHeadline4>
          </StepHeader>
          <FormSSN>
            <FormInput>
              <FormLabel>Personnummer</FormLabel>
              <Input
                {...register("ssn", {
                  required: "Ange personnummer",
                  pattern: {
                    value: ssnRegexp,
                    message: "Ange personnummer ÅÅÅÅMMDD-XXXX"
                  },
                })}
                placeholder="YYYYMMDD-XXXX"
              />
              <ErrorText>{errors.ssn?.message}</ErrorText>
            </FormInput>
          </FormSSN>

          <Form>
            <FormInput>
              <FormLabel>Förnamn</FormLabel>
              <Input {...register("firstName", { required: "Ange förnamn" })} />
              <ErrorText>{errors.firstName?.message}</ErrorText>
            </FormInput>

            <FormInput>
              <FormLabel>Efternamn</FormLabel>
              <Input {...register("surname", { required: "Ange efternamn" })} />
              <ErrorText>{errors.surname?.message}</ErrorText>
            </FormInput>
          </Form>

          <Form>
            <FormInput>
              <FormLabel>E-post</FormLabel>
              <Input
                type={"email"}
                {...register("email", {
                  required: "Ange mail",
                  pattern: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g,
                })}
              />
              <ErrorText>{errors.email?.message}</ErrorText>
            </FormInput>

            <FormInput>
              <FormLabel>Mobilnummer</FormLabel>
              <PhoneInputContainer>
                <PhoneIntlDropdownContainer ref={phoneContainer}>
                  <PhoneIntlDropdownHeader onClick={togglePhoneDropdown}>
                    {phoneSelected.flag} {phoneSelected.code}
                    <PhoneIntlDropdownArrow open={phoneOpen} />
                  </PhoneIntlDropdownHeader>
                  {phoneOpen && (
                    <PhoneIntlDropdownList>
                      {phoneOptions.map((option) => (
                        <PhoneIntlDropdownOption
                          key={option.code}
                          onClick={() => {
                            setPhoneSelected(option);
                            togglePhoneDropdown();
                          }}
                          onBlur={() => setPhoneOpen(false)}
                        >
                          {option.flag} {option.country}
                          <PhoneIntlDropdownOptionCode>
                            {option.code}
                          </PhoneIntlDropdownOptionCode>
                        </PhoneIntlDropdownOption>
                      ))}
                    </PhoneIntlDropdownList>
                  )}
                </PhoneIntlDropdownContainer>
                <PhoneInputElement
                  type="tel"
                  placeholder={phoneSelected.placeholder}
                  {...register("phone", {
                    required: "Var vänlig ange ett giltigt telefonnummer",
                    onChange: onPhoneChange,
                    pattern: phoneRegexp,
                    maxLength: 12
                  })}
                />
              </PhoneInputContainer>
              <ErrorText>{phoneError}</ErrorText>
            </FormInput>
          </Form>

          <Beside>
            <Checkbox
              checked={getValues("approvesDirectMarketing")}
                onChange={(value: boolean) => {
                  setValue("approvesDirectMarketing", value);
                }}
            />
            <LabelBeside>
              Jag vill få erbjudanden, nyhetsbrev
              och inspiration från Clas Fixare via email och sms
            </LabelBeside>
          </Beside>

          <Divider />

          <StepHeader>
            <SubHeadline4>Address</SubHeadline4>
          </StepHeader>
          <Form>
            <FormInput>
              <FormLabel>Gata</FormLabel>
              <Input
                {...register("street", {
                  required: "Ange address med gatunummer",
                })}
              />
              <ErrorText>{errors.street?.message}</ErrorText>
            </FormInput>

            <FormInput>
              <FormLabel>Postnummer</FormLabel>
              <Input disabled={true} value={postalCode ?? ""} />
            </FormInput>
          </Form>

          <Form>
            <FormInput>
              <FormLabel>Ort</FormLabel>
              <Input {...register("city", { required: "Ange ort" })} />
              <ErrorText>{errors.city?.message}</ErrorText>
            </FormInput>
            <FormInput>
              <FormLabel>Portkod</FormLabel>
              <Input {...register("gateAccessCode")} />
            </FormInput>
          </Form>

          <Form>
            <FormInput>
              <FormLabel>Boendeform</FormLabel>
              <Select
                {...register("livingForm", { required: "Ange boendeform" })}
              >
                <option disabled value="">
                  Välj alternativ
                </option>
                <option>Bostadsrätt</option>
                <option>Hyresrätt</option>
                <option>Villa, radhus, parhus m.fl.</option>
                <option>Annat</option>
              </Select>
              <ErrorText>{errors.livingForm?.message}</ErrorText>
            </FormInput>
            <FormInput>&nbsp;</FormInput>
          </Form>
        </Wrapper>
        <Container>
          <Consent />
          <BtnContainer>
            <BlackBtn type="submit">Nästa</BlackBtn>
          </BtnContainer>
        </Container>
      </form>
    </>
  );
};

const Beside = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  align-items: center;
  max-width: calc(50% - 1rem);
`;

const LabelBeside = styled(SubHeadline6)`
  margin-left: 0.5rem;
`;

const Wrapper = styled.div`
  background-color: ${colors.secondary.blue[2]};
  padding: 1.3125rem;
  width: 100%;
`;

const StepHeader = styled.div`
  margin-bottom: 2.625rem;
`;

const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Divider = styled.div`
  background-color: white;
  width: 120%;
  height: 3rem;
  margin: 4rem 0 1rem -2rem;
`;

const Form = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;

  ${media.tablet} {
    gap: 1rem;
    flex-direction: row;
    align-items: start;
  }
`;

const FormSSN = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 100%;

  ${media.tablet} {
    max-width: 50%;
  }
`;

const Input = styled.input`
  border-radius: 0.2rem;
  border: none;
  height: 2.5rem;
  padding-left: 0.5rem;
`;

const Select = styled.select`
  border-radius: 0.2rem;
  border: none;
  height: 2.5rem;
  padding-left: 0.5rem;
  background-color: white;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  ${media.tablet} {
    margin-bottom: 0;
    width: 20rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.3125rem 0;
  justify-content: space-between;
  align-items: right;

  ${media.tablet} {
    flex-direction: row;
  }
`;

const FormLabel = styled(SubHeadline5)`
  margin: 0.5rem 0;
`;

const ErrorText = styled(BodyText)`
  color: #cc3c3c;
  margin: 0;
  padding-top: 0.25rem;
`;

const PhoneIntlDropdownContainer = styled.div`
  width: 6.5rem;
  user-select: none;
  cursor: pointer;
`;

const PhoneIntlDropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-items: start;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  border: none;
  height: 2.5rem;
  padding: 0.5rem;
  background-color: white;
  &:hover {
    background-color: ${colors.grey[3]};
  }
`;

const PhoneIntlDropdownArrow = styled.i<{ open: boolean }>`
  content: "";
  border: solid black;
  border-width: 0 0.15rem 0.15rem 0;
  display: inline-flex;
  padding: 0.15rem;
  margin-left: auto;
  ${(props) =>
    props.open
      ? css`
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
        `
      : css`
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        `}
`;

const PhoneIntlDropdownList = styled.ul`
  padding: 0;
  margin: 0;
  position: absolute;
  max-height: 10rem;
  overflow: auto;
  border-radius: 0.2rem;
`;

const PhoneIntlDropdownOption = styled.li`
  list-style: none;
  background-color: white;
  padding: 0.5rem;
  width: 14rem;
  display: flex;
  flex-direction: row;
  &:hover {
    background-color: ${colors.grey[3]};
  }
`;

const PhoneIntlDropdownOptionCode = styled.div`
  margin-right: 0;
  margin-left: auto;
  color: ${colors.grey[2]};
`;

const PhoneInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const PhoneInputElement = styled.input`
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  border: none;
  height: 2.5rem;
  padding-left: 0.5rem;
  flex-grow: 1;
`;
