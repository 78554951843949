import { PreviewPropsBase, QuestionPreviewModuleConfiguration } from "..";
import { Action } from "utils";
import { Choice, MultipleChoiceDefinition } from ".";
import styled from "styled-components";
import { defaultEstimate } from "estimates";
import { Tooltip } from "../Tooltip";
export const Configuration: QuestionPreviewModuleConfiguration<
  MultipleChoiceQuestionPreviewProps,
  MultipleChoiceDefinition,
  Actions
> = {
  typeId: "multi-question",
  isValidToSubmit: (state, definition) => {
    if (!definition.mandatory) return true;
    return !!state.selectedChoice?.answer;
  },
  presentAnswerText: (state) => {
    return state.selectedChoice?.answer;
  },
  extractEstimatesFromPreviewData: (input, definition) => {
    if (input.selectedChoice) return [input.selectedChoice.estimate];

    return [];
  },
  getDefaultPreviewData: () => {
    return {
      selectedChoice: undefined,
    };
  },
  getDefaultPreviewDataFromDefinition: (definition) => {
    const selectedChoice = definition.choices?.find((a) => true);
    return {
      selectedChoice,
    };
  },
  getPreviewDataReducer: (
    state: MultipleChoiceQuestionPreviewProps,
    action,
    definition
  ) => {
    switch (action.type) {
      case "select_choice": {
        return {
          ...state,
          selectedChoice: definition.choices.find(
            (a) => a.id === action.choiceId
          )!,
        };
      }
    }
    return state;
  },
  previewQuestion: ({ definition, state, dispatch }) => {
    const choices = [...definition.choices];
    return (
      <Wrapper>
        <LabelCol>
          <div>
            <QuestionText>
              {definition.questionText}
              {definition.mandatory && " *"}
            </QuestionText>
            <Tooltip text={definition.tooltipText} />
          </div>
        </LabelCol>
        <select
          value={state.selectedChoice?.id || "None"}
          onChange={(e: any) => {
            let choice = definition.choices.find(
              (x) => x.id === e.target.value
            );
            if (!choice) {
              dispatch({ type: "select_choice", choiceId: e.target.value });
              return;
            }

            dispatch({ type: "select_choice", choiceId: e.target.value });
          }}
        >
          <option value="None" disabled={definition.mandatory}>
            Välj alternativ
          </option>
          {choices.map((c) => (
            <option key={c.id} value={c.id}>
              {c.answer}
            </option>
          ))}
        </select>
      </Wrapper>
    );
  },
  presentAnswer: ({ definition, state }) => {
    return (
      <Wrapper>
        <QuestionText>{definition.questionText}</QuestionText>
        <QuestionText>{state.selectedChoice?.answer}</QuestionText>
      </Wrapper>
    );
  },
};

export interface MultipleChoiceQuestionPreviewProps extends PreviewPropsBase {
  selectedChoice?: Choice;
}

interface SelectChoice extends Action {
  type: "select_choice";
  choiceId: string;
}

type Actions = SelectChoice;

const LabelCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;

  & select {
    background-color: white;
    border-radius: 0.25rem;
    border: 0.125rem solid black;
    padding: 0rem 0.875rem;
    width: 100%;
    height: 2.5rem;
    padding: 0.1rem;
    margin-top: 0.1rem;
  }
  & select::after {
    padding-right: 0.5rem;
  }
`;
const QuestionText = styled.span``;
