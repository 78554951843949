import { CheckoutContext } from "./Checkout/context";
import { CheckoutRouter } from "./Router";
import { StepperContext } from "./Stepper/useStepper";

export const CheckoutPage = () => {
  return (
    <StepperContext>
      <CheckoutContext>
        <CheckoutRouter />
      </CheckoutContext>
    </StepperContext>
  );
};
