import { createContext, FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function useStepper(): StepperContextProps {
  return useContext(context);
}

interface StepperContextProps {
  move: (direction: "forward" | "backwards" | number) => void;
  links: {
    active: boolean;
    id: string;
    label: string;
    badge: string;
  }[];
}
const context = createContext<StepperContextProps>({
  links: [],
  move: () => {},
});

export const StepperContext: FC = ({ children }) => {
  const navigate = useNavigate();
  const [currentLinkIndex, setCurrentLinkIndex] = useState<number>(0);
  function move(direction: "forward" | "backwards" | number) {
    setCurrentLinkIndex((index) => {
      const maxIndex = alllinks.length - 1;
      switch (direction) {
        case "backwards":
          return index > 0 ? index - 1 : 0;
        case "forward":
          return index === maxIndex ? maxIndex : index + 1;
        default:
          return direction;
      }
    });
  }
  useEffect(() => {
    const link = alllinks[currentLinkIndex];
    navigate(`/checkout/${link.id}`);
  }, [currentLinkIndex]);
  const l = alllinks.map((a, index) => {
    return { ...a, active: index === currentLinkIndex };
  });

  const value = {
    links: l,
    move,
  };
  return <context.Provider value={value}>{children}</context.Provider>;
};
interface Link {
  id: string;
  label: string;
  badge: string;
}
const alllinks: Link[] = [
  { id: "deliverydate", label: "Önskat datum", badge: "1" },
  { id: "questions", label: "Bilder", badge: "2" },
  { id: "personaldetails", label: "Uppgifter", badge: "3" },
  { id: "summary", label: "Översikt", badge: "4" },
];
