import { FC } from "react";
import styled from "styled-components";
import * as React from "react";
import { colors } from "../../style/statics";

export const Switch: FC<{
  checked: boolean;
  onChange: () => void;
}> = ({ checked, onChange }) => {
  enum SwitchType {
    Square = "",
  }

  interface IToggleSwitchProps {
    type: SwitchType;
  }

  const ToggleSwitch: React.FC<IToggleSwitchProps> = (props): JSX.Element => (
    <SwitchClass>
      <SwitchInput checked={checked} type="checkbox" onChange={onChange} />
      <SwitchSlider />
    </SwitchClass>
  );

  return <ToggleSwitch type={SwitchType.Square} />;
};

const SwitchClass = styled.label`
  position: relative;
  display: inline-block;
  width: 4em;
  height: 2em;
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: flex;
  align-items: center;
  padding-left: 0.3rem;
  border-radius: 0.3rem;
  color: ${colors.grey[1]};

  &:after {
    content: "Nej";
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
  }

  &:before {
    position: absolute;
    content: "";
    height: 1.6em;
    width: 1.6em;
    left: 0.3rem;
    bottom: 3px;
    background-color: #000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 0.3rem;
  }
`;

const SwitchInput = styled.input`
  display: none;

  &:checked + span:before {
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
  }

  &:checked + span:after {
    content: "Ja";
    -webkit-transform: translateX(0rem);
    -ms-transform: translateX(0rem);
    transform: translateX(0rem);
  }
`;
