import { FixedPrice } from "../FixedPrice";
import { Estimate } from "../interfaces";
import { Price } from "../Models/price";
import { Time, TimeEstimate, TimeEstimateInterval } from "../Models/time";
import {
  flattenFixedPrice,
  flattenTimeEstimate,
  flattenTimeInterval,
} from "./flatten";
import { joinTime } from "./helpers";
function chargeTotalTime(time: Time): Time {
  const length = Math.ceil(time.length / 30) * 30;
  return {
    variant: "minutes",
    length,
  };
}
export function summarizePriceEstimates(estimates: Estimate[]) {
  const result = estimates.reduce(estimateReducer, defaultProps);
  const timeEstimates = flattenTimeEstimate(result.fixedTime);
  const intervalEstimates = flattenTimeInterval(result.intervalTime);
  const fixedPriceEstimate = flattenFixedPrice(result.fixedPrice);
  const price: Price = fixedPriceEstimate.price;
  const fromDuration = joinTime([
    timeEstimates.duration,
    intervalEstimates.fromDuration,
  ]);
  const toDuration = joinTime([
    timeEstimates.duration,
    intervalEstimates.toDuration,
  ]);
  const from = chargeTotalTime(fromDuration);
  const to = chargeTotalTime(toDuration);

  return {
    from,
    to,
    price,
  };
}

const defaultProps: EstimateResultProps = {
  fixedPrice: [],
  fixedTime: [],
  intervalTime: [],
  quoteRequired: false,
};

interface EstimateResultProps {
  fixedPrice: FixedPrice[];
  fixedTime: TimeEstimate[];
  intervalTime: TimeEstimateInterval[];
  quoteRequired: boolean;
}

function estimateReducer(
  state: EstimateResultProps,
  estimate: Estimate
): EstimateResultProps {
  switch (estimate.type) {
    case "None":
      return state;
    case "FixedPrice":
      return {
        ...state,
        fixedPrice: [...state.fixedPrice, estimate as FixedPrice],
      };
    case "FixedTime":
      return {
        ...state,
        fixedTime: [...state.fixedTime, estimate as TimeEstimate],
      };
    case "IntervalTime":
      return {
        ...state,
        intervalTime: [...state.intervalTime, estimate as TimeEstimateInterval],
      };
    case "QuoteRequired":
      return { ...state, quoteRequired: true };
  }
  return state;
}

export function SummarizeEstimatesPerType(estimates: Estimate[]) {
  const defaultProps: EstimateResultProps = {
    fixedPrice: [],
    fixedTime: [],
    intervalTime: [],
    quoteRequired: false,
  };
  const { fixedPrice, fixedTime, intervalTime, quoteRequired } =
    estimates.reduce(estimateReducer, defaultProps);

  return { fixedPrice, fixedTime, intervalTime, quoteRequired };
}
