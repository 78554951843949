import { Answer } from "booking-backend/src/Cart/reducer";
import { FC, useEffect, useReducer } from "react";
import styled from "styled-components";
import { EnhancedCartItem, useCart } from "../Cart/index";
import { reducer } from "../MainPage/serviceBundle/reducer";
import { SingleServiceBundlePriceSummary } from "../MainPage/serviceBundle/SingleServiceBundlePriceSummary";
import { BlackBtn } from "../style/Buttons";
import { media } from "../style/media";
import { colors } from "../style/statics";

export const EditElement: FC<{ item: EnhancedCartItem }> = ({ item }) => {
  const { dispatch: dispatchCart, startEditItem } = useCart();
  const [allItems, dispatch] = useReducer(reducer, {
    items: item.questionAnswers,
  });

  useEffect(() => {
    dispatch({ type: "reset", questions: item.questionAnswers });
  }, [item]);

  function save() {
    const answers = allItems.items.map(
      (a) =>
        ({
          answerId: (a as any).answerId,
          answerState: a.state,
          questionDefinitionId: a.questionDefinitionId,
        } as Answer)
    );
    dispatchCart({
      type: "updated-item-in-cart",
      serviceInCartId: item.serviceInCartId,
      answers,
    });

    startEditItem();
  }
  const allQuestionEstimates = allItems.items.flatMap((s) => {
    return s.meta.extractEstimatesFromPreviewData(s.state, s.definition);
  });
  const allEstimates = [...allQuestionEstimates, item.serviceBundle?.estimate!];
  return (
    <Configuration>
      <ItemWrapper>
        {allItems.items.map(
          (
            { state, definition, meta, questionDefinitionId, estimates },
            answerId
          ) => (
            <AnswerItem key={answerId}>
              {meta.previewQuestion({
                definition,
                state,
                dispatch: (action) => {
                  const previewState = meta.getPreviewDataReducer(
                    state,
                    action,
                    definition
                  );
                  dispatch({
                    type: "StateUpdated",
                    questionDefinitionId,
                    previewState,
                  });
                },
              })}
            </AnswerItem>
          )
        )}
        <SingleServiceBundlePriceSummary
          allEstimates={allEstimates}
          deductable={item.serviceBundle.deductable ?? "None"}
          basePrice={item.serviceBundle.estimate}
          serviceBundle={item.serviceBundle}
        />
        <BtnContainer>
          <BlackBtn onClick={save}>Bekräfta</BlackBtn>
        </BtnContainer>
      </ItemWrapper>
    </Configuration>
  );
};

const Configuration = styled.div`
  background-color: ${colors.grey[3]};
  margin-top: -1.2rem;
  padding: 1rem;
  border-radius: 0.25rem;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;

  ${media.tablet} {
    justify-content: flex-end;
  }
`;

const ItemWrapper = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 0.25rem;
`;

const AnswerItem = styled.div`
  margin: 1rem 0;
`;
