import styled from "styled-components";
import { media } from "../../style/media";
import { BodyText } from "../../style/Text";

export function LegalFooter() {
  return (
    <Footer>
      <a href="/integritets-policy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
        <LinkText>Integritetspolicy</LinkText>
      </a>
      <a href="/cookie-policy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
        <LinkText>Cookiepolicy</LinkText>
      </a>
      <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
        <LinkText>Allmänna villkor</LinkText>
      </a>
    </Footer>
  );
}

const Footer = styled.footer`
  background-color: black;
  padding: 1rem 0;
  bottom: 0;
  display: flex;
  color: white;
  align-items: left;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 3.2rem;

  ${media.tablet} {
    padding: 0.4rem 0;
  }
`;

const LinkText = styled(BodyText)`
  font-size: 0.75rem;
  color: white;
  margin: 0 1rem;
`;
