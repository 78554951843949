import styled from "styled-components";
import { media } from "../../style/media";
import { BodyText } from "../../style/Text";

export function Consent() {
  return (
    <ConsentContainer>
      <ConsentText>
        Genom att använda tjänsten godkänner du våra{" "}
        <a href="/terms-and-conditions" target="_blank">
          allmänna villkor &amp; avbokningsregler
        </a>{" "}
        samt{" "}
        <a href="/integritets-policy" target="_blank">
          Integritetspolicy{" "}
        </a>
        . Vi lagrar dina uppgifter för att kunna erbjuda dig tjänsten. Du kan få
        tillgång till eller ta bort din data när som helst genom att kontakta
        kundtjänst.
      </ConsentText>
    </ConsentContainer>
  );
}

const ConsentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.tablet} {
    justify-content: flex-start;
    width: 80%;
  }
`;

const ConsentText = styled(BodyText)`
  margin-bottom: 0;
`;
