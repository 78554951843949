import { useCart } from "../Cart/useCart";
import styled from "styled-components";
import { colors } from "../style/statics";
import { SubHeadline6, SubHeadline6Grey } from "../style/Text";
import { Checkbox } from "../Global/components/Checkbox";
import { media } from "../style/media";
import { Switch } from "../Global/components/Switch";

export function ClasMedlemChecker() {
  const { isClasMedlem, dispatch } = useCart();
  function toggle() {
    dispatch({
      type: "toggle-is-member",
    });
  }
  return (
    <ClubClasMember>
      <CheckBoxAlternative>
        <SubHeadline6>Är du Club Clas medlem?</SubHeadline6>
        <SubHeadline6Grey>10 % på alla tjänster</SubHeadline6Grey>
      </CheckBoxAlternative>
      <CheckboxWrapper>
        <Checkbox checked={isClasMedlem} onChange={toggle}></Checkbox>
      </CheckboxWrapper>
    </ClubClasMember>
  );
}

export function ClasMedlemCheckerSmall() {
  const { isClasMedlem, dispatch } = useCart();
  function toggle() {
    dispatch({
      type: "toggle-is-member",
    });
  }
  return (
    <ClubClasMemberSmall>
      <CheckBoxAlternative>
        <SubHeadline6>Club Clas medlem?</SubHeadline6>
      </CheckBoxAlternative>
      <CheckboxWrapper>
        <Switch checked={isClasMedlem} onChange={toggle} />
      </CheckboxWrapper>
    </ClubClasMemberSmall>
  );
}

export function DeductionChecker() {
  const { useDeduction, dispatch } = useCart();
  function toggle() {
    dispatch({
      type: "toggle-use-deduction",
    });
  }
  return (
    <DeductionCheckerWrapper>
      <CheckBoxAlternative>
        <SubHeadline6>Räkna med RUT/ROT?</SubHeadline6>
      </CheckBoxAlternative>
      <CheckboxWrapper>
        <Checkbox checked={useDeduction} onChange={toggle}></Checkbox>
      </CheckboxWrapper>
    </DeductionCheckerWrapper>
  );
}

export function DeductionCheckerSmall() {
  const { useDeduction, dispatch } = useCart();
  function toggle() {
    dispatch({
      type: "toggle-use-deduction",
    });
  }
  return (
    <DeductionCheckerWrapperSmall>
      <CheckBoxAlternative>
        <SubHeadline6>Vill du räkna med RUT/ROT?</SubHeadline6>
      </CheckBoxAlternative>
      <CheckboxWrapper>
        <Switch checked={useDeduction} onChange={toggle} />
      </CheckboxWrapper>
    </DeductionCheckerWrapperSmall>
  );
}

const ClubClasMember = styled.div`
  width: 100%;
  background-color: ${colors.brandColor};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 0.25rem;

  ${media.tablet} {
    padding: 1rem 1.5rem;
    margin: 1rem 0.5rem 1rem 0;
  }
`;

const ClubClasMemberSmall = styled.div`
  width: 100%;
  background-color: ${colors.illustration.blue};
  justify-content: space-between;
  display: flex;
  margin-right: 0.4rem;
  padding: 0.8rem 1.2rem;
  border-radius: 0.25rem;
  height: 4rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
`;

const CheckBoxAlternative = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeductionCheckerWrapper = styled.div`
  width: 100%;
  background-color: ${colors.secondary.yellow[3]};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  padding: 1rem;
  margin: 0.5rem 0 1rem 0;
  border-radius: 0.25rem;

  ${media.tablet} {
    margin: 1rem 0;
    padding: 1rem 1.5rem;
  }
`;

const DeductionCheckerWrapperSmall = styled.div`
  width: 100%;
  background-color: ${colors.secondary.red[3]};
  display: flex;
  margin-right: 0.4rem;
  padding: 0.8rem 1.2rem;
  border-radius: 0.25rem;
  height: 4rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
`;
