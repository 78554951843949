import {
  CheckoutQuestionPreviewModuleConfiguration,
  PreviewPropsBase,
} from "..";
import { Action, uniqueId } from "utils";
import { ImageUploadQuestionDefinition } from ".";
import { MdOutlineAddCircle } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import styled from "styled-components";
import { FC, useState } from "react";

export const Configuration: CheckoutQuestionPreviewModuleConfiguration<
  ImageUploadPreviewProps,
  ImageUploadQuestionDefinition,
  Actions
> = {
  typeId: "imageupload-question",
  presentAnswerText: ({ state, definition }) => {
    if (!state.fileUrl) {
      return `${definition.questionText}: Ingen bild uppladdad`;
    }
    return `${definition.questionText}: ${state.fileUrl}`;
  },
  presentAnswerHTML: ({ definition, state }) => {
    if (!state.fileUrl) {
      return `${definition.questionText}: Ingen bild uppladdad`;
    }
    return `<div>
            <label>${definition.questionText}</label>
            <a href="${state.fileUrl}" target="_blank"><img style="max-width: 400px;" src="${state.fileUrl}" /></a>
        </div>`;
  },
  presentAnswer: ({ definition, state }) => {
    return (
      <Wrapper>
        {definition.questionText}:{" "}
        {state.fileUrl ? <>Uppladdad</> : <>Inte uppladdad</>}
      </Wrapper>
    );
  },
  getDefaultPreviewData: () => {
    return {};
  },
  getDefaultPreviewDataFromDefinition: (definition) => {
    return {};
  },
  getPreviewDataReducer: (
    state: ImageUploadPreviewProps,
    action,
    definition
  ) => {
    switch (action.type) {
      case "added_image":
        return {
          ...state,
          fileUrl: action.fileUrl,
          fileType: action.fileType,
          fileName: action.fileName,
        };
      case "removed_image":
        return {
          ...state,
          fileUrl: undefined,
          fileType: undefined,
          fileName: undefined,
        };
    }
    return state;
  },
  previewQuestion: ({ definition, state: { fileUrl }, dispatch }) => {
    const urlToUpload = `/api/uploadCustomerFile`;

    async function uploadFile(file: File) {
      const formData = new FormData();
      formData.append("images", file, file.name);
      const res = await fetch(urlToUpload, {
        method: "POST",
        body: formData,
      });

      const result: { fileUrl: string; fileType: string; fileName: string } =
        await res.json();
      dispatch({
        type: "added_image",
        fileUrl: result.fileUrl,
        fileType: result.fileType,
        fileName: result.fileName,
      });
    }

    async function close() {
      dispatch({
        type: "removed_image",
      });
    }

    return (
      <>
        {fileUrl ? (
          <>
            <ImageWrapper src={fileUrl} onclick={() => close()} />
          </>
        ) : (
          <FileUploadComponent uploadFile={uploadFile}>
            <QuestionText>{definition.questionText}</QuestionText>
          </FileUploadComponent>
        )}
      </>
    );
  },
};
const FileUploadComponent: FC<{ uploadFile: (file: File) => void }> = ({
  children,
  uploadFile,
}) => {
  const [id] = useState<string>(() => uniqueId());
  return (
    <FileUploadLabel htmlFor={id}>
      {children}
      <PlusButton />
      <FileInput id={id} type="file" onChange={onFileChange(uploadFile)} />
    </FileUploadLabel>
  );
};

function onFileChange(cb) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    cb(file);
  };
}

export interface ImageUploadPreviewProps extends PreviewPropsBase {
  fileUrl?: string;
}

interface AddedImageAction extends Action {
  type: "added_image";
  fileName: string;
  fileType: string;
  fileUrl: string;
}

interface RemovedImageAction extends Action {
  type: "removed_image";
}

type Actions = AddedImageAction | RemovedImageAction;

function PlusButton() {
  return (
    <PlusButtonStyle>
      <MdOutlineAddCircle size={30} />
    </PlusButtonStyle>
  );
}

const PlusButtonStyle = styled.div`
  color: #78d7fa;
  margin: 0.5rem;
`;
const FileUploadLabel = styled.label`
  cursor: pointer;
  border: 2px dotted #7cd2fb;
  background-color: white;
  color: #a8acad;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
`;
const FileInput = styled.input`
  display: none;
`;

function ImageWrapper({ src, onclick }) {
  return (
    <ImageWrapInternal>
      <Image src={src} />
      <CloseButton size={20} onClick={onclick} />
    </ImageWrapInternal>
  );
}

const CloseButton = styled(AiFillCloseCircle)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  fill: black;
  &:hover {
    opacity: 80%;
  }
`;
const ImageWrapInternal = styled.div`
  border: 1px solid #ccc;
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
`;
const Image = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
`;

const Wrapper = styled.div``;
const QuestionText = styled.span``;
