import { QUOTE_REQUIRED_TEXT, QUOTE_REQUIRED_TYPE } from "./models";

export * from "./models";

export const checkQuestionsForQuoteRequired = (question, item?): Boolean => {
  switch (question.meta.typeId) {
    case "yesno-question":
      const value = question.state.selected;
      const selectedAnswer = question.definition?.choices?.find(
        (definitionAnswer) => {
          return definitionAnswer?.answer === value;
        }
      );
      return selectedAnswer?.estimate?.type === QUOTE_REQUIRED_TYPE;
    case "quantity":
      const count = question?.state?.count;
      if (count > 1) {
        return question?.definition?.estimate.type === QUOTE_REQUIRED_TYPE;
      }
      return false;
    case "product-selection-question":
      const bringProducts = item?.questionAnswers?.find(
        (questionAnswer) => questionAnswer?.state?.answer === "bring products"
      );
      if (bringProducts) {
        const productId = bringProducts?.state?.selectedProductId;
        let product = null;
        const questionDefinition =
          item?.serviceBundle?.questionDefinitions?.find((question) => {
            product = question.definition?.products?.find(
              (product) =>
                product.id === productId &&
                product?.estimate?.type === QUOTE_REQUIRED_TYPE
            );
            return product;
          });
        if (questionDefinition) {
          return true;
        }
      }
      return false;
    default:
      return (
        question.state?.selectedChoice?.estimate?.type === QUOTE_REQUIRED_TYPE
      );
  }
};

function parantz(input: string) {
  if (input === "") return "";
  return `(${input})`;
}

export function uniqueId() {
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const length = 20;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

export interface Action {
  type: String;
}

export function slugify(string) {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function getCategoriesFromCartItems(enhancedCartItemsInCart) {
  let categories = {};
  let subCategories = {};
  for (const item of enhancedCartItemsInCart) {
    const category = item.serviceBundle.category;
    if (category) {
      subCategories[category.id] = category;
      const parentCategory = category.parentCategory;
      if (parentCategory) {
        const childIds =
          categories[parentCategory.id] &&
          categories[parentCategory.id].childIds
            ? categories[parentCategory.id].childIds
            : [];
        if (!childIds.includes(category.id)) {
          childIds.push(category.id);
        }
        categories[parentCategory.id] = {
          childIds: childIds,
          category: parentCategory,
        };
      }
    }
  }
  return { categories, subCategories };
}

export function getQuestionAnswersAsHtml(
  questionAnswers,
  cartItem,
  HTML = true
) {
  return questionAnswers
    .map((answer) => {
      const isQuoteRequired = checkQuestionsForQuoteRequired(answer, cartItem);
      let quoteRequiredText = isQuoteRequired
        ? parantz(QUOTE_REQUIRED_TEXT)
        : "";
      return `${answer.questionText}: ${
        answer.answerText ?? "Ej angivet"
      } ${parantz(answer.estimateText)} ${parantz(
        answer.priceText
      )} ${quoteRequiredText}`;
    })
    .join(HTML ? "<br /><br />" : " \n ");
}

export function getEstimatedTimeFromQuestionAnswers(questionAnswers) {
  return questionAnswers
    .map((answer) => {
      return `${answer.estimateText} `;
    })
    .join(" ");
}

export function getCheckoutQuestionAnswersAsHtml(
  checkoutQuestionAnswers,
  HTML = true
) {
  return checkoutQuestionAnswers
    .map((answer) => {
      return `${answer.answerHTML}`;
    })
    .join(HTML ? "<br /><br />" : " \n ");
}
