import { FC } from "react";
import styled from "styled-components";
import { useServiceBundleConfig } from "./useServiceBundlePreview";

export const RenderAllQuestions: FC = () => {
  const { questions, dispatch } = useServiceBundleConfig();

  return (
    <>
      {questions.map(
        (
          { questionDefinitionId, state, definition, meta, estimates },
          index
        ) => {
          const RenderElement = meta.previewQuestion;

          return (
            <Questions key={index}>
              <RenderElement
                {...{
                  definition,
                  state,
                  dispatch: (action) => {
                    const previewState = meta.getPreviewDataReducer(
                      state,
                      action,
                      definition
                    );
                    dispatch({
                      type: "StateUpdated",
                      questionDefinitionId,
                      previewState,
                    });
                  },
                }}
              />
            </Questions>
          );
        }
      )}
    </>
  );
};

const Questions = styled.div`
  margin: 1.2rem 0;
`;
