import { createContext, FC, useContext, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router";
import styled from "styled-components";
import { AccordionToggle } from "./AccordionToggle";
import { Category } from "servicebundlecore/model";

export const StyledAccordion: FC<AccordionContextProps> = ({
  activeKey,
  whitespace,
  ...rest
}) => {
  return (
    <Accordion flush activeKey={activeKey}>
      <AccordionContextProvider
        whitespace={whitespace}
        activeKey={activeKey}
        {...rest}
      />
    </Accordion>
  );
};

export interface AccordionItem {
  id: string;
  title: string | JSX.Element;
  image?: JSX.Element | string;
  disabled?: boolean;
  disabledNavigation?: boolean;
  disabledText?: string;
  subtitle?: string;
  category?: Category;
  discriminator?: string;
  parentCatgoryId?: string;
}

const AccordionContextProvider: FC<AccordionContextProps> = ({
  items,
  children,
  activeKey,
  onToggle,
  toggleButton,
  secondaryColors,
  whitespace,
}) => {
  const { hash } = useLocation();
  const activeItemRef = useRef(null);

  useEffect(() => {
    if (!activeItemRef.current) return;
    (activeItemRef.current as any).scrollIntoView({ behavior: "smooth" });
  }, [activeItemRef, items, hash]);

  return (
    <>
      <AccordionContext.Provider
        value={{
          whitespace,
          items,
          toggleButton,
          activeKey,
          secondaryColors,
        }}
      >
        {items.map((c) => (
          <div
            key={c.id}
            style={{
              margin: whitespace ? "1rem 0" : 0,
            }}
          >
            <AccordionItemWrapper
              ref={hash && `#${c.id}` === hash ? activeItemRef : null}
              id={c.id}
              eventKey={c.id}
              key={c.id}
            >
              <AccordionToggle
                item={c}
                onToggle={() => (c.disabledNavigation ? () => {} : onToggle(c))}
                eventKey={c.id}
                key={c.id}
              />
              {activeKey === c.id ? (
                <AccordionBody>{children}</AccordionBody>
              ) : (
                ""
              )}
            </AccordionItemWrapper>
          </div>
        ))}
      </AccordionContext.Provider>
    </>
  );
};

const AccordionContext = createContext<{
  whitespace?: boolean;
  items: AccordionItem[];
  toggleButton?: JSX.Element;
  activeKey?: string;
  secondaryColors?: boolean;
}>({
  items: [],
});

export const useAccordionContext = () => useContext(AccordionContext);
export interface AccordionContextProps {
  whitespace?: boolean;
  items: AccordionItem[];
  activeKey?: string;
  onToggle: (item: AccordionItem) => void;
  toggleButton?: JSX.Element;
  secondaryColors?: boolean;
}

const AccordionBody = styled(Accordion.Body)`
  padding: 0;
`;

const AccordionItemWrapper = styled(Accordion.Item)`
  border: 0;
  border-radius: 0.25rem;
  background-color: white;
`;
