import { createContext, FC, useContext, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { media } from "../../style/media";
import { colors } from "../../style/statics";

export const Footer: FC = ({ children }) => {
  const [content, setContent] = useState<JSX.Element | null>();

  return (
    <footerContext.Provider
      value={{
        renderFooter: setContent,
      }}
    >
      {children}
      {content && (
        <FooterWrapper>
          <Container>{content}</Container>
        </FooterWrapper>
      )}
    </footerContext.Provider>
  );
};

const footerContext = createContext<{
  renderFooter: (item: JSX.Element | null) => void;
}>(undefined as any);

export const useFooter = () => useContext(footerContext);

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 3.2rem;
  height: 9.2rem;
  background-color: ${colors.brandColor};
  width: 100%;
  padding: 1rem 0 3rem 0;
  align-items: center;

  ${media.tablet} {
    bottom: 2rem;
    height: 10rem;
  }
  ${media.desktop} {
    padding-top: 1.8rem;
    height: 9.5rem;
  }
`;
