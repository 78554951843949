import styled from "styled-components";
import { colors, MissingBundleImage } from "../../style/statics";
import "react-datepicker/dist/react-datepicker.css";
import {
  SubHeadline4,
  SubHeadline5,
  SubHeadline6,
  SubHeadline6Grey,
} from "../../style/Text";
import { useCheckout } from "../Checkout/context";
import { media } from "../../style/media";
import { BlackBtn } from "../../style/Buttons";
import { FC, useEffect } from "react";
import { EnhancedCartItem, useCart } from "../../Cart/index";
import {
  estimatedPriceWithDeductionsAndRebates,
  extractHourlyRateFromBundle,
  getTotalPriceForEnhancedCartItem,
  summarizeFromAndToPrice,
  summarizePriceEstimates,
} from "estimates";
import { useStepper } from "../Stepper/useStepper";
import { SimpleStepper } from "../Stepper";
import TagManager from "react-gtm-module";
import { DesiredStartDate } from "./DesiredStartDate";
import { DesiredStartTime } from "./DesiredStartTime";
import { DesireUrgency } from "./DesireUrgency";

const CartItem: FC<{ service: EnhancedCartItem }> = ({ service }) => {
  const { useDeduction, isClasMedlem } = useCart();
  const hourlyRate = extractHourlyRateFromBundle(service.serviceBundle);
  const totalPriceText = getTotalPriceForEnhancedCartItem(
    service,
    useDeduction,
    isClasMedlem,
    hourlyRate
  );

  return (
    <Service>
      <ImageContainer>
        <BundleImage
          src={service.serviceBundle?.cms.imgUrl || MissingBundleImage}
          alt="Logo"
        />
      </ImageContainer>
      <ProductAndPriceInfo>
        <SubHeadline6>{service.serviceBundle?.cms.title}</SubHeadline6>
        <SubHeadline6Grey>Estimerat pris - {totalPriceText}</SubHeadline6Grey>
      </ProductAndPriceInfo>
    </Service>
  );
};

export const ServiceQuestionPage = () => {
  const { enhancedCartItemsInCart, useDeduction, isClasMedlem } = useCart();
  const {
    dispatch,
    formState: { otherComments },
  } = useCheckout();

  useEffect(() => {
    window.scrollTo(0, 0);

    let totalpriceWithoutDeductionAndClubClas = 0;
    if (typeof window !== "undefined" && enhancedCartItemsInCart.length > 0) {
      const allPrices = enhancedCartItemsInCart.flatMap((s) => {
        const estimates = s.questionAnswers.flatMap((a) => {
          return a.meta.extractEstimatesFromPreviewData(a.state, a.definition);
        });
        const result = summarizePriceEstimates([
          ...estimates,
          s.serviceBundle?.estimate!,
        ]);

        const subTotalPriceWithoutDeduction =
          estimatedPriceWithDeductionsAndRebates({
            ...result,
            deduction: "None",
            isClasMedlem: false,
            hourlyRate: extractHourlyRateFromBundle(s.serviceBundle),
          });

        const subTotal = estimatedPriceWithDeductionsAndRebates({
          ...result,
          deduction: useDeduction ? s.serviceBundle?.deductable! : "None",
          isClasMedlem,
          hourlyRate: extractHourlyRateFromBundle(s.serviceBundle),
        });

        const bundlePrice = result?.price?.amount
          ? result?.price?.amount
          : subTotalPriceWithoutDeduction.fromPrice;
        totalpriceWithoutDeductionAndClubClas =
          totalpriceWithoutDeductionAndClubClas + bundlePrice;

        return subTotal;
      });
      const totalPrice = summarizeFromAndToPrice(allPrices);

      const tagManagerArgs = {
        dataLayer: {
          event: "funnel_boka.clasfixare.se_checkout",
          tasks: enhancedCartItemsInCart,
          totalPrice,
          useDeduction,
          isClasMedlem,
          totalpriceWithoutDeductionAndClubClas,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, []);
  const { move } = useStepper();

  if (enhancedCartItemsInCart.length < 1) {
    return <SubHeadline5>Inga tjänster i varukorgen</SubHeadline5>;
  }

  return (
    <>
      <SimpleStepper />
      <Wrapper>
        <StepHeader>
          <SubHeadline4>Dina valda tjänster</SubHeadline4>
        </StepHeader>
        <SelectedServices>
          {enhancedCartItemsInCart.map((service, key) => (
            <CartItem key={key} service={service} />
          ))}
        </SelectedServices>
        <HorizontalDividerLine />

        <DateSection />

        <HorizontalDividerLine />
        <SubHeadline5>Är det något specifikt vi behöver veta?</SubHeadline5>
        <Textarea
          placeholder={""}
          value={otherComments || ""}
          onChange={(e) =>
            dispatch({
              type: "set_form_value",
              value: { otherComments: e.target.value },
            })
          }
        />
      </Wrapper>
      <BtnContainer>
        <BlackBtn onClick={() => move("forward")}>Nästa</BlackBtn>
      </BtnContainer>
    </>
  );
};
function DateSection() {
  return (
    <DateSectionContainer>
      <DateSectionColumn>
        <DesiredStartDate />
        <DesireUrgency />
      </DateSectionColumn>
      <VerticalDividerLine />
      <DateSectionColumn>
        <DesiredStartTime />
        {/* <Debug /> */}
      </DateSectionColumn>
    </DateSectionContainer>
  );
}

function Debug() {
  const {
    formState: { requestedStartTimeId, requestedStartDate, desireUrgency },
  } = useCheckout();

  return (
    <pre>
      {JSON.stringify(
        { requestedStartTimeId, requestedStartDate, desireUrgency },
        null,
        2
      )}
    </pre>
  );
}
const DateSectionContainer = styled.div`
  ${media.desktop} {
    display: flex;
    gap: 1rem;
  }
`;
const DateSectionColumn = styled.div`
  width: 100%;
  ${media.desktop} {
    width: 50%;
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.secondary.blue[2]};
  padding: 1.3125rem;
`;

export const StepHeader = styled.div`
  margin-bottom: 2.625rem;
`;

const SelectedServices = styled.div`
  display: flex;
  margin-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  ${media.tablet} {
    flex-direction: row;
  }
`;

const Service = styled.div`
  margin-right: 2.5rem;
  display: flex;
  padding-bottom: 0.5rem;

  ${media.tablet} {
    align-items: center;
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  background-color: white;
  border-radius: 50%;
  margin-right: 1.3125rem;
  padding: 0.75rem;
`;

const ProductAndPriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  div:nth-last-child(1) {
    margin-top: 0.5rem;
  }
`;
const VerticalDividerLine = styled.div`
  border-right: 2px solid ${colors.secondary.blue[0]};
`;
const HorizontalDividerLine = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.secondary.blue[0]};
  margin: 1rem 0;
`;

const Textarea = styled.textarea`
  width: 100%;
  border-radius: 0.5px;
  border: none;
  outline: none;
  padding: 0.5rem;
  margin: 1rem 0;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  ${media.tablet} {
    margin-bottom: 0;
  }
`;

const BundleImage = styled.img`
  width: 2.5rem;
`;
