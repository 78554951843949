import { ServiceBundleConfiguration } from "./ServiceBundleConfiguration";
import { AccordionItem, StyledAccordion } from "../Accordion";
import styled from "styled-components";
import { colors, MissingBundleImage } from "../../style/statics";
import { TextButton } from "../Accordion/Buttons";
import { ServiceBundleSubstitute } from "./useServiceBundlePreview";
import {
  useCurrentServiceBundle,
  useServiceBundles,
} from "../Global/useServiceBundles";
import { useCategory, useSubCategory } from "../Global/useCategory";
import { useStateWithDeps } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { SubHeadline6 } from "../../style/Text";
import { useCart } from "../../Cart";
import { FC } from "react";
import {
  estimatedTimeAndPriceText,
  rebatePrice,
  summarizePriceEstimates,
} from "estimates";

export const ServiceBundleList = () => {
  const category = useCategory();
  const subCategory = useSubCategory();

  const currentServiceBundle = useCurrentServiceBundle();
  const { serviceBundles } = useServiceBundles();
  const navigate = useNavigate();
  const { serviceBundleId } = useParams<{ serviceBundleId: string }>();

  const [filteredServiceBunldes] = useStateWithDeps<
    ServiceBundleSubstitute[]
  >(() => {
    if (subCategory) {
      return serviceBundles.filter((x) => x.categoryId === subCategory.id);
    }
    return serviceBundles;
  }, [subCategory, serviceBundles]);

  const toggleNavigate = (a: AccordionItem) => {
    if (serviceBundleId === a.id) {
      navigate(`/${category?.seoName}/${subCategory?.seoName}`);
    } else navigate(`/${category?.seoName}/${subCategory?.seoName}/${a.id}`);
  };

  return (
    <>
      <Item>
        <StyledAccordion
          toggleButton={
            <TextButton
              disabled={subCategory?.unavailableAtYourArea}
              label=""
            />
          }
          onToggle={toggleNavigate}
          items={filteredServiceBunldes.map((x) => ({
            ...x,
            id: x.cms.seoName!,
            disabledText: "",
            disabledNavigation: subCategory?.unavailableAtYourArea,
            disabled: subCategory?.unavailableAtYourArea,
            image: <HeaderImage bundle={x} />,
            title: <BundleTitle bundle={x} />,
          }))}
          activeKey={currentServiceBundle?.cms.seoName || ""}
        >
          <ServiceBundleConfiguration serviceBundle={currentServiceBundle} />
        </StyledAccordion>
      </Item>
    </>
  );
};

const Item = styled.div`
  margin: 1.3rem 0rem;
  background-color: ${colors.grey[3]};
`;

const HeaderImage: FC<{ bundle: ServiceBundleSubstitute }> = ({ bundle }) => {
  return (
    <ImageBackground>
      <BundleImage
        src={bundle.cms.imgUrl || MissingBundleImage}
        alt={bundle.cms.title}
      />
    </ImageBackground>
  );
};

const BundleTitle: FC<{ bundle: ServiceBundleSubstitute }> = ({ bundle }) => {
  const { isClasMedlem, useDeduction } = useCart();
  const estimates = [bundle.estimate];
  const result = summarizePriceEstimates(estimates);
  const resultWithRebateOnPrice = rebatePrice(
    result,
    isClasMedlem,
    useDeduction ? bundle.deductable : "None"
  );
  const estimate = estimatedTimeAndPriceText(resultWithRebateOnPrice);
  return (
    <TitleBackground>
      <TitleRow>
        <SubHeadline6>
          {bundle.cms.title}
          {bundle.deductable &&
            bundle.deductable !== "None" &&
            bundle.deductable !== "UseParent" && (
              <Deductable>{bundle.deductable}</Deductable>
            )}
        </SubHeadline6>
      </TitleRow>
      <PriceLabel>{estimate}</PriceLabel>
    </TitleBackground>
  );
};

const Deductable = styled.span`
  margin-left: 1rem;
  padding: 0.1rem 0.3rem;
  background-color: #d0d0d0;
  font-weight: bold;
  font-size: 0.8rem !important;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-word;
`;

const PriceLabel = styled(SubHeadline6)`
  color: ${colors.grey[1]};
  margin-top: 0.438rem;
`;

const BundleImage = styled.img``;
const ImageBackground = styled.div`
  background-color: white;
  border-radius: 50%;
  padding: 0.8rem;
`;

const TitleBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
