import { Tooltip } from "questions/Tooltip";
import styled from "styled-components";
import { SubHeadline5 } from "../../style/Text";
import { useCheckout } from "../Checkout/context";
import { WrapHeadlineFlex } from "./DesiredStartDate";
import { SalesforceRequestedStartTime } from "salesforce/SalesforceModels";

export function DesiredStartTime() {
  const {
    dispatch,
    formState: { requestedStartTimeId },
  } = useCheckout();
  return (
    <Container>
      <WrapHeadlineFlex>
        <SubHeadline5>Önskad tid</SubHeadline5>
        <Tooltip text={tooltipText} />
      </WrapHeadlineFlex>
      <Select
        value={requestedStartTimeId}
        onChange={(e) => {
          dispatch({
            type: "set_form_value",
            value: { requestedStartTimeId: e.target.value as SalesforceRequestedStartTime },
          });
        }}
      >
        {desiredTimeAlternatives.map(({ id, label }) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
      </Select>
    </Container>
  );
}

interface Alternative {
  id: string;
  label: string;
}
export const desiredTimeAlternatives: Alternative[] = [
  { id: SalesforceRequestedStartTime.None, label: "Välj ett alternativ" },
  { id: SalesforceRequestedStartTime.FlexibleMorning, label: "Flexibel förmiddag" },
  { id: SalesforceRequestedStartTime.FlexibleEvening, label: "Flexibel eftermiddag" },
  { id: SalesforceRequestedStartTime.FlexibleWholeDay, label: "Flexibel hela dagen" },
  { id: SalesforceRequestedStartTime.NotFlexible, label: "Ej flexibel, fast tid önskas" },
];
const Container = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 1rem 0;
`;

const Select = styled.select`
  padding: 0.8rem;
  margin: 1rem 0;
  width: 100%;
  border: 0;
  outline: none;
  cursor: pointer;
`;

const tooltipText = `
    Detta hjälper oss att hitta en passande tid. 
    Med hjälp av denna information vet vi hur flexibel ni är när ni valt att få hjälp så snabbt som möjligt.

    Väljer du "Ej flexibel" hjälper vi till att boka så specifik tid som möjligt.
`;
