import classNames from "classnames";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styled from "styled-components";
import { Submenu } from "../../Global/SubMenu";
import { media } from "../../style/media";
import { colors } from "../../style/statics";
import { BodyTextMedium, Headline1, SubHeadline4 } from "../../style/Text";
import { GoBackButton } from "../components/GoBackButton";
import { useStepper } from "./useStepper";

export const SimpleStepper = (props) => {
  const { links, move } = useStepper();
  const { onSubmit } = props;

  const validateMove = (index, onSubmit) => {
    if (index === 2) {
      onSubmit();
    } else {
      move(index);
    }
  };

  //TODO refaktor stepper (but maybe will not need in new layout);
  const indexedLinks = links.map((item, index) => {
    return { ...item, index: index };
  });

  //TODO its not beutiful but works
  const activeItem = indexedLinks.find((item) => item.active);
  const currentIndex = activeItem?.index ? activeItem.index : 0;
  const maxIndex = 2;
  const nextIndex =
    currentIndex + 1 > maxIndex ? currentIndex : currentIndex + 1;
  const prevIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;

  return (
    <>
      <Submenu>
        <GoBackButton />
      </Submenu>
      <Headline1>Checka ut</Headline1>
      <StepperWrapper>
        <StepBtn onClick={() => validateMove(prevIndex, onSubmit)}>
          <MdKeyboardArrowLeft size={44} />
        </StepBtn>

        {links.map(({ id, label, badge, active }, index) => (
          <StepWrapper key={id} onClick={() => validateMove(index, onSubmit)}>
            <StepItem className={classNames({ active })}>
              <PageNumber>{badge}</PageNumber>
            </StepItem>
            <StepText>{label}</StepText>
          </StepWrapper>
        ))}

        <StepBtn onClick={() => validateMove(nextIndex, onSubmit)}>
          <MdKeyboardArrowRight size={44} />
        </StepBtn>
      </StepperWrapper>
    </>
  );
};

const StepperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const StepBtn = styled.div`
  border: none;
  cursor: pointer;
  padding: 0 1rem;

  ${media.mobile} {
    padding: 0;
  }

  :hover {
    color: ${colors.brandColor};
  }
`;

const StepItem = styled.div`
  background-color: ${colors.brandColor};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 0.5rem;
  margin-bottom: 0.5rem;

  ${media.mobile} {
    margin: 0 1rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  &.active {
    background-color: white;
    color: ${colors.brandColor};
    border: 2px solid ${colors.brandColor};
  }
`;

const PageNumber = styled(SubHeadline4)`
  margin: auto;
  font-size: 1.5rem;
`;

const StepText = styled(BodyTextMedium)`
  margin: 0 0.5rem;

  ${media.mobile} {
    padding: 0;
    margin-top: 0.5rem;
  }
`;
