import styled from "styled-components";
import { colors } from "../style/statics";
import {
  useCategories,
  useCategory,
  useParentCategories,
} from "./Global/useCategory";
import { StyledAccordion } from "./Accordion";
import { ArrowButton } from "./Accordion/Buttons";
import { FC } from "react";
import { useNavigate } from "react-router";
import { MIN_SEARCH_LENGTH } from "./ServiceBundleSearch/constants";
import { useCart } from "../Cart/useCart";

export const CategoriesAccordion: FC = ({ children }) => {
  const categories = useParentCategories();
  const category = useCategory();
  const navigate = useNavigate();
  const { postalCode } = useCart();
  const mainCategories = useCategories();
  const searchActive = mainCategories?.searchText?.length >= MIN_SEARCH_LENGTH;

  return (
    <div style={{ display: searchActive ? "none" : "block" }}>
      <StyledAccordion
        whitespace
        toggleButton={<ArrowButton />}
        onToggle={(c) => navigate(`/${category?.seoName === c.id ? "" : c.id}`)}
        items={categories.map((x) => {
          const disabledSubcatCount =
            x.subCategories?.filter((x) => !x.unavailableAtYourArea).length ||
            0;

          const subtitle = (() => {
            if (!postalCode) {
              return "Ange postnummer för att se vilka tjänster som är tillgängliga";
            }
            if (
              disabledSubcatCount > 0 &&
              disabledSubcatCount !== x.subCategories?.length
            ) {
              return `${disabledSubcatCount} ${
                disabledSubcatCount === 1 ? "underkategori" : "underkategorier"
              } finns tillgänglig i ditt område`;
            }
          })();

          return {
            ...x,
            id: x.seoName!,
            title: x.name,
            image: x.iconUrl,
            disabled:
              x.subCategories &&
              x.subCategories.length > 0 &&
              x.subCategories.every((y) => y.unavailableAtYourArea),
            subtitle,
          };
        })}
        activeKey={category?.seoName || ""}
      >
        <LineBreak />
        <SubCatMargin>{children}</SubCatMargin>
        <NonNegativeMarginHR />
      </StyledAccordion>
    </div>
  );
};

const SubCatMargin = styled.div`
  margin-left: 1rem;
`;

const LineBreak = styled.div`
  margin-top: 2.5rem;
`;

const NonNegativeMarginHR = styled.hr`
  border-top: 0.125rem solid ${colors.grey[3]};
  opacity: 1;
  margin: 1.688rem 0;
`;
