import {
  Button,
  OverlayTrigger,
  Popover,
  Tooltip as BT,
} from "react-bootstrap";
import { MdInfo } from "react-icons/md";
import styled from "styled-components";

export const Tooltip = ({ text }: { text?: string }) => {
  const placement = "top";
  if (!text) return null;

  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <PopoverContent id={`tooltip-${placement}`}>{text}</PopoverContent>
      }
    >
      <IconButton>
        <MdInfo size={"1.2rem"} />
      </IconButton>
    </OverlayTrigger>
  );
};

const IconButton = styled.button`
  background: none;
  border: 0;
  margin: 0;
  align-content: center;
`;

const PopoverContent = styled(Popover)`
  background-color: #c4c4c4;
  padding: 0.5rem;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(91, 91, 91, 0.41);
  box-shadow: 0px 0px 5px 0px rgba(91, 91, 91, 0.41);

  & > .popover-arrow:after {
    border-top-color: #c4c4c4;
  }
`;
