import { FC, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import {
  useCurrentServiceBundle,
  useServiceBundles,
} from "../Global/useServiceBundles";
import { context, useCategory, useSubCategory } from "../Global/useCategory";
import { simpleFilter } from "fuzzy";
import { ServiceBundleConfiguration } from "../serviceBundle/ServiceBundleConfiguration";
import { AccordionItem, StyledAccordion } from "../Accordion";
import { TextButton } from "../Accordion/Buttons";
import { ServiceBundleSubstitute } from "../serviceBundle/useServiceBundlePreview";
import { BundleTitle } from "../Global/BundleTitle";
import { HeaderImage } from "../Global/HeaderImage";
import { useNavigate, useParams } from "react-router-dom";
import magnification from "./magnification.svg";
import { MIN_SEARCH_LENGTH } from "./constants";
import { useDebounce } from "../../utils/useDebouncer";
import { media } from "../../style/media";

export const ServiceBundleSearch: FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const { serviceBundles } = useServiceBundles();
  const subCategory = useSubCategory();
  const category = useCategory();
  const categoryContext = useContext(context);
  const currentServiceBundle = useCurrentServiceBundle();

  const debouncedValue = useDebounce(searchText, 500);
  categoryContext.setSearchTextFunc(debouncedValue);

  const getConcatString = (bundleItem) => {
    let returnString = "";
    if (bundleItem.cms?.title) {
      returnString += bundleItem.cms.title;
    }

    if (bundleItem.category && bundleItem.category.name) {
      returnString += " : " + bundleItem.category.name;
    }

    return returnString;
  };

  const serviceBundleMemo = useMemo(() => {
    let filteredObjs: ServiceBundleSubstitute[] = [];
    if (debouncedValue.length >= MIN_SEARCH_LENGTH) {
      const searchItems = serviceBundles.map((bundleItem) => {
        return getConcatString(bundleItem);
      });

      const filteredItems: string[] = simpleFilter(searchText, searchItems);
      filteredObjs = serviceBundles.filter((bundleItem) => {
        return filteredItems.includes(getConcatString(bundleItem));
      });
    }

    return filteredObjs;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const navigate = useNavigate();
  const { serviceBundleId } = useParams<{ serviceBundleId: string }>();

  const toggleNavigate = (a: AccordionItem) => {
    const subCategorySeoName = a?.category?.seoName;
    const categorySeoName = a?.category?.parentCategory?.seoName;

    if (serviceBundleId === a.id) {
      navigate(`/${category?.seoName}/${subCategory?.seoName}`);
    } else navigate(`/${categorySeoName}/${subCategorySeoName}/${a.id}`);
  };

  return (
    <div>
      <Wrapper>
        <SearchLabel>Sök tjänst</SearchLabel>
        <SearchbarComponent
          type="text"
          placeholder="Sök efter tjänsten"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <MagnificationBlock src={magnification} />
      </Wrapper>
      <div>
        <StyledAccordion
          toggleButton={
            <TextButton
              disabled={subCategory?.unavailableAtYourArea}
              label=""
            />
          }
          onToggle={toggleNavigate}
          items={serviceBundleMemo.map((x) => ({
            ...x,
            id: x.cms.seoName!,
            disabledText: "",
            disabledNavigation: subCategory?.unavailableAtYourArea,
            disabled: subCategory?.unavailableAtYourArea,
            image: <HeaderImage bundle={x} />,
            title: <BundleTitle bundle={x} />,
          }))}
          activeKey={currentServiceBundle?.cms.seoName || ""}
        >
          <ServiceBundleConfiguration serviceBundle={currentServiceBundle} />
        </StyledAccordion>
      </div>
    </div>
  );
};

const SearchbarComponent = styled.input`
  width: 100%;
  border: 0;
  height: 58px;
  border-radius: 6px;
  padding: 10px 10px 10px 16px;
  font-size: 16px;
  ${media.tablet} {
    padding: 10px 10px 10px 21px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90px;
  background-color: #dce6f0;
  padding: 10px 16px 10px 16px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 4px;
  ${media.tablet} {
    padding: 10px 20px 10px 10px;
  }
`;

const MagnificationBlock = styled.img`
  display: flex;
  position: absolute;
  right: 40px;
`;

const SearchLabel = styled.label`
  display: flex;
  white-space: nowrap;
  margin: 0 35px 0 25px;
  font-size: 18px;
  font-family: "Clas Ohlson Sans Web medium", "Clas Ohlson Sans Web";
  display: none;
  ${media.tablet} {
    display: inline;
  }
`;
