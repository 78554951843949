import { CartContext } from "./Cart/useCart";
import Container from "react-bootstrap/Container";
import { GlobalStyle } from "./style/global";
import styled from "styled-components";
import { ToastContext } from "./Global/components/Toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CategoriesListContext } from "./MainPage/Global/useCategory";
import { MainMenu } from "./Global/MainMenu";
import { MainPage } from "./MainPage";
import { CartPage } from "./Cart/CartPage";
import { RenderSubmenu, SubmenuContextProvider } from "./Global/SubMenu";
import { AllServiceBundlesContext } from "./MainPage/Global/useServiceBundles";
import { Footer } from "./Global/Footer";
import { media } from "./style/media";
import { LegalFooter } from "./Global/LegalFooter";
import { CheckoutPage } from "./Checkout";
import { FooterSummary } from "./Cart/FooterSummary";
import { useTagManager } from "./Global/useTagManager";
function App() {
  useTagManager();
  return (
    <BrowserRouter>
      <SubmenuContextProvider>
        <AllServiceBundlesContext>
          <CartContext>
            <CategoriesListContext>
              <ToastContext>
                <GlobalStyle />
                <MainMenu />
                <Footer>
                  <Container>
                    <RenderSubmenu />
                    <Main>
                      <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/:categoryId" element={<MainPage />} />
                        <Route
                          path="/:categoryId/:subCategoryId"
                          element={<MainPage />}
                        />
                        <Route
                          path="/:categoryId/:subCategoryId/:serviceBundleId"
                          element={<MainPage />}
                        />
                        <Route path="/varukorg" element={<CartPage />} />
                        <Route path="/checkout/*" element={<CheckoutPage />} />
                      </Routes>
                    </Main>
                  </Container>
                  <FooterSummary />
                </Footer>
                <LegalFooter />
              </ToastContext>
            </CategoriesListContext>
          </CartContext>
        </AllServiceBundlesContext>
      </SubmenuContextProvider>
    </BrowserRouter>
  );
}

export default App;

const Main = styled.div`
  padding-top: 0rem;
  margin-bottom: 15rem;

  ${media.desktop} {
    padding-top: 1.5rem;
  }
`;
