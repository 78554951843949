import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Cart } from "./CartIndicatorInHeader";
import Availablity from "./Availability";
import { colors } from "../../style/statics";
import logo from "./ClasFixare_Lockup_1Row_white_RGB_1.svg";

export function MainMenu() {
  return (
    <>
      <Menu>
        <Container>
          <MenuRow>
            <Col>
              <Link to="/">
                <Logo src={logo} alt="Clas fixare" />
              </Link>
            </Col>
            <HeaderCol>
              <Header>Boka din fixare</Header>
            </HeaderCol>
            <CartIconCol>
              <Cart />
            </CartIconCol>
          </MenuRow>
        </Container>
      </Menu>
      <Availablity />
    </>
  );
}

const MenuRow = styled(Row)`
  align-items: center;
`;
const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${colors.grey[0]};
  color: ${colors.font.white};
  display: flex;
  z-index: 999;
`;

const HeaderCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const CartIconCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Logo = styled.img`
  height: 4.5rem;
  cursor: pointer;
  margin: 0;
`;

const Header = styled.span`
  color: ${colors.font.white};
`;
