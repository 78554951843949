import { FC } from "react";
import styled from "styled-components";
import { StyledAccordion } from "../MainPage/Accordion";
import { EnhancedCartItem, useCart } from "../Cart/index";
import { colors, MissingBundleImage } from "../style/statics";
import { SubHeadline6 } from "../style/Text";
import { useToggleButtonContext } from "../MainPage/Accordion/AccordionToggle";
import classNames from "classnames";
import { useStateWithDeps } from "../utils";
import { media } from "../style/media";
import { EditElement } from "./EditCartItem";
import {
  extractHourlyRateFromBundle,
  extractStartFeeTextFromItem,
  getTotalPriceForEnhancedCartItem,
} from "estimates";
import { CategoryDicItem } from "servicebundlecore";
import { getCategoriesFromCartItems } from "utils";
import { QUOTE_REQUIRED_TEXT } from "utils";
import { checkBundleForQuoteRequired } from "estimates";

const categoryTitle = (category, isSubCat) => {
  const title = category.name;
  const image = isSubCat ? category.iconUrl : "";

  const startFeeText = extractStartFeeTextFromItem(category);

  return (
    <TitleWrapper
      className={classNames({
        isSubCat,
      })}
    >
      <>
        <ImageWrapper>
          {image && typeof image === "string" && (
            <img
              src={image}
              alt={typeof title === "string" ? title : "clasfixare"}
            />
          )}
          {image && typeof image !== "string" && image}
        </ImageWrapper>
        <TitleText>
          <Title>
            {typeof title === "string" ? <span>{title}</span> : title}
            {category.subtitle && <Subtitle>{category.subtitle}</Subtitle>}
          </Title>
          {startFeeText && <StartFee>{startFeeText}</StartFee>}
        </TitleText>
      </>
    </TitleWrapper>
  );
};

export const Layout: FC = () => {
  const { enhancedCartItemsInCart, startEditItem, editingItemId } = useCart();
  const [current] = useStateWithDeps<EnhancedCartItem | undefined>(
    () =>
      enhancedCartItemsInCart.find((x) => x.serviceInCartId === editingItemId),
    [editingItemId, enhancedCartItemsInCart]
  );
  const { categories, subCategories } = getCategoriesFromCartItems(
    enhancedCartItemsInCart
  );
  const categoryList: CategoryDicItem[] = Object.values(categories);

  return (
    <>
      {categoryList.map((cat: CategoryDicItem) => {
        const subCategoryIds = cat.childIds;
        const categoryId = cat.category.id;
        const category = cat.category;

        return (
          <div key={cat.category.id}>
            {categoryTitle(category, false)}
            <LineBreak />
            {subCategoryIds.map((subCatId) => {
              const subCat = subCategories[subCatId];
              const filteredCartItems = enhancedCartItemsInCart.filter(
                (item) => {
                  return (
                    subCatId === item?.serviceBundle?.category?.id &&
                    categoryId ===
                      item?.serviceBundle?.category?.parentCategory?.id
                  );
                }
              );
              return (
                <div key={subCatId}>
                  <SubCatMargin>
                    {categoryTitle(subCat, true)}
                    <CartItemWraper>
                      <StyledAccordion
                        toggleButton={<AccordionActions />}
                        onToggle={(c) => startEditItem(c.id)}
                        items={filteredCartItems.map((x) => ({
                          id: x.serviceInCartId,
                          title: <HeaderTitle item={x} />,
                          image: <HeaderImage item={x} />,
                        }))}
                        activeKey={editingItemId}
                        secondaryColors
                      >
                        {current && <EditElement item={current} />}
                      </StyledAccordion>
                    </CartItemWraper>
                  </SubCatMargin>
                  <LineBreak />
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export const HeaderImage: FC<{ item: EnhancedCartItem }> = ({ item }) => {
  return (
    <ImageBackground>
      <BundleImage
        src={item.serviceBundle?.cms.imgUrl || MissingBundleImage}
        alt={item.serviceBundle?.cms.title}
      />
    </ImageBackground>
  );
};

export const HeaderTitle: FC<{ item: EnhancedCartItem }> = ({ item }) => {
  const { useDeduction, isClasMedlem } = useCart();

  const isQuoteRequired = checkBundleForQuoteRequired(item);

  const hourlyRate = extractHourlyRateFromBundle(item.serviceBundle);
  let totalPriceText = QUOTE_REQUIRED_TEXT;
  if (!isQuoteRequired) {
    totalPriceText = getTotalPriceForEnhancedCartItem(
      item,
      useDeduction,
      isClasMedlem,
      hourlyRate
    );
  }

  return (
    <TitleBackground>
      <SubHeadline6>{item.serviceBundle?.cms.title}</SubHeadline6>
      <Price>{totalPriceText}</Price>
    </TitleBackground>
  );
};

const AccordionActions = () => {
  return (
    <Wrapper>
      <EditButton />
      <DeleteButton />
    </Wrapper>
  );
};

export const EditButton: FC = () => {
  const { toggle, active } = useToggleButtonContext();
  return (
    <Btn className={classNames({ active })} type="button" onClick={toggle}>
      <BtnChRm>Ändra</BtnChRm>
    </Btn>
  );
};

export const DeleteButton: FC<{ label?: string }> = () => {
  const { item } = useToggleButtonContext();
  const { dispatch } = useCart();

  const remove = () => {
    dispatch({
      type: "remove-from-cart",
      serviceInCartId: item.id,
    });
  };

  return (
    <DeleteBtn type="button" onClick={remove}>
      <BtnChRm>Ta bort</BtnChRm>
    </DeleteBtn>
  );
};

const BundleImage = styled.img``;

const ImageBackground = styled.div`
  background-color: ${colors.grey[4]};
  border-radius: 50%;
  padding: 0.8rem;
`;

const TitleBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BtnChRm = styled(SubHeadline6)`
  font-size: 0.8rem;
`;

const Price = styled.span`
  font-size: 1rem;
  line-height: 0.875rem;
  color: ${colors.grey[1]};
  margin-top: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.mobile} {
    flex-direction: row;
  }
`;

const Btn = styled.button`
  background-color: ${colors.grey[0]};
  color: white;
  width: 5.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  border: 0;

  &.active {
    background-color: ${colors.grey[1]};
  }
`;

const DeleteBtn = styled(Btn)`
  margin-left: 0rem;
  margin-top: 0.5rem;
  background-color: ${colors.font.red};

  ${media.mobile} {
    margin-top: 0rem;
    margin-left: 1rem;
  }
`;

const SubCatMargin = styled.div`
  margin-left: 1rem;
`;

const LineBreak = styled.div`
  margin-top: 0.8rem;
`;

const Subtitle = styled.p`
  margin-bottom: 0;
  font-style: italic;
  font-size: 0.8rem;
`;
const StartFee = styled(Subtitle)`
  color: ${colors.font.black};
`;
const TitleText = styled.div`
  padding: 0 1.313rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  background-color: ${colors.grey[3]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: ${colors.secondary.green[0]};

  &.isSubCat {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  & span {
    font-family: Clas Ohlson Sans Web medium;
    font-size: 1.1rem;
  }

  & img {
    width: 2.5rem;
    justify-content: center;
    align-content: center;
  }
`;

const Title = styled.div`
  width: 100%;

  &.disabled {
    color: ${colors.grey[2]};
  }
`;
const ImageWrapper = styled.div`
  &.disabled {
    opacity: 0.4;
  }
`;

const CartItemWraper = styled.div`
  margin-top: 0;
`;
