import {
  checkEstimatesForQuoteRequired,
  Deductable,
  Estimate,
  estimatedLowestPriceText,
  estimatedTimeAndPriceText,
  extractHourlyRateFromBundle,
  rebatePrice,
  summarizePriceEstimates,
} from "estimates";
import { SmallText, SubHeadline6 } from "../../style/Text";
import { FC } from "react";
import styled from "styled-components";
import { useCart } from "../../Cart";
import { colors } from "../../style/statics";
import { ServiceBundleSubstitute } from "./useServiceBundlePreview";
import { QUOTE_REQUIRED_TEXT, QUOTE_REQUIRED_TYPE } from "utils";

interface SummaryProps {
  deductable: Deductable;
  allEstimates: Estimate[];
  basePrice: Estimate;
  serviceBundle: ServiceBundleSubstitute;
}

const EstimatedTimeOrPrice: FC<SummaryProps> = ({
  allEstimates,
  deductable,
  basePrice,
}) => {
  if (basePrice.type === "FixedPrice") {
    return <></>;
  }

  if (basePrice.type === QUOTE_REQUIRED_TYPE) {
    return (
      <StyledSummary>
        <Label>
          <LabelEstprice>Uppskattad tid/pris</LabelEstprice>
        </Label>
        <Label>
          <EstPrice>Offert krävs</EstPrice>
        </Label>
      </StyledSummary>
    );
  }

  let estimatedPrice = (
    <SummarizeTimeEstimate estimates={allEstimates} deductable={deductable} />
  );

  if (checkEstimatesForQuoteRequired(allEstimates)) {
    estimatedPrice = <>{QUOTE_REQUIRED_TEXT}</>;
  }

  return (
    <StyledSummary>
      <Label>
        <LabelEstprice>Uppskattad tid/pris</LabelEstprice>
      </Label>
      <Label>
        <EstPrice>{estimatedPrice}</EstPrice>
      </Label>
    </StyledSummary>
  );
};
const EstimatedTotalPrice: FC<SummaryProps> = ({
  allEstimates,
  deductable,
  basePrice,
  serviceBundle,
}) => {
  if (basePrice.type === QUOTE_REQUIRED_TYPE) return null;
  if (checkEstimatesForQuoteRequired(allEstimates)) return null;

  if (basePrice.type === "FixedPrice") {
    return (
      <StyledSummary>
        <Label>
          <LabelEstprice>Estimerat pris</LabelEstprice>
        </Label>
        <Label>
          <EstPrice>
            <SummarizePriceCalculatedEstimate
              estimates={allEstimates}
              deduction={deductable}
              serviceBundle={serviceBundle}
            />
          </EstPrice>
        </Label>
      </StyledSummary>
    );
  }

  return (
    <>
      <StyledSummary>
        <Label>
          <LabelEstprice>Estimerat minsta totalbelopp</LabelEstprice>
        </Label>
        <Label>
          <EstPrice>
            <SummarizePriceCalculatedEstimate
              estimates={allEstimates}
              deduction={deductable}
              serviceBundle={serviceBundle}
            />{" "}
            *
          </EstPrice>
        </Label>
      </StyledSummary>

      <StyledSummary>
        <Label>
          <SmallText>
            * Slutpris kan komma att ändras baserat på total tidsåtgång. Pris är 499kr/h efter RUT-avdrag.
          </SmallText>
        </Label>
      </StyledSummary>
    </>
  );
};

export const SingleServiceBundlePriceSummary: FC<SummaryProps> = ({
  allEstimates,
  deductable,
  basePrice,
  serviceBundle,
}) => {
  return (
    <Wrapper>
      <HR />
      <EstimatedTimeOrPrice
        {...{ allEstimates, deductable, basePrice, serviceBundle }}
      />
      <EstimatedTotalPrice
        {...{ allEstimates, deductable, basePrice, serviceBundle }}
      />

      <HR />
    </Wrapper>
  );
};

const SummarizeTimeEstimate: FC<{
  estimates: Estimate[];
  deductable: Deductable;
}> = ({ estimates, deductable }) => {
  const { isClasMedlem, useDeduction } = useCart();
  const timeEstimate = calculateTimeEstimate(
    estimates,
    deductable,
    isClasMedlem,
    useDeduction
  );

  return <>{timeEstimate}</>;
};

export const calculateTimeEstimate = (
  estimates,
  deductable,
  isClasMedlem,
  useDeduction
) => {
  const result = summarizePriceEstimates(estimates);
  const resultWithRebateOnPrice = rebatePrice(
    result,
    isClasMedlem,
    useDeduction ? deductable : "None"
  );

  return estimatedTimeAndPriceText(resultWithRebateOnPrice);
};

const SummarizePriceCalculatedEstimate: FC<{
  estimates: Estimate[];
  deduction: Deductable;
  serviceBundle: ServiceBundleSubstitute;
}> = ({ estimates, deduction, serviceBundle }) => {
  const { isClasMedlem, useDeduction } = useCart();
  const result = summarizePriceEstimates(estimates);
  const hourlyRate = extractHourlyRateFromBundle(serviceBundle);
  const isQuoteRequired = estimates.find(
    (item) => item.type === QUOTE_REQUIRED_TYPE
  );
  let priceText = <>{QUOTE_REQUIRED_TEXT}</>;
  if (!isQuoteRequired) {
    priceText = estimatedLowestPriceText({
      ...result,
      deduction: useDeduction ? deduction : "None",
      isClasMedlem,
      hourlyRate,
    });
  }
  return <>{priceText}</>;
};

const Wrapper = styled.div`
  margin: 1rem 0;
`;

const HR = styled.hr`
  border: 0.063rem solid ${colors.secondary.blue[1]};
  opacity: 1;
`;

const StyledSummary = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  width: 50%;
  /* text-align: left; */
`;

const LabelEstprice = styled(SubHeadline6)`
  margin-top: 0.5rem;
  text-align: left;
`;

const EstPrice = styled(SubHeadline6)`
  font-size: 1.1rem;
  margin-top: 0.5rem;
  text-align: right;
`;
