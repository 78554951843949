import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import formatMoney from "accounting-js/lib/formatMoney.js";

export function useStateWithDeps<S>(
  initialState: S | (() => S),
  deps?: DependencyList
): [S, Dispatch<SetStateAction<S>>] {
  const [res, setRes] = useState<S>(initialState);
  useEffect(() => {
    setRes(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [res, setRes];
}

export const asMoney = (money: string | number) =>
  !money
    ? "0 kr"
    : formatMoney(money, {
        symbol: "kr",
        precision: 2,
        thousand: ".",
        decimal: ",",
        format: "%v %s",
      });
