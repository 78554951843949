import { createContext, FC, useContext, useReducer, useState } from "react";
import { CheckoutActions, CheckoutFormState, checkoutReducer } from "./reducer";
import moment from "moment";
import { CheckoutConfirmation, PersonalData } from "servicebundlecore";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../../Cart/useCart";
import fetch from "../../../utils/enhancedFetch";
import { SalesforceRequestedStartTime } from "salesforce/SalesforceModels";

const context = createContext<{
  dispatch: React.Dispatch<CheckoutActions>;
  formState: CheckoutFormState;
  getPersonalInfo: (ssn: string) => void;
  loading: boolean;
  submitCheckout: () => void;
  checkoutConfirmation?: CheckoutConfirmation;
}>(undefined as any);

export interface FormValidationError {
  field: string;
}

export const CheckoutContext: FC = ({ children }) => {
  const [{ checkoutConfirmation, formState }, dispatch] = useReducer(
    checkoutReducer,
    {
      formState: {
        requestedStartDate: moment().add(2, "days").toDate(),
        requestedStartTimeId: SalesforceRequestedStartTime.None,
        desireUrgency: false,
      },
      checkoutValidationErrors: [],
    }
  );
  const { dispatch: dispatchCart } = useCart();
  const { postalCode } = useCart();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const getPersonalInfo = async (ssn: string) => {
    setLoading(true);
    const personal = await fetch("/api/ssn", {
      method: "post",
      body: JSON.stringify({
        ssn,
      }),
      headers: { "Content-Type": "application/json" },
    }).then((result) => {
        if (result.ok) {
            return result.json() as Promise<PersonalData>;
        }
        return null;
    });
    if (personal) {
        dispatch({ type: "set_form_value", value: { personal } });
    }

    setLoading(false);
  };

  const submit = async () => {
    setLoading(true);

    const {
      requestedStartDate,
      desireUrgency,
      requestedStartTimeId,
      otherComments,
      gateAccessCode,
      personal,
    } = formState;

    if (personal === undefined) {
        throw "should never get here, but make ts happy :)";
    }

    const newFormState: CheckoutFormState = {
      requestedStartDate,
      personal: {
          ...personal,
          zipcode: postalCode
      },
      desireUrgency: desireUrgency ?? false,
      requestedStartTimeId: requestedStartTimeId ?? SalesforceRequestedStartTime.None,
      otherComments,
      gateAccessCode,
    };
    const result = await fetch("/api/checkout", {
      method: "post",
      body: JSON.stringify(newFormState),
      headers: { "Content-Type": "application/json" },
    });
    if (!result.ok) {
      setLoading(false);
      alert(await result.text());
      navigate("/checkout/first");
      return;
    }

    dispatchCart({ type: "refresh" });
    let confirmation = (await result.json()) as CheckoutConfirmation;

    dispatch({
      type: "checkout_confirmed",
      value: confirmation,
    });

    setLoading(false);
    navigate("/checkout/confirmation");
  };

  return (
    <context.Provider
      value={{
        checkoutConfirmation,
        submitCheckout: submit,
        dispatch,
        formState: formState as any,
        getPersonalInfo,
        loading,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useCheckout = () => useContext(context);
