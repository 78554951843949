import {
  BodyText,
  Headline1,
  SubHeadline5,
} from "../style/Text";
import styled from "styled-components";
import { GoCheck } from "react-icons/go";

export function MainTextBlock() {
  return (
    <div>
      <MainHeadline>Välkommen till Clas Fixare - boka din tjänst här</MainHeadline>
      Här kan du välja på tjänster inom montering, upphängning, installation, måleri, snickeri, el och VVS.
      <BodyTextUnder>
        <UspList>
          <li>
            <GoCheck size="2rem" />{" "}
            <SubHeadline5>Tydlig prissättning</SubHeadline5>
          </li>
          <li>
            <GoCheck size="2rem" />{" "}
            <SubHeadline5>Behöriga hantverkare</SubHeadline5>
          </li>
          <li>
            <GoCheck size="2rem" />{" "}
            <SubHeadline5>Både stora och mindre uppdrag</SubHeadline5>
          </li>
          <li>
            <GoCheck size="2rem" />{" "}
            <SubHeadline5>Enkel och snabb bokning</SubHeadline5>
          </li>
        </UspList>
      </BodyTextUnder>
    </div>
  );
}

const UspList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  & li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  & li svg {
    margin-right: 1rem;
  }
`;

const MainHeadline = styled(Headline1)`
  font-size: 2rem;
`;

const BodyTextUnder = styled(BodyText)`
  margin-top: 2em;
`;
