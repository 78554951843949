import {
  ServiceBundleConfig,
  ServiceBundleSubstitute,
} from "./useServiceBundlePreview";
import { AddToCart } from "./AddToCart";
import styled from "styled-components";
import { colors } from "../../style/statics";
import { RenderAllQuestions } from "./RenderAllQuestions";
import {
  calculateTimeEstimate,
  SingleServiceBundlePriceSummary,
} from "./SingleServiceBundlePriceSummary";
import { FC } from "react";
import { useServiceBundleConfig } from "./useServiceBundlePreview";
import { BodyText, SubHeadline6 } from "../../style/Text";
import { media } from "../../style/media";
import { useCart } from "../../Cart/useCart";

export const ServiceBundleConfiguration: FC<{
  serviceBundle?: ServiceBundleSubstitute;
}> = ({ serviceBundle }) => {
  if (!serviceBundle) return <></>;

  return (
    <Configuration>
      {serviceBundle && (
        <ServiceBundleConfig serviceBundle={serviceBundle}>
          <InfoText>
            <SubHeadline6>Information</SubHeadline6>
            <BundleInformation>{serviceBundle.cms.readMore}</BundleInformation>
          </InfoText>
          {serviceBundle.cms.termsAndConditions && (
            <>
              <SubHeadline6>Villkor</SubHeadline6>
              <TermsAndCondition
                dangerouslySetInnerHTML={{
                  __html: serviceBundle.cms.termsAndConditions,
                }}
              />
            </>
          )}
          <ItemWrapper>
            <RenderAllQuestions />
            <MandatoryExplanation />
            <SummaryFront serviceBundle={serviceBundle} />
            <AddToCart serviceBundleId={serviceBundle.id} />
          </ItemWrapper>
        </ServiceBundleConfig>
      )}
    </Configuration>
  );
};

const MandatoryExplanation = () => {
  const {
    serviceBundle: { questionDefinitions },
  } = useServiceBundleConfig();

  if (questionDefinitions.every((x) => !x.definition.mandatory)) return null;
  return <MandatoryText>* Obligatorisk</MandatoryText>;
};

export const SummaryFront: FC<{ serviceBundle: ServiceBundleSubstitute }> = ({
  serviceBundle,
}) => {
  const {
    allEstimates,
    serviceBundle: { deductable, estimate },
  } = useServiceBundleConfig();

  const { isClasMedlem, useDeduction } = useCart();
  const timeEstimate = calculateTimeEstimate(
    allEstimates,
    deductable,
    isClasMedlem,
    useDeduction
  );
  const { setTimeEstimate } = useServiceBundleConfig();
  setTimeEstimate(timeEstimate);

  return (
    <SingleServiceBundlePriceSummary
      allEstimates={allEstimates}
      deductable={deductable}
      basePrice={estimate}
      serviceBundle={serviceBundle}
    />
  );
};

const MandatoryText = styled.span`
  font-size: 0.8rem;
  font-family: Clas Ohlson Sans Web medium;
  font-size: 0.625rem;
  line-height: 0.8125rem;
`;

const BundleInformation = styled(BodyText)`
  margin-top: 0.25rem;
  font-size: 0.8rem;
`;

const InfoText = styled.div`
  display: block;

  ${media.tablet} {
    display: none;
  }
`;

const Configuration = styled.div`
  background-color: ${colors.secondary.green[0]};
  padding: 1rem;
  margin-top: 1rem;
`;

const ItemWrapper = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 0.25rem;
`;

const TermsAndCondition = styled(BodyText)`
  white-space: pre-wrap;
  margin-top: 0.25rem;
  font-size: 0.8rem;
`;
