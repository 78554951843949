import pinIcon from "./pinIcon.svg";
import styled from "styled-components";
import { media } from "../../style/media";
import { BodyText, SubHeadline5 } from "../../style/Text";
import { useCart } from "../../Cart/useCart";
import { useStateWithDeps } from "../../utils";
import { colors } from "../../style/statics";
import { useCategories } from "../../MainPage/Global/useCategory";
import { useEffect, useMemo } from "react";
import classNames from "classnames";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

export default function Availability() {
  const { postalCode } = useCart();
  const { categories } = useCategories();

  const { icon, title, subtitle, availability } = useMemo((): {
    icon?: JSX.Element;
    title: string;
    subtitle?: string;
    availability: "full" | "partial" | "none" | "unknown";
  } => {
    if (!postalCode) {
      return {
        title: "Var kan vi hjälpa dig?",
        subtitle:
          "Skriv in ditt postnummer så ser du om våra tjänster är tillgängliga där du bor",
        availability: "unknown",
      };
    }

    let allAvailable = categories.every((x) => !x.unavailableAtYourArea);
    let noneAvailable = categories.every((x) => x.unavailableAtYourArea);

    if (allAvailable) {
      return {
        icon: <img src={pinIcon} width={34} height={34} alt="pin icon" />,
        title: "Alla våra tjänster är tillgängliga i ditt område",
        availability: "full",
      };
    } else if (noneAvailable) {
      return {
        icon: <img src={pinIcon} width={34} height={34} alt="pin icon" />,
        title: "Tyvärr är våra tjänster inte tillgängliga i ditt område än",
        availability: "none",
      };
    } else {
      return {
        icon: <img src={pinIcon} width={34} height={34} alt="pin icon" />,
        title: "Delar av våra tjänster är tillgängliga i ditt område",
        availability: "partial",
      };
    }
  }, [postalCode, categories]);

  return (
    <Wrapper className={classNames({ expanded: availability === "unknown" })}>
      <Container>
        <Content>
          <LeftCol
            className={classNames({ expanded: availability === "unknown" })}
          >
            <Left>
              {icon && <ImageWrapper>{icon}</ImageWrapper>}
              <TextWrapper>
                <SubHeadline5>{title}</SubHeadline5>
                {subtitle && <Text>{subtitle}</Text>}
              </TextWrapper>
            </Left>
            <PostalCode expanded={availability === "unknown"} />
          </LeftCol>

          <RightCol>
            {availability === "unknown" && (
              <Circle>
                <CircleIcon src={pinIcon} alt="pin icon" />
              </Circle>
            )}
          </RightCol>
        </Content>
      </Container>
    </Wrapper>
  );
}

export const PostalCode = ({ expanded }: { expanded: boolean }) => {
  const [params] = useSearchParams();
  const { postalCode, dispatch } = useCart();
  const [postal, setPostal] = useStateWithDeps<string>(
    () => postalCode || "",
    [postalCode]
  );

  useEffect(() => {
    if (postal.replace(/\D/g, "").length === 5) save();
  }, [postal]);

  useEffect(() => {
    let codeinParams = params.get("postalcode");
    if (!codeinParams) return;
    setPostal(codeinParams);
    if (codeinParams.replace(/\D/g, "").length !== 5) save();
  }, [params]);

  const save = () => {
    dispatch({ type: "set-postal-code", postal });
  };

  return (
    <InputWrapper className={classNames({ expanded })}>
      <ZipCodeInput
        placeholder="Skriv in ditt postnummer"
        className={classNames({ expanded })}
        onBlur={save}
        value={postal}
        onChange={(e) => setPostal(e.target.value)}
      />
    </InputWrapper>
  );
};

const menuheight = "4.5rem";

const LeftCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.tablet} {
    flex-direction: row;
    align-items: center;
  }

  &.expanded {
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;

    ${media.tablet} {
      padding: 0 2rem 0 0;
      align-items: flex-start;
    }

    ${media.desktop} {
      padding: 0 5rem 0 0;
    }
  }
`;

const RightCol = styled.div``;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
  margin-top: 0;
  &.expanded {
    width: 100%;
  }

  ${media.tablet} {
    margin-top: 1rem;
  }

  ${media.desktop} {
    margin: 0;
  }
`;

const ZipCodeInput = styled.input`
  border-radius: 0.25rem;
  border: 0;
  padding: 1rem;
  width: 100%;
  font-size: 0.9rem;

  ${media.tablet} {
    max-width: 10rem;
  }

  &.expanded {
    margin: 0;
    margin: 2rem 0rem;
    max-width: 100rem;
    width: 100%;

    ${media.tablet} {
      margin: 0;
    }

    ${media.desktop} {
      margin: 2rem 0rem;
    }
  }
`;

const Circle = styled.div`
  display: none;
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  background-color: ${colors.grey[4]};
  justify-content: center;
  align-items: center;

  ${media.tablet} {
    display: flex;
  }

  ${media.desktop} {
    height: 20.5rem;
    width: 20.5rem;
  }
`;

const CircleIcon = styled.img`
  height: 10rem;
  ${media.desktop} {
    height: 14rem;
  }
`;

const Wrapper = styled.div`
  position: static;
  z-index: 10;
  top: 4.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: ${menuheight};
  box-sizing: border-box;
  background-color: ${colors.brandColor};

  &.expanded {
    padding: 0rem;
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  ${media.tablet} {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;

  ${media.desktop} {
    margin-left: 1rem;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-color: ${colors.grey[4]};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const Text = styled(BodyText)`
  margin-bottom: 0;
  margin-top: 0.2rem;
`;
