import { createContext, FC, useContext, useEffect, useState } from "react";

export const useConfig = () => {
  return useContext(context);
};

interface ApplicationConfiguration {
  gtmId: string;
  applicationInsights: {
    connectionString: string;
    instrumentationKey: string;
  };
  applicationVersion: string;
  environment: string;
}

export const ConfigurationProvider: FC = ({ children }) => {
  const [state, setState] = useState<ApplicationConfiguration>();
  useEffect(() => {
    fetchConfiguration().then((config) => {
      setState(config);
    });
  }, []);
  if (state === undefined) return <></>;
  return <context.Provider value={state}>{children}</context.Provider>;
};
const context = createContext<ApplicationConfiguration>({} as any);
async function fetchConfiguration() {
  const response = await fetch("/api/config");
  const result: ApplicationConfiguration = await response.json();

  return result;
}
